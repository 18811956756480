
import React, { useState } from 'react'
import './Liquidez.css'
import logo4 from './liquidez.png'
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { Row, Col, Divider, Skeleton, Result, message, Button, Popconfirm, InputNumber } from 'antd';
import { useEffect } from 'react';
import { RequestGet } from '../../hooks/Request/Request';
import { veltisTooltip } from '../../hooks/Tooltip/Tooltip';

HighchartsMore(Highcharts);


const Liquidez = ({ dataa }) => {

    const [loading, setloading] = useState(true);
    const [datosRequest, setdatosRequest] = useState();
    const [datosRequestAdvance, setdatosRequestAdvance] = useState(false);
    const [errorRequest, seterrorRequest] = useState(false);
    const [precioAvanzado, setprecioAvanzado] = useState();
    const [precioAvanzadoZona, setprecioAvanzadoZona] = useState();
    const [precioPrincipal, setprecioPrincipal] = useState();
    const [peso1, setpeso1] = useState('25%');
    const [peso2, setpeso2] = useState('25%');
    const [peso3, setpeso3] = useState('25%');
    const [peso4, setpeso4] = useState('25%');
    const [errorPesos, seterrorPesos] = useState('black');

    var precioInforme = 0;

    //('/api/getLiquidez/:refcat/:valorprincipal/:valorprincipalzona/:valorsumlado/:valorsimuladozona/:peso1/:peso2/:peso3/:peso4');
    useEffect(() => {
        seterrorRequest(false);
        const handleRequest = async () => {
            let valll = dataa.data[0].valor_ref_dgc > 0 ? parseFloat(dataa.data[0].valor_ref_dgc).toFixed(0) : 0;
            let vall2 = 0;

            if (dataa.data[0].valor_ref_dgc > 0) {
                valll = parseFloat(dataa.data[0].valor_ref_dgc).toFixed(0);
            }
            else {
                if (dataa.data[0].user_hist_awm_price > 0) {
                    valll = parseFloat(dataa.data[0].user_hist_awm_price).toFixed(0);
                } else valll = 0;
            }

            if (dataa.data[0].valor_ref_zona_dgc > 0) {
                vall2 = parseFloat(dataa.data[0].valor_ref_zona_dgc).toFixed(0);
            }
            else if (dataa.data[0].euskadi_precios_valor > 0) {
                vall2 = parseFloat(dataa.data[0].euskadi_precios_valor / dataa.data[0].sup_elememtos_constru_asociados).toFixed(0);
            }

            const responseq = await RequestGet(
                'https://terminal.veltisrating.com:8888/api/getLiquidez/' + dataa.data[0].full_refcat + '/' + valll + '/' + vall2 + '/0/0/' + peso1.toString().replace('%', '').replace(' ', '').trim() + '/' + peso2.toString().replace('%', '').replace(' ', '').trim() + '/' + peso3.toString().replace('%', '').replace(' ', '').trim() + '/' + peso4.toString().replace('%', '').replace(' ', '').trim(),
            )
            if (responseq.response === 400 && responseq.message === 'Ha ocurrido un error') {
                seterrorRequest(true);
            }
            else if (responseq.response === 400 && responseq.message === 'invalid_pesos') {
                seterrorPesos('red');
                message.open({
                    type: 'error',
                    maxCount: 1,
                    content: `El peso total de los componentes debe sumar 100%`,
                    duration: 5,
                    style: {
                    },
                });
            }
            else {
                setdatosRequest(responseq.data);
                setprecioAvanzado(responseq.data.valor_total);
                setprecioPrincipal(valll);
                setprecioAvanzadoZona(responseq.data.comp1.source_2);
            }
            setloading(false);
        }

        //console.log(dataa.data[0].valor_ref_dgc)

        if (validarPesos() /*&& dataa.data[0].valor_ref_dgc !== null && dataa.data[0].valor_ref_dgc !== ''*/) {
            setdatosRequestAdvance(false)
            setloading(true);
            handleRequest();
        }
    }, [dataa])


    const valll = dataa.data[0].valor_ref_dgc > 0 ? parseFloat(dataa.data[0].valor_ref_dgc).toFixed(0) : 0;

    const confirm = (e) => {
        if (precioInforme <= 0) {
            message.error('El precio tiene que ser mayor o menor a 0');
        } 
        else {
            window.open('https://terminal.veltisrating.com:8888/api/view_pdf_liquidez/'+precioInforme+'/'+parseFloat(dataa.data[0].valor_ref_zona_dgc).toFixed(0)+'/' + dataa.data[0].full_refcat, '_blank');
        }
    };
    const cancel = (e) => { message.error('Se ha cancelado la generación del informe'); };

    const onChange = (value) => { precioInforme = value };


    function advanceRequest(new_precio) {
        const handleRequest = async () => {
            const valll = dataa.data[0].valor_ref_dgc > 0 ? parseFloat(dataa.data[0].valor_ref_dgc).toFixed(0) : 0;
            const vall2 = dataa.data[0].valor_ref_zona_dgc > 0 ? parseFloat(dataa.data[0].valor_ref_zona_dgc).toFixed(0) : 0;
            const responseq = await RequestGet(
                'https://terminal.veltisrating.com:8888/api/getLiquidez/' + dataa.data[0].full_refcat + '/' + valll + '/' + vall2 + '/' + new_precio.toString().replace('%', '').replace(' ', '').trim() + '/' + 0 + '/' + peso1.toString().replace('%', '').replace(' ', '').trim() + '/' + peso2.toString().replace('%', '').replace(' ', '').trim() + '/' + peso3.toString().replace('%', '').replace(' ', '').trim() + '/' + peso4.toString().replace('%', '').replace(' ', '').trim(),
            )
            if (responseq.response === 400 && responseq.message === 'Ha ocurrido un error') {
                seterrorRequest(true);
            }
            else if (responseq.response === 400 && responseq.message === 'invalid_pesos') {
                seterrorPesos('red');
                message.open({
                    type: 'error',
                    maxCount: 1,
                    content: `El peso total de los componentes debe sumar 100%`,
                    duration: 5,
                    style: {
                    },
                });
            }
            else {
                setdatosRequestAdvance(false);
                setdatosRequestAdvance(responseq.data);
            }
        }
        if (validarPesos()) {
            handleRequest();
        }
    }

    function advanceRequestZona(new_precio) {
        const handleRequest = async () => {
            const valll = dataa.data[0].valor_ref_dgc > 0 ? parseFloat(dataa.data[0].valor_ref_dgc).toFixed(0) : 0;
            const vall2 = dataa.data[0].valor_ref_zona_dgc > 0 ? parseFloat(dataa.data[0].valor_ref_zona_dgc).toFixed(0) : 0;
            const responseq = await RequestGet(
                'https://terminal.veltisrating.com:8888/api/getLiquidez/' + dataa.data[0].full_refcat + '/' + valll + '/' + vall2 + '/' + 0 + '/' + new_precio.toString().replace('%', '').replace(' ', '').trim() + '/' + peso1.toString().replace('%', '').replace(' ', '').trim() + '/' + peso2.toString().replace('%', '').replace(' ', '').trim() + '/' + peso3.toString().replace('%', '').replace(' ', '').trim() + '/' + peso4.toString().replace('%', '').replace(' ', '').trim(),
            )
            if (responseq.response === 400 && responseq.message === 'Ha ocurrido un error') {
                seterrorRequest(true);
            }
            else if (responseq.response === 400 && responseq.message === 'invalid_pesos') {
                seterrorPesos('red');
                message.open({
                    type: 'error',
                    maxCount: 1,
                    content: `El peso total de los componentes debe sumar 100%`,
                    duration: 5,
                    style: {
                    },
                });
            }
            else {
                setdatosRequestAdvance(false);
                setdatosRequestAdvance(responseq.data);
            }
        }
        if (validarPesos()) {
            handleRequest();
        }
    }

    function advanceRequestPrincipal(new_precio) {
        const handleRequest = async () => {
            const valll = dataa.data[0].valor_ref_dgc > 0 ? parseFloat(dataa.data[0].valor_ref_dgc).toFixed(0) : 0;
            const vall2 = dataa.data[0].valor_ref_zona_dgc > 0 ? parseFloat(dataa.data[0].valor_ref_zona_dgc).toFixed(0) : 0;
            const responseq = await RequestGet(
                'https://terminal.veltisrating.com:8888/api/getLiquidez/' + dataa.data[0].full_refcat + '/' + new_precio.toString().replace('%', '').replace(' ', '').trim() + '/' + vall2 + '/' + precioAvanzado.toString().replace('%', '').replace(' ', '').trim() + '/' + precioAvanzadoZona.toString().replace('%', '').replace(' ', '').trim() + '/' + peso1.toString().replace('%', '').replace(' ', '').trim() + '/' + peso2.toString().replace('%', '').replace(' ', '').trim() + '/' + peso3.toString().replace('%', '').replace(' ', '').trim() + '/' + peso4.toString().replace('%', '').replace(' ', '').trim(),
            )
            if (responseq.response === 400 && responseq.message === 'Ha ocurrido un error') {
                seterrorRequest(true);
            }
            else if (responseq.response === 400 && responseq.message === 'invalid_pesos') {
                seterrorPesos('red');
                message.open({
                    type: 'error',
                    maxCount: 1,
                    content: `El peso total de los componentes debe sumar 100%`,
                    duration: 5,
                    style: {
                    },
                });
            }
            else {
                setdatosRequestAdvance(false);
                setdatosRequestAdvance(responseq.data);
            }
        }
        if (validarPesos()) {
            handleRequest();
        }
    }

    function handleChangePrice(val) {
        let valor = val.target.value.toString().replace('€', '').replace(' ', '').trim();
        if (valor === '' || isNaN(valor)) valor = 0;
        setprecioAvanzado(parseInt(valor));
    }
    function handleChangePriceZona(val) {
        let valor = val.target.value.toString().replace('€', '').replace(' ', '').trim();
        if (valor === '' || isNaN(valor)) valor = 0;
        setprecioAvanzadoZona(parseInt(valor));
    }

    function handleChangePeso1(val) {
        let valor = val.target.value.toString().replace('%', '').replace(' ', '').trim();
        if (valor === '' || isNaN(valor)) valor = 0;
        setpeso1(parseInt(valor) + '%');
    }
    function handleChangePeso2(val) {
        let valor = val.target.value.toString().replace('%', '').replace(' ', '').trim();
        if (valor === '' || isNaN(valor)) valor = 0;
        setpeso2(parseInt(valor) + '%');
    }
    function handleChangePeso3(val) {
        let valor = val.target.value.toString().replace('%', '').replace(' ', '').trim();
        if (valor === '' || isNaN(valor)) valor = 0;
        setpeso3(parseInt(valor) + '%');
    }
    function handleChangePeso4(val) {
        let valor = val.target.value.toString().replace('%', '').replace(' ', '').trim();
        if (valor === '' || isNaN(valor)) valor = 0;
        setpeso4(parseInt(valor) + '%');
    }
    function validarPesos() {
        const pesos_totales = parseInt(peso1.toString().replace('%', '').replace(' ', '').trim())
            + parseInt(peso2.toString().replace('%', '').replace(' ', '').trim())
            + parseInt(peso3.toString().replace('%', '').replace(' ', '').trim())
            + parseInt(peso4.toString().replace('%', '').replace(' ', '').trim());
        if (Math.abs(pesos_totales - 100) > 1) {
            return false;
        } else {
            return true;
        }
    }
    const handleFocus = (event) => event.target.select();

    function handleKeyDownPesos(e) {
        if (e.key === 'Enter') {
            const pesos_totales = parseInt(peso1.toString().replace('%', '').replace(' ', '').trim())
                + parseInt(peso2.toString().replace('%', '').replace(' ', '').trim())
                + parseInt(peso3.toString().replace('%', '').replace(' ', '').trim())
                + parseInt(peso4.toString().replace('%', '').replace(' ', '').trim());
            if (Math.abs(pesos_totales - 100) > 1) {
                seterrorPesos('red');
                message.open({
                    type: 'error',
                    maxCount: 1,
                    content: `El peso total de los componentes debe sumar 100%, peso total actual ${pesos_totales}%`,
                    duration: 5,
                    style: {
                    },
                });
            } else {
                seterrorPesos('black');
                advanceRequest(precioAvanzado);
                message.destroy();
            }
        }
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            advanceRequest(precioAvanzado);
        }
    }

    function handleKeyDownZona(e) {
        if (e.key === 'Enter') {
            advanceRequestZona(precioAvanzadoZona);
        }
    }

    function handleChangePricePrincipal(val) {
        let valor = val.target.value.toString().replace('€', '').replace(' ', '').trim();
        if (valor === '' || isNaN(valor)) valor = 0;
        setprecioPrincipal(parseInt(valor));
    }

    function handleKeyDownPrincipal(e) {
        if (e.key === 'Enter') {
            advanceRequestPrincipal(precioPrincipal);
        }
    }

    if (loading) {
        return (
            <>
                <div style={{ display: 'flex', gap: '5px', marginTop: '5%', marginLeft: '2%' }}>
                    <Skeleton
                        active
                        loading={loading}
                        paragraph={{
                            rows: 3,
                        }}
                    />
                </div> <div style={{ display: 'flex', gap: '5px', marginLeft: '2%' }}>
                    <Skeleton
                        active
                        loading={loading}
                        paragraph={{
                            rows: 3,
                        }}
                    />
                </div> <div style={{ display: 'flex', gap: '5px', marginLeft: '2%' }}>
                    <Skeleton
                        active
                        loading={loading}
                        paragraph={{
                            rows: 3,
                        }}
                    />
                </div><div style={{ display: 'flex', gap: '5px', marginLeft: '2%' }}>
                    <Skeleton
                        active
                        loading={loading}
                        paragraph={{
                            rows: 3,
                        }}
                    />
                </div>
            </>
        )
    } else {

        if (errorRequest) return (
            <Result
                icon={null}
                subTitle="No hay datos de liquidez disponibles"
            />
        );

        const tabla1Data = [
            {
                precioUnitario: datosRequest.comp1.score + ` (${datosRequest.comp1.aportacion})`,
                ratioDinamismo: datosRequest.comp2.score + ` (${datosRequest.comp2.aportacion})`,
                ratingVeltis: datosRequest.comp3.score + ` (${datosRequest.comp3.aportacion})`,
                caracteristicasActivo: datosRequest.comp4.score + ` (${datosRequest.comp4.aportacion})`,
                liquidezGlobal: datosRequest.liquidez,
                precioTotal: datosRequest.valor_total,
                precioEurM2: datosRequest.comp1.source,
                sZona: datosRequest.comp1.source_2,
                activoSZona: Math.round(((datosRequest.comp1.source / datosRequest.comp1.source_2) - 1) * 100, 2) + '%',
            }
        ];

        const tabla12Data = [
            {
                precioUnitario: datosRequestAdvance ? datosRequestAdvance.comp1.score + ` (${datosRequestAdvance.comp1.aportacion})` : '0 (0)',
                precioUnitarioScore: datosRequestAdvance ? datosRequestAdvance.comp1.score : 0,
                precioUnitarioAportacion: datosRequestAdvance ? datosRequestAdvance.comp1.aportacion : 0,
                ratioDinamismo: datosRequestAdvance ? datosRequestAdvance.comp2.score + ` (${datosRequestAdvance.comp2.aportacion})` : '0 (0)',
                ratingVeltis: datosRequestAdvance ? datosRequestAdvance.comp3.score + ` (${datosRequestAdvance.comp3.aportacion})` : '0 (0)',
                caracteristicasActivo: datosRequestAdvance ? datosRequestAdvance.comp4.score + ` (${datosRequestAdvance.comp4.aportacion})` : '0 (0)',
                liquidezGlobal: datosRequestAdvance ? datosRequestAdvance.liquidez : 0,
                precioTotal: datosRequestAdvance ? Math.round(((datosRequestAdvance.valor_total / datosRequest.valor_total) - 1) * 100, 2) + '%' : 0,
                precioEurM2: datosRequestAdvance ? Math.round(datosRequestAdvance.valor_total / parseInt(datosRequestAdvance.comp4_detail.comp4_1.source), 0) : 0,
                sZona: datosRequestAdvance ? datosRequestAdvance.comp1.source_2 : 0,
                activoSZona: datosRequestAdvance ? Math.round(((datosRequestAdvance.comp1.source / datosRequestAdvance.comp1.source_2) - 1) * 100, 2) + '%' : 0,
                liquidez: datosRequestAdvance ? datosRequestAdvance.liquidez : 0,
            }
        ];

        const tabla2Data = [
            {
                refActivo: datosRequest.refcat,
                xMuestra: 'Ref.Activo',
                columnas: [
                    /*{
                        nombre: '',
                        valor1: 'LIQUIDEZ',
                        valor2: datosRequest.liquidez,
                        valor3: 50.3,
                        valor_color2: { backgroundColor: '#2F75B5', color: 'white' },
                        valor_color1: { backgroundColor: '#BDD7EE' },
                        valor_color_nombre: { backgroundColor: '#BDD7EE' },
                    },*/
                    {
                        nombre: '€/m2',
                        valor1: 'Precio Unitario',
                        valor2: datosRequest.comp1.source,
                        valor3: datosRequest.comp1.source_2,
                        valor_color2: { backgroundColor: '#2F75B5', color: 'white' },
                        valor_color1: { backgroundColor: '#BDD7EE' },
                        valor_color_nombre: { backgroundColor: '#BDD7EE' },
                    },
                    /*{
                        nombre: 'rat. Dinamismo',
                        valor1: 'COMP2',
                        valor2: datosRequest.comp2.source,
                        valor3: datosRequest.comp2.source_2,
                        valor_color2: { backgroundColor: '#2F75B5', color: 'white' },
                        valor_color1: { backgroundColor: '#BDD7EE' },
                        valor_color_nombre: { backgroundColor: '#BDD7EE' },
                    },*/
                    {
                        nombre: 'Score',
                        valor1: 'Rating',
                        valor2: datosRequest.comp3.source,
                        valor3: datosRequest.comp3.source_2,
                        valor_color2: { backgroundColor: '#2F75B5', color: 'white' },
                        valor_color1: { backgroundColor: '#BDD7EE' },
                        valor_color_nombre: { backgroundColor: '#BDD7EE' },
                    },
                    {
                        nombre: '',
                        valor1: '',
                        valor2: '',
                        valor3: "",
                        valor_color2: { backgroundColor: '#2F75B5', color: 'white' },
                        valor_color1: { backgroundColor: '#BDD7EE' },
                        valor_color_nombre: { backgroundColor: '#BDD7EE' },
                    },
                    /* {
                         nombre: '',
                         valor1: 'COMP4.1',
                         valor2: datosRequest.comp4_detail.comp4_1.score,
                         valor3: 50.3,
                         valor_color2: {},
                     },
                     {
                         nombre: '',
                         valor1: 'COMP4.2',
                         valor2: datosRequest.comp4_detail.comp4_2.score,
                         valor3: 50.3,
                         valor_color2: {},
                     },
                     {
                         nombre: '',
                         valor1: 'COMP4.3',
                         valor2: datosRequest.comp4_detail.comp4_3.score,
                         valor3: 50.3,
                         valor_color2: {},
                     },*/
                    {
                        nombre: 'm2',
                        valor1: datosRequest.comp4_detail.comp4_1.name,
                        valor2: datosRequest.comp4_detail.comp4_1.source,
                        valor3: datosRequest.comp4_detail.comp4_1.source_2,
                        valor_color2: {},
                    },
                    {
                        nombre: 'años',
                        valor1: datosRequest.comp4_detail.comp4_2.name,
                        valor2: datosRequest.comp4_detail.comp4_2.source,
                        valor3: datosRequest.comp4_detail.comp4_2.source_2,
                        valor_color2: {},
                    },
                    {
                        nombre: '1 a 9, 1 es lujo',
                        valor1: datosRequest.comp4_detail.comp4_3.name,
                        valor2: datosRequest.comp4_detail.comp4_3.source,
                        valor3: datosRequest.comp4_detail.comp4_3.source_2,
                        valor_color2: {},
                    },
                ],
            },
        ];

        const tabla3Data = [
            {
                superficie: datosRequest.comp4_detail.comp4_1.aportacion,
                antiguedad: datosRequest.comp4_detail.comp4_2.aportacion,
                calidad: datosRequest.comp4_detail.comp4_3.aportacion,
                precioUnitarioDif: datosRequest.comp1.aportacion,
                ratioDinamismo: datosRequest.comp2.aportacion,
                rating: datosRequest.comp3.aportacion,
                caracteristicasActivo: datosRequest.comp4.aportacion,
            },
        ];


        const velocimetroOptions = {
            chart: {
                type: 'gauge',
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false,
                height: '100%', // Establece el tamaño del gráfico al 100% del contenedor
                marginBottom: 0,
                padding: 0,
            },
            title: {
                text: '-/+ Liquidez',
                align: 'center', // Alineación del título
                verticalAlign: 'top', // Alineación vertical del título (cambiado a 'bottom')
                y: 75, // Espaciado vertical desde el gráfico
                style: {
                    fontSize: '100%', // Tamaño de fuente del título
                },
            },
            pane: {
                startAngle: 90, // Cambiado a 90 grados
                endAngle: 270, // Cambiado a 270 grados
                background: [
                    {
                        backgroundColor: 'white',
                        borderWidth: 0,
                        outerRadius: '0%',
                    },
                ],
            },
            yAxis: {
                min: -50,
                max: 50,
                reversed: true,
                minorTickInterval: 'auto',
                minorTickWidth: 1,
                minorTickLength: 10,
                minorTickPosition: 'inside',
                minorTickColor: '#666',
                tickPixelInterval: 20,
                tickWidth: 2,
                tickPosition: 'inside',
                tickLength: 10,
                tickColor: '#666',
                labels: {
                    step: 2,
                    rotation: '',
                },
                title: null,
                plotBands: [{
                    color: {
                        linearGradient: { x1: 0, y1: 1, x2: 1, y2: 1 },
                        stops: [
                            [0.00, '#FE0000'],
                            [0.25, '#FF6600'],
                            [0.50, '#FFFF00'],
                            [0.75, '#018001'],
                            [1.00, '#00FF01']
                        ]
                    },
                    from: -50,
                    to: 50
                }],
            },
            /*
            plotBands: [
                        {
                            from: 0,
                            to: 20,
                            color: '#FE0000', // red
                        },

                        {
                            from: 20,
                            to: 40,
                            color: '#FF6600', // yellow
                        },
                        {
                            from: 40,
                            to: 60,
                            color: '#FFFF00', // green
                        },
                        {
                            from: 60,
                            to: 80,
                            color: '#018001', // green
                        },
                        {
                            from: 80,
                            to: 100,
                            color: '#00FF01', // green
                        },
                    ],*/
            series: [
                {
                    name: 'Score',
                    data: [datosRequestAdvance === false ? 0 : datosRequestAdvance.liquidez - datosRequest.liquidez],
                    tooltip: {
                        valueSuffix: '',
                    },
                },
            ],
            credits: {
                enabled: false,
            },
        };

        const speedometerOptions = {
            chart: {
                type: 'gauge',
                alignTicks: false,
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false,
                maxHeight: 400,
            },
            title: {
                text: valll > 0 ? 'Liquidez <br/> Veltis' : 'Liquidez <br/> Estimada',
                align: 'center', // Alineación del título
                verticalAlign: 'center', // Alineación vertical del título (cambiado a 'bottom')
                y: 180,
                x: -10,
                style: {
                    fontSize: '100%',
                    color: 'grey'
                    // Tamaño de fuente del título
                },
            },
            pane: [
                {
                    startAngle: -150,
                    endAngle: 150,
                    background: null,
                    center: ['45%', '25%'],
                    size: '100%',
                },
            ],
            yAxis: [
                {
                    min: 0,
                    max: 100,
                    minorTickInterval: 'auto',
                    minorTickWidth: 1,
                    minorTickLength: 10,
                    minorTickPosition: 'inside',
                    tickPixelInterval: 30,
                    tickWidth: 2,
                    tickPosition: 'inside',
                    tickLength: 10,
                    labels: {
                        step: 2,
                        rotation: 'auto',
                    },
                    title: {
                        text: 'Liquidez',
                    },
                    offset: 0,
                    plotBands: [
                        {
                            from: 0,
                            to: 20,
                            color: '#FE0000', // red
                        },

                        {
                            from: 20,
                            to: 40,
                            color: '#FF6600', // yellow
                        },
                        {
                            from: 40,
                            to: 60,
                            color: '#FFFF00', // green
                        },
                        {
                            from: 60,
                            to: 80,
                            color: '#018001', // green
                        },
                        {
                            from: 80,
                            to: 100,
                            color: '#00FF01', // green
                        },
                    ],
                },
            ],
            series: [
                {
                    name: 'Score',
                    data: [datosRequest.liquidez],
                    yAxis: 0,
                },
            ],
            credits: {
                enabled: false,
            },
        };

        const speedometerOptionsPlus = {
            chart: {
                type: 'gauge',
                alignTicks: false,
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false,
                maxHeight: 400,
            },
            title: {
                text: 'Liquidez <br/> Simulada',
                align: 'center', // Alineación del título
                verticalAlign: 'center', // Alineación vertical del título (cambiado a 'bottom')
                y: 180,
                x: -10,
                style: {
                    fontSize: '100%',
                    color: 'grey'
                    // Tamaño de fuente del título
                },
            },
            pane: [
                {
                    startAngle: -150,
                    endAngle: 150,
                    background: null,
                    center: ['45%', '25%'],
                    size: '100%',
                },
            ],
            yAxis: [
                {
                    min: 0,
                    max: 100,
                    minorTickInterval: 'auto',
                    minorTickWidth: 1,
                    minorTickLength: 10,
                    minorTickPosition: 'inside',
                    tickPixelInterval: 30,
                    tickWidth: 2,
                    tickPosition: 'inside',
                    tickLength: 10,
                    labels: {
                        step: 2,
                        rotation: 'auto',
                    },
                    title: {
                        text: 'Liquidez',
                    },
                    offset: 0,
                    plotBands: [
                        {
                            from: 0,
                            to: 20,
                            color: '#000', // red
                        },

                        {
                            from: 20,
                            to: 40,
                            color: '#003366', // yellow
                        },
                        {
                            from: 40,
                            to: 60,
                            color: '#0066CC', // green
                        },
                        {
                            from: 60,
                            to: 80,
                            color: '#3399FF', // green
                        },
                        {
                            from: 80,
                            to: 100,
                            color: '#99CCFF', // green
                        },
                    ],

                },
            ],
            series: [
                {
                    name: 'Score',
                    data: [datosRequestAdvance === false ? 0 : datosRequestAdvance.liquidez],
                    yAxis: 0,
                },
            ],
            credits: {
                enabled: false,
            },
        };

        const pentagonoOptions = {
            chart: {
                polar: true,
                type: 'line',
                height: 300,
            },
            title: null,
            pane: {
                size: '50%',
                center: ['50%', '54%'],

            },

            xAxis: {
                categories: ['LIQUIDEZ', 'COMP1', 'COMP2', 'COMP3', 'COMP4'],
                tickmarkPlacement: 'on',
                lineWidth: 0,
                min: 0,
                max: 5,
            },

            yAxis: {
                gridLineInterpolation: 'polygon',
                lineWidth: 0,
                gridLineWidth: 1,
                borderWidth: 0,
                labels: {
                    enabled: true,
                    style: {
                        color: 'teal'
                    }
                },
                tickInterval: 20,
                min: 0,
                max: 100,
            },

            plotOptions: {
                series: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        connectorColor: 'blue'
                    },
                    enableMouseTracking: true
                },
            },

            tooltip: {
                shared: true,
                pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
            },

            series: [{
                name: 'Score',
                data: [datosRequest.liquidez, datosRequest.comp1.score, datosRequest.comp2.score, datosRequest.comp3.score, datosRequest.comp4.score],
                pointPlacement: 'on'
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400,
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },
                        pane: {
                            size: '70%'
                        }
                    }
                }]
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            }

        };

        const horizontalBarOptions_comp4 = {
            chart: {
                type: 'bar',
                height: 260
            },
            title: {
                text: 'Puntos de LIQUIDEZ por componentes'
            },
            xAxis: {
                categories: ['Puntos'],
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                title: null,
                tickInterval: 50,
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        connectorColor: 'blue'
                    },
                    stacking: 'normal',
                    pointWidth: 50
                }
            },
            tooltip: {
                shared: true,
                pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
            },
            series: [
                {
                    name: 'Superficie',
                    data: [datosRequest.comp4_detail.comp4_1.aportacion]
                },
                {
                    name: 'Antigüedad',
                    data: [datosRequest.comp4_detail.comp4_2.aportacion]
                },
                {
                    name: 'Calidad',
                    data: [datosRequest.comp4_detail.comp4_3.aportacion]
                }
            ],
            credits: {
                enabled: false,
            }
        };

        const horizontalBarOptions_comp = {
            chart: {
                type: 'bar',
                height: 260
            },
            title: {
                text: 'Puntos de LIQUIDEZ por componentes'
            },
            xAxis: {
                categories: ['Puntos'],
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                title: null,
                tickInterval: 20,
                showLastLabel: true,
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        connectorColor: 'blue'
                    },
                    stacking: 'normal',
                    pointWidth: 50,
                },

            },
            tooltip: {
                shared: true,
                pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
            },
            series: [
                {
                    name: 'Precio Unitario dif.',
                    data: [datosRequest.comp1.aportacion]
                },
                {
                    name: 'Ratio dinamismo',
                    data: [datosRequest.comp2.aportacion]
                },
                {
                    name: 'Rating',
                    data: [datosRequest.comp3.aportacion]
                },
                {
                    name: 'Caracteristicas activo',
                    data: [datosRequest.comp4.aportacion]
                },
            ],
            credits: {
                enabled: false,
            }
        };

        return (
            <div style={{ paddingBottom: 40, width: '100%', height: '91%', display: loading ? 'none' : 'block' }}>
                <div style={{ display: 'flex', justifyContent: 'end', marginRight: 10, marginBottom: 10 }}>
                    <Popconfirm
                        title="Establecer €/m2 Vivienda"
                        description={
                            <>
                                <InputNumber addonBefore="" addonAfter="€" defaultValue={0} onChange={onChange} />
                            </>
                        }
                        onConfirm={confirm}
                        onCancel={cancel}
                        okText="Generar Informe"
                        cancelText="Cancelar"
                    >
                        <Button type="primary" /*onClick={}*/>
                            Abrir informe (Liquidez)
                        </Button>
                    </Popconfirm>
                </div>

                <div>
                    <Divider orientation="left" style={{ marginTop: 0, marginBottom: 22 }}>MODELO DE LIQUIDEZ DE UN ACTIVO - VELTIS © ({valll > 0 ? 'DATOS OFICIALES' : 'DATOS APORTADOS'})</Divider>

                    <div className='tabla1'>

                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={4}>COMPONENTES LIQUIDEZ Y PESOS</th>
                                    <th></th>
                                    <th colSpan={4} >

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="subtitulos1">
                                    <td>{veltisTooltip('1. Precio Unitario', <>Valor de referencia de la Dirección General de Catastro expresado en €/m<sup>2</sup></>)}</td>
                                    <td>{veltisTooltip('2. Ratio Dinamismo', <>Transacciónes inmobiliarias entre densidad de población a escala municipal.</>)}</td>
                                    <td> 3. Rating Veltis </td>
                                    <td> {veltisTooltip('4. Características Activo', <>Componente que analiza las características intrínsecas del activo</>)}</td>
                                    <td> <b style={{ fontWeight: 'bold' }}>Liquidez {valll > 0 ? 'Veltis' : 'Estimada'}</b> </td>
                                    <td> {veltisTooltip('Precio Total €', <>Valor total de referencia de la Dirección General de Catastro expresado en €</>)} </td>
                                    <td> {veltisTooltip(<>Precio €/m<sup>2</sup></>, <>Valor de referencia de la Dirección General de Catastro expresado en €/m<sup>2</sup></>)} </td>
                                    <td> {veltisTooltip(<>Precio Zona €/m<sup>2</sup></>, <>Valor de referencia de la Zona Homogénea expresado en €/m<sup>2</sup>.<br />Fuente: Dirección General de Catastro </>)} </td>
                                    <td> {veltisTooltip('Dif. Precio Activo/Zona', <>Diferencia porcentual entre el valor de la Zona Homogénea y el Valor del Activo.</>)} </td>
                                </tr>

                                <tr style={{ backgroundColor: '#e6e4e4' }}>
                                    <td colSpan={9} className="estilo1"><b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>LIQUIDEZ ESTIMADA</b></td>
                                </tr>
                                {tabla1Data.map((item, index) => (
                                    <tr key={index}>
                                        <td className="estilo1">{veltisTooltip(item.precioUnitario, <>Score del componente de 0 a 100 y (Aportación a la liquidez global)</>)}</td>
                                        <td className="estilo1">{item.ratioDinamismo}</td>
                                        <td className="estilo1">{item.ratingVeltis}</td>
                                        <td className="estilo1">{item.caracteristicasActivo}</td>
                                        <td className="estilo1"><b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>{item.liquidezGlobal}</b></td>
                                        <td className="estilo1">{valll > 0 ? item.precioTotal : veltisTooltip(<input type='text' value={precioPrincipal} style={{ width: '100%', textAlign: 'center', textDecoration: 'underline 1px dotted', border: '2px dotted red' }} onChange={handleChangePricePrincipal} onKeyDown={handleKeyDownPrincipal} onFocus={handleFocus} />, "Añade un valor de partida")}</td>
                                        <td className="estilo1">{item.precioEurM2}</td>
                                        <td className="estilo1">{item.sZona}</td>
                                        <td className="estilo1">{item.activoSZona}</td>
                                    </tr>
                                ))}
                                <tr style={{ backgroundColor: 'white' }}>
                                    <td colSpan={9}> &nbsp; </td>
                                </tr>
                                <tr style={{ backgroundColor: '#e6e4e4' }}>
                                    <td colSpan={9}><b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>SIMULACIÓN LIQUIDEZ</b></td>
                                </tr>

                                <tr className="subtitulos1" >
                                    <td><input type='text' value={peso1} style={{ width: '100%', textAlign: 'center', textDecoration: 'underline 1px dotted', border: '1px dotted black', color: errorPesos }} onChange={handleChangePeso1} onKeyDown={handleKeyDownPesos} onFocus={handleFocus} /></td>
                                    <td><input type='text' value={peso2} style={{ width: '100%', textAlign: 'center', textDecoration: 'underline 1px dotted', border: '1px dotted black', color: errorPesos }} onChange={handleChangePeso2} onKeyDown={handleKeyDownPesos} onFocus={handleFocus} /></td>
                                    <td><input type='text' value={peso3} style={{ width: '100%', textAlign: 'center', textDecoration: 'underline 1px dotted', border: '1px dotted black', color: errorPesos }} onChange={handleChangePeso3} onKeyDown={handleKeyDownPesos} onFocus={handleFocus} /></td>
                                    <td><input type='text' value={peso4} style={{ width: '100%', textAlign: 'center', textDecoration: 'underline 1px dotted', border: '1px dotted black', color: errorPesos }} onChange={handleChangePeso4} onKeyDown={handleKeyDownPesos} onFocus={handleFocus} /></td>
                                    <td>Modificadores (peso/valor)</td>
                                    <td><input type='text' value={precioAvanzado} style={{ width: '100%', textAlign: 'center', textDecoration: 'underline 1px dotted', border: '1px dotted black' }} onChange={handleChangePrice} onKeyDown={handleKeyDown} onFocus={handleFocus} /></td>
                                    <td>&nbsp;</td>
                                    <td><input type='text' value={precioAvanzadoZona} style={{ width: '100%', textAlign: 'center', textDecoration: 'underline 1px dotted', border: '1px dotted black' }} onChange={handleChangePriceZona} onKeyDown={handleKeyDownZona} onFocus={handleFocus} /></td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr style={{ backgroundColor: 'white' }}>
                                    <td colSpan={9}> &nbsp; </td>
                                </tr>

                                <tr style={{ backgroundColor: '#e6e4e4' }}>
                                    <td colSpan={9}><b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>RESULTADOS SIMULACIÓN LIQUIDEZ</b></td>
                                </tr>
                                {tabla12Data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{veltisTooltip(item.precioUnitario, <>Score del componente de 0 a 100 y (Aportación a la liquidez global)</>)}</td>
                                        <td>{item.ratioDinamismo}</td>
                                        <td>{item.ratingVeltis}</td>
                                        <td>{item.caracteristicasActivo}</td>
                                        <td><b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>{item.liquidez}</b></td>
                                        <td>{veltisTooltip(item.precioTotal, <>Diferencia porcentual entre el precio de origen y el precio simulado.</>)}</td>
                                        <td>{veltisTooltip(item.precioEurM2, <>Precio unitario de simulación expresado en €/m<sup>2</sup><br />Formula: Precio Total € / Superficie.</>)}</td>
                                        <td>{item.sZona}</td>
                                        <td>{veltisTooltip(item.activoSZona, <>Diferencia porcentual entre el Valor de la Zona Homogénea (Precio €/m2/Zona) y el Valor Simulado del Activo (Precio total €).</>)}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>

                    </div>
                    <div className='graficos1' style={{ height: 200, margin: '0 auto', width: '90%', maxWidth: '900px', minWidth: '600px' }}>
                        <Row gutter={0} justify="center">
                            <Col span={6}  >
                                <div className='chart-container'>
                                    <HighchartsReact highcharts={Highcharts} options={speedometerOptions} />
                                </div>
                            </Col>
                            <Col span={6} style={{ backgroundColor: 'white' }}>
                                <div className='chart-container'>
                                    <HighchartsReact highcharts={Highcharts} options={velocimetroOptions} />
                                </div>
                            </Col>
                            <Col span={6} >
                                <div className='chart-container'>
                                    <HighchartsReact highcharts={Highcharts} options={speedometerOptionsPlus} />
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <br />
                    <div>
                        <div className='pentagonoYTabla' >
                            <Row gutter={0} justify="center" >
                                <Col xs={20} sm={12} md={8} >
                                    <div style={{ background: 'white', marginBottom: '20px', margin: '0 auto', width: '100%', maxWidth: '800px', minWidth: '100px' }} className='chart-container'>
                                        <HighchartsReact highcharts={Highcharts} options={pentagonoOptions} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={12} md={10} className="tabla2">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th colSpan={4} style={{ fontWeight: 600, borderBottom: '2px solid' }}>Datos oficiales utilizados</th>
                                            </tr>
                                            <tr className='subtitulos1'>
                                                <td colSpan={2}> {datosRequest.refcat} </td>
                                                <td style={{ backgroundColor: '#2F75B5', color: 'white' }}> Ref.Activo </td>
                                                <td style={{ backgroundColor: '#2F75B5', color: 'white' }}> Ref.Zona/Municipio </td>
                                            </tr>
                                            {tabla2Data.map((fila) => (
                                                <>
                                                    {fila.columnas.map((columna, columnIndex) => (
                                                        <tr key={columnIndex}>
                                                            <td style={columna.valor_color_nombre}>{columna.nombre}</td>
                                                            <td style={columna.valor_color1}>{columna.valor1}</td>
                                                            <td style={columna.valor_color2}>{columna.valor2}</td>
                                                            <td>{columna.valor3}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </Col>


                                <div style={{ background: 'white', color: 'transparent' }}>
                                    fffffffffffffffff
                                </div>
                            </Row>
                        </div>
                    </div>
                    <br />
                    <div className='tabla3'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={3} style={{ backgroundColor: '#7f7f7f', fontWeight: 'bold', fontSize: 15, borderBottom: '2px solid' }}>
                                        Aportación características activo
                                    </th>
                                    <th colSpan={4} className="aportacion" style={{ fontWeight: 'bold', fontSize: 15, borderBottom: '2px solid black' }}>
                                        Aportación liquidez por componentes
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="subtitulos3">
                                    <td style={{ backgroundColor: '#a1a1a1' }}> Superficie </td>
                                    <td style={{ backgroundColor: '#a1a1a1' }}> Antigüedad </td>
                                    <td style={{ backgroundColor: '#a1a1a1' }}> Calidad </td>
                                    <td style={{ backgroundColor: '#BDD7EE' }}> Precio unitario </td>
                                    <td style={{ backgroundColor: '#BDD7EE' }}> Ratio dinamismo </td>
                                    <td style={{ backgroundColor: '#BDD7EE' }}> Rating </td>
                                    <td style={{ backgroundColor: '#BDD7EE' }}> Características activo </td>
                                </tr>
                                {tabla3Data.map((item, index) => (
                                    <tr key={index} className="subtitulos3">
                                        <td style={{ backgroundColor: '#C8C8C8' }}>{item.superficie}</td>
                                        <td style={{ backgroundColor: '#C8C8C8' }}>{item.antiguedad}</td>
                                        <td style={{ backgroundColor: '#C8C8C8' }}>{item.calidad}</td>
                                        <td style={{ backgroundColor: '#d3e2f0' }}>{item.precioUnitarioDif}</td>
                                        <td style={{ backgroundColor: '#d3e2f0' }}>{item.ratioDinamismo}</td>
                                        <td style={{ backgroundColor: '#d3e2f0' }}>{item.rating}</td>
                                        <td style={{ backgroundColor: '#d3e2f0' }}>{item.caracteristicasActivo}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <div className='graficasHorizontales'>
                            <Row gutter={0} justify="center">
                                <Col xs={2} sm={12} md={9} style={{ backgroundColor: 'white' }}>
                                    <div style={{ background: 'white', margin: '0 auto', width: '100%', maxWidth: '350px', minWidth: '200px', border: '1px solid grey' }} className='chart-container'>
                                        <HighchartsReact highcharts={Highcharts} options={horizontalBarOptions_comp4} />
                                    </div>
                                </Col>
                                <Col xs={14} sm={12} md={13} style={{ backgroundColor: 'white' }}>
                                    <div style={{ background: 'white', margin: '0 auto', width: '100%', maxWidth: '500px', minWidth: '200px', border: '1px solid grey' }} className='chart-container'>
                                        <HighchartsReact highcharts={Highcharts} options={horizontalBarOptions_comp} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='tabla1'>
                        <p>
                            <br />
                            <b style={{ fontWeight: 'bold' }}>LIQUIDEZ AGREGADA VELTIS®:</b> Expresión dinámica de la ventaja comparativa de un activo con respecto a sus pares en un periodo de tiempo determinado. Tiene en cuanto los indicadores con más peso correlacionados con la liquidez de un activo inmobiliario, que son: precio, dinamismo de mercado, rating inmobiliario y características del activo. Indicadores que se actualizan trimestralmente, ya que la Liquidez es dinámica.
                            <br /><br />
                        </p>
                    </div>
                    {/* <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                            <img src={logo4} style={{ width: '100%' }}></img>*/}
                </div>
            </div >
        )
    }


}

export default Liquidez