import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-legend';
import AfeccionDrawer from '../AfeccionDrawer/AfeccionDrawer';
import "leaflet/dist/leaflet.css";
import "./Mapa.css";
import { RatingData } from '../../hooks/Rating/Rating';
import { Button, Modal } from 'antd';
import Selector from './Selector';

L.Icon.Default.imagePath = 'leaflet_images/';

const Mapa = ({ mapClicked, gridSize, activosInGrid, setACtivoSeletected, setoverSelectedItem, overSelectedItem, leyendaElegida, setLeyendaElegida }) => {

  const filtrosMapa = [
    {
      id: 0, name: 'Rating', activated: true, data: [
        { id: 1, score: 100, name: 'AAA', color: '1c4a16' },
        { id: 2, score: 95, name: 'AA+', color: '34641c' },
        { id: 3, score: 90, name: 'AA', color: '4c7f22' },
        { id: 4, score: 85, name: 'AA-', color: '659928' },
        { id: 5, score: 80, name: 'A+', color: '84a720' },
        { id: 6, score: 75, name: 'A', color: 'a4b617' },
        { id: 7, score: 70, name: 'A-', color: 'c3c50f' },
        { id: 8, score: 65, name: 'BBB+', color: 'e3d407' },
        { id: 9, score: 60, name: 'BBB', color: 'ffdf00' },
        { id: 10, score: 55, name: 'BBB-', color: 'fec400' },
        { id: 11, score: 50, name: 'BB+', color: 'fda900' },
        { id: 12, score: 45, name: 'BB', color: 'fb8e00' },
        { id: 13, score: 40, name: 'BB-', color: 'fa7300' },
        { id: 14, score: 35, name: 'B+', color: 'f85900' },
        { id: 15, score: 30, name: 'B', color: 'f73e00' },
        { id: 16, score: 25, name: 'B-', color: 'f52300' },
        { id: 17, score: 20, name: 'CCC', color: 'f40800' },
        { id: 18, score: 15, name: 'CC', color: 'c80000' },
        { id: 19, score: 10, name: 'C', color: '880000' },
        { id: 20, score: 5, name: 'D', color: '490000' },
      ]
    },
    {
      id: 1, name: 'Certificación Energética Estimada', activated: false, data: [
        { id: 1, score: 'A', name: 'Calificación A', color: '00963F' },
        { id: 2, score: 'B', name: 'Calificación B', color: '21B24B' },
        { id: 3, score: 'C', name: 'Calificación C', color: '99ca3d' },
        { id: 4, score: 'D', name: 'Calificación D', color: 'ece824' },
        { id: 5, score: 'E', name: 'Calificación E', color: 'F0B418' },
        { id: 6, score: 'F', name: 'Calificación F', color: 'E07627' },
        { id: 7, score: 'G', name: 'Calificación G', color: 'E52E29' },
      ]
    },
    {
      id: 2, name: 'Renta Media por Hogar', activated: false, data: [
        { id: 1, score: '>40k', name: '>40.000€', color: '00963F' },
        { id: 3, score: '30k-40k', name: '30.000€-40.000€', color: '99ca3d' },
        { id: 4, score: '20k-30k', name: '20.000€-30.000€', color: 'ece824' },
        { id: 5, score: '10k-20k', name: '10.000€-20.000€', color: 'F0B418' },
        { id: 7, score: '<10000', name: '<10.000€', color: 'E52E29' },
      ]
    },
    {
      id: 3, name: 'Antigüedad', activated: false, data: [
        { id: 1, score: '<10 años', name: '<10 años', color: '00963F' },
        { id: 2, score: '10-20 años', name: '10-20 años', color: '99ca3d' },
        { id: 3, score: '30-40 años', name: '30-40 años', color: 'ece824' },
        { id: 4, score: '40-50 años', name: '40-50 años', color: 'F0B418' },
        { id: 5, score: '>50 años', name: '>50 años', color: 'E52E29' },
      ]
    },
    {
      id: 4, name: 'Taxonomía', activated: false, data: [
        { id: 1, score: 'Verde', name: 'Verde', color: '00963F' },
        { id: 2, score: 'Sostenible', name: 'Sostenible', color: 'ece824' },
        { id: 3, score: 'No sostenible', name: 'No sostenible', color: 'E52E29' },
      ]
    },

  ]

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const map = useRef();
  const geoJsonLayer = useRef();
  const [geometrias, setGeometrias] = useState(null);
  const [capas, setCapas] = useState();
  const [filtrosLeyendaMapa, setFiltrosLeyendaMapa] = useState(filtrosMapa);
  const [chageGeometriasFromLayer, setchageGeometriasFromLayer] = useState(false);


  // comprueba si hay alguún filtro activo y lo aplica a la leyenda
  useEffect(() => {
    let opcion;
    filtrosLeyendaMapa.forEach((item) => {
      if (item.activated === true) opcion = item;
    })
    if (map.current) {
      map.current.legendAdded = false;
      if (document.getElementsByName('filtros-mapa').length > 0) document.getElementsByName('filtros-mapa')[0].parentNode.parentNode.remove();
      setLeyendaElegida(opcion);
    }
  }, [filtrosLeyendaMapa])


  // recarga la posición del mapa para mostrar los nuevos datos cargados 
  // con el useState se le indica si debe cambiar la posición del mapa o no
  useEffect(() => {
    if (mapClicked === 3 && map !== null && map.current !== null) map.current.invalidateSize();

    if (geometrias) {
      geoJsonLayer.current.clearLayers().addData(geometrias);
      if (chageGeometriasFromLayer === false && geometrias.length > 0) {
        map.current.fitBounds(geoJsonLayer.current.getBounds());
      }
      else {
        setchageGeometriasFromLayer(false);
      }
    }
  }, [mapClicked, gridSize, geometrias]);

  useEffect(() => {
    setchageGeometriasFromLayer(true);
  }, [leyendaElegida])

  // genera la leyenda del mapa y controla el modal
  useEffect(() => {
    const generateLegend = () => {
      const legend = L.control({ position: 'bottomright' });

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'legend');
        div.onclick = function (e) {
          if (e.target.id === "filtros-mapa") {
            showModal();
          }
        }

        const legendHTML = leyendaElegida.data.map((color) => (
          `<div style="display: flex;">
          <div class="legend-color" style="background-color: #${color.color};"></div>
          <div class="legend-label">${color.name}</div>
          </div>`
        )).join('');

        div.innerHTML =
          `<div>
            <button name="filtros-mapa"  id="filtros-mapa">Leyenda Mapa: <br /><span style="text-wrap: wrap;">${leyendaElegida.name}</span></button>
            ${legendHTML}
          </div>`;

        return div;
      };
      legend.addTo(map.current);
    };

    if (map.current && !map.current.legendAdded) {
      generateLegend();
      tempChangeColors();
      map.current.legendAdded = true;
    }
  }, [geometrias, leyendaElegida])


  useEffect(() => {
    const retorno = [];
    if (activosInGrid.length > 0 && activosInGrid[0] !== undefined) {
      for (const iterator of activosInGrid) {
        if (iterator !== undefined && iterator.parcela_geom !== undefined) {
          const feature = {
            type: 'Feature',
            properties: iterator,
            geometry: JSON.parse(iterator.parcela_geom),
          };
          if (iterator.rating_parcela !== null && iterator.rating_parcela !== undefined) {
            feature.properties.color = '#' + RatingData(iterator.rating_parcela).color;
          }
          retorno.push(feature);
        }
      }
      if (retorno.length > 0) {
        setGeometrias(retorno);
        if (geoJsonLayer.current) {
          geoJsonLayer.current.clearLayers().addData(retorno);
          map.current.fitBounds(geoJsonLayer.current.getBounds())
        }
      }
      tempChangeColors();
    } else {
      setGeometrias([]);
    }
  }, [activosInGrid])

  // esta función genera un número para probar el cambio de color en los filtros, al conectar a la api se puede borrar
  function ponerColorRandom() {
    const rngNumber = Math.floor(Math.random() * (4 - 0 + 1) + 0);
    return String(leyendaElegida.data[rngNumber].color);
  }

  function ponerColorRiskFilterCee(value) {
    const index = filtrosMapa.findIndex((objeto) => objeto.id === 1);
    const subindex = filtrosMapa[index].data.findIndex((objeto) => objeto.score === value);
    return String(filtrosMapa[index].data[subindex].color);
  }

  function ponerColorRiskRMH(value) {
    const index = filtrosMapa.findIndex((objeto) => objeto.id === 2);
    let subindex;
    if (parseInt(value) >= 40000) subindex = 0;
    else if (parseInt(value) >= 30000) subindex = 1;
    else if (parseInt(value) >= 20000) subindex = 2;
    else if (parseInt(value) >= 10000) subindex = 3;
    else subindex = 4;
    return String(filtrosMapa[index].data[subindex].color);
  }

  function ponerColorRiskAntiguedad(value) {
    const index = filtrosMapa.findIndex((objeto) => objeto.id === 3);
    let subindex;
    if (parseInt(value) >= 2023 - 10) subindex = 0;
    else if (parseInt(value) >= 2023 - 20) subindex = 1;
    else if (parseInt(value) >= 2023 - 40) subindex = 2;
    else if (parseInt(value) >= 2023 - 50) subindex = 3;
    else subindex = 4;
    return String(filtrosMapa[index].data[subindex].color);
  }

  function ponerColorRiskTaxonomia(value) {
    const index = filtrosMapa.findIndex((objeto) => objeto.id === 4);
    let subindex;
    if (parseInt(value) === 2) subindex = 0;
    else if (parseInt(value) === 1) subindex = 1;
    else subindex = 2;
    return String(filtrosMapa[index].data[subindex].color);
  }

  // esta función comprueba que filtro esta activado y si existe y en base a ello aplica unos colores u otros
  function tempChangeColors() {
    const retorno = [];
    if (activosInGrid.length > 0 && activosInGrid[0] !== undefined) {
      for (const iterator of activosInGrid) {
        if (iterator !== undefined && iterator.parcela_geom !== undefined) {
          const feature = {
            type: 'Feature',
            properties: iterator,
            geometry: JSON.parse(iterator.parcela_geom),
          };

          switch (leyendaElegida.id) {
            case 0:
              if (iterator.rating_parcela !== null && iterator.rating_parcela !== undefined) {
                feature.properties.color = '#' + RatingData(iterator.rating_parcela).color;
              }
              break;
            case 1:
              if (iterator.cee_predicted !== null && iterator.cee_predicted !== undefined) {
                feature.properties.color = '#' + ponerColorRiskFilterCee(iterator.cee_predicted);
              }
              break;
            case 2:
              if (iterator.rmh_2019 !== null && iterator.rmh_2019 !== undefined) {
                feature.properties.color = '#' + ponerColorRiskRMH(iterator.rmh_2019);
              }
              break;
            case 3:
              if (iterator.tabla14_antiguedad_efectiva !== null && iterator.tabla14_antiguedad_efectiva !== undefined) {
                feature.properties.color = '#' + ponerColorRiskAntiguedad(iterator.tabla14_antiguedad_efectiva);
              }
              break;
            case 4:
              if (iterator.taxonomia !== null && iterator.taxonomia !== undefined) {
                feature.properties.color = '#' + ponerColorRiskTaxonomia(iterator.taxonomia);
              }
              break;
            default:
              if (iterator.rating_parcela !== null && iterator.rating_parcela !== undefined) {
                feature.properties.color = '#' + RatingData(iterator.rating_parcela).color;
              }
              break;
          }
          retorno.push(feature);
        }
      }
      if (retorno.length > 0) {
        setGeometrias(retorno);
        if (geoJsonLayer.current) {
          geoJsonLayer.current.clearLayers().addData(retorno);
        }
      }
    }
  }

  function handleClick(e) {
    const layer = e.target;
    setACtivoSeletected([e.target.feature.properties]);
    const zoomLevel = 20;
    const latLng = layer.getCenter();
    layer._map.setView(latLng, zoomLevel, {
      animate: true,
      duration: 0.5,
    });
  }

  function onEachFeature(feature, layer) {
    layer.on({
      click: handleClick,
    });
  }

  function style(feature) {
    if (capas !== undefined) {
      capas.map((item) => {
        return {
          color: feature.properties.color,
          opacity: (item.opacity / 100),
          fillOpacity: (item.opacity / 100),
        }
      })
    } else {
      return {
        color: feature.properties.color,
      }
    }
  }

  // modal functions
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <AfeccionDrawer setoverSelectedItem={setoverSelectedItem} overSelectedItem={overSelectedItem} setCapas={setCapas} />
      <MapContainer
        center={[40.416775, -3.703790]}
        zoom={5}
        ref={map}
        scrollWheelZoom={true}
        id='map-container'
        style={gridStyle}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
        />

        {geometrias && <GeoJSON key="whatever" data={geometrias} ref={geoJsonLayer} onEachFeature={onEachFeature} style={style} />}

      </MapContainer>
      <Modal
        open={open}
        title="Seleccione Leyenda"
        onOk={handleOk}
        onCancel={handleCancel}
        width={'300px'}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>Aplicar</Button>,
        ]}
      >
        <Selector options={filtrosLeyendaMapa} setOptions={setFiltrosLeyendaMapa} />
      </Modal>
    </>
  );
}

export default Mapa;
