import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Button, Skeleton } from 'antd';
import './AnalisisCartera.css';
import Highcharts from 'highcharts';
import { PrinterOutlined } from '@ant-design/icons';
import HighchartsReact from 'highcharts-react-official';
import HighchartsVariablePie from 'highcharts/modules/variable-pie';
import ExpandableTable from '../TablaDesplegable/TablaDesplegable';
import { requestPost } from '../../hooks/Request/Request';
HighchartsVariablePie(Highcharts);

const AnalisisCartera = ({ dataa, carteras, setCarteras }) => {

    const [carteraSelected, setCarteraSelected] = useState(null);
    const [dataChart, setDataChart] = useState([]);
    const [colors, setColors] = useState([]);
    const [dataInv, setDataInv] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setCarteraSelected(null);
        setColors([]);
        setDataInv(null);
        setDataChart([]);
        setLoading(true);
        const arrayFiltrado = carteras.filter(caca => caca.checked === true);
        if (arrayFiltrado && arrayFiltrado.length > 0) {
            setCarteraSelected(arrayFiltrado[0]);
            const datos = arrayFiltrado[0];

            const consulta = async () => {

                const moco = await requestPost(
                    'https://terminal.veltisrating.com:8888/api/getCarteraDistRating',
                    { cartera_id: datos.id }
                )

                let valor_cartera = 0, cnt_cartera = 0;
                for (const iterator of moco.data) {
                    valor_cartera += iterator.precio;
                    cnt_cartera += iterator.cnt;
                }

                let sum_n1 = 0, sum_d1 = 0, activosInversion = 0, activosEspeculacion = 0, totalActivos = 0;
                let dataaChart = [], colorrs = [];
                for (const iterator of moco.data) {
                    const peso = ((iterator.precio * 100) / valor_cartera);
                    const pct_rating = ((iterator.cnt * 100) / cnt_cartera);
                    const valor_ponderado = iterator.precio * peso;
                    const n1 = iterator.rating_parcela * peso;
                    sum_n1 += n1;
                    sum_d1 += peso;
                    if (parseInt(iterator.rating_parcela) >= 10) {
                        activosInversion += parseInt(valor_ponderado);
                    } else {
                        activosEspeculacion += parseInt(valor_ponderado);
                    }
                    totalActivos += parseInt(valor_ponderado);
                    dataaChart.push(
                        {
                            name: iterator.valorrating,
                            //y: iterator.cnt,
                            y: parseInt(pct_rating),
                            z: parseInt(valor_ponderado),
                        }
                    )
                    colorrs.push(`#${iterator.color}`)
                }
                const pctInversion = parseFloat((activosInversion * 100) / totalActivos).toFixed(2);
                const percentEspeculacion = parseFloat(100 - pctInversion).toFixed(2);
                setDataInv({
                    inversion: {
                        pct: pctInversion,
                        color: 'rgb(101, 153, 40)'
                    },
                    especulacion: {
                        pct: percentEspeculacion,
                        color: 'rgb(244, 8, 0)'
                    }
                })
                setDataChart(dataaChart);
                setColors(colorrs);
                setLoading(false);
            }
            consulta();
        }
    }, [carteras])

    const options = {
        chart: {
            type: 'variablepie',
            reflow: true,
            //backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
        },
        title: {
            text: `Distribución Cartera`,
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
        colors: colors,
        plotOptions: {
            variablepie: {
                dataLabels: {
                    enabled: true,
                    alignTo: 'connectors',
                    style: {
                        //color: 'rgb(255,255,255,.75)',
                        //textOutline: 'transparent'
                    },
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                enableMouseTracking: false
            }
        },
        series: [{
            silent: false,
            name: 'Dist. Ratings',
            colorByPoint: true,
            data: dataChart
        }]
    }

    const options1 = {
        chart: {
            type: 'pie',
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: true,
        },
        title: {
            text: `Tipo cartera`,
        },
        accessibility: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        colors: dataInv !== null ? [dataInv.especulacion.color, dataInv.inversion.color] : [],
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                innerSize: '50%',
                size: '60%',
                dataLabels: {
                    enabled: true,
                    distance: 5,
                    marginLeft: 0,
                    connectorPadding: 0,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                }
            },
            variablepie: {
                dataLabels: {
                    enabled: true,
                },
            }
        },
        series: [{
            name: 'Ejemplo de serie',
            data: [
                ['Especulación', dataInv !== null ? parseFloat(dataInv.especulacion.pct) : 0],
                ['Inversión', dataInv !== null ? parseFloat(dataInv.inversion.pct) : 0]
            ]
        }]
    };

    const scroll1 = (id) => {
        const section = document.querySelector('#' + id);
        section.scrollIntoView({ behavior: 'smooth', block: 'end' })
    };

    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        setShowContent(false);
        const timeout = setTimeout(() => {
            setShowContent(true);
        }, 1);

        return () => {
            clearTimeout(timeout);
        }
    }, [dataa, carteras]);

    return (
        <div style={{ whiteSpace: 'none', marginRight: 0, paddingBottom: 50 }}>
            {
                /*<div className='botones'>
                    <Button onClick={() => { scroll1('section33') }}>Sec1</Button>
                    <Button onClick={() => { scroll1('section3') }}>Sec3</Button>

                </div>*/
            }
            <div style={{ float: 'right', marginRight: 5, marginLeft: 5 }}>
                {/*<Button type="default" disabled = {carteraSelected == null} icon={<PrinterOutlined />} size={'medium'}>
                            {carteraSelected !==null ? 
                                <a href={'https://terminal.veltisrating.com/hud/informe_cartera/' + carteraSelected.id}
                                    target="_blank"
                                    rel="noreferrer">
                                    Imprimir informe
                                </a>
                                :
                                'Imprimir informe'
                            }
                        </Button>*/}
            </div>,
            <Divider orientation="left" id='section33'>Comparativa Carteras</Divider>
            <div style={{ width: '100%' }}>
                {showContent === true ? <ExpandableTable dataa={dataa} carteras={carteras} setCarteras={setCarteras} /> : <></>}
            </div>
            <br />

            {carteraSelected !== null ?
                <div>
                    <Divider orientation="left" id='section1'> Análisis Cartera {carteraSelected.name}</Divider>
                    {
                        (loading) ? (
                            <>
                                <Skeleton active />
                            </>
                        ) : (
                            <>
                                <Row className='content'>
                                    <Col xs={24} sm={15} md={11}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={options1}
                                        />
                                    </Col>
                                    <Col xs={24} sm={15} md={11}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={options}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </div>
                :
                <div></div>
            }


            {
                /*<br /><br />
                <Button onClick={() => { scroll1('section33') }} > Volver arriba </Button>
                <FloatButton.BackTop
                    visibilityHeight={10}
                />*/
            }
        </div>
    )
}
export default AnalisisCartera;