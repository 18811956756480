import React from 'react'
import FilterCat from './FilterCat'



const Filters = ({ indicecat, subcategorias, usestateListaFiltros, setusestateListaFiltros }) => {
    

    return (
        <FilterCat indicecat={indicecat} subcategorias={subcategorias} usestateListaFiltros={usestateListaFiltros} setusestateListaFiltros={setusestateListaFiltros} />
    )
}

export default Filters