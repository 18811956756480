export const RatingData = (rating) => {

    if( rating === null || rating === '' || isNaN(rating) === true || rating === undefined ) return null;
    rating = Number(rating);
    
    if (rating === 19) return ({'score': 100, 'valorRating': 'AAA', 'color': '1c4a16', 'rentabilidad': '2.50', 'text': 'Activo de excepcional calidad', 'volatilidad': 'Muy Baja' });
    else if (rating === 18) return ({'score': 95, 'valorRating': 'AA+', 'color': '34641c', 'rentabilidad': '2.72', 'text': 'Activo de muy alta calidad', 'volatilidad': 'Muy Baja' });
    else if (rating === 17) return ({'score': 90, 'valorRating': 'AA', 'color': '4c7f22', 'rentabilidad': '2.94', 'text': 'Excepcionalmente bajo riesgo', 'volatilidad': 'Muy Baja' });
    else if (rating === 16) return ({'score': 85, 'valorRating': 'AA-', 'color': '659928', 'rentabilidad': '3.16', 'text': 'Muy bajo riesgo', 'volatilidad': 'Muy Baja' });
    else if (rating === 15) return ({'score': 80, 'valorRating': 'A+', 'color': '84a720', 'rentabilidad': '3.38', 'text': 'Riesgo bajo con algunas inquietudes', 'volatilidad': 'Muy Baja' });
    else if (rating === 14) return ({'score': 75, 'valorRating': 'A', 'color': 'a4b617', 'rentabilidad': '3.60', 'text': 'Moderadamente riesgo bajo y cierta fluctuación a las condiciones del mercado', 'volatilidad': 'Muy Baja' });
    else if (rating === 13) return ({'score': 70, 'valorRating': 'A-', 'color': 'c3c50f', 'rentabilidad': '3.82', 'text': 'Riesgo moderado, generalmente', 'volatilidad': 'Muy Baja' });
    else if (rating === 12) return ({'score': 65, 'valorRating': 'BBB+', 'color': 'e3d407', 'rentabilidad': '4.04', 'text': 'Riesgo moderado, sujeto a condiciones del mercado', 'volatilidad': 'Baja' });
    else if (rating === 11) return ({'score': 60, 'valorRating': 'BBB', 'color': 'ffdf00', 'rentabilidad': '4.26', 'text': 'Riesgo moderado, puede emperorar según condiciones del mercado', 'volatilidad': 'Baja' });
    else if (rating === 10) return ({'score': 55, 'valorRating': 'BBB-', 'color': 'fec400', 'rentabilidad': '4.48', 'text': 'Riesgo medio a moderado y sujeto a condiciones del mercado', 'volatilidad': 'Baja' });
    else if (rating === 9) return ({'score': 50, 'valorRating': 'BB+', 'color': 'fda900', 'rentabilidad': '4.71', 'text': 'Riesgo medio', 'volatilidad': 'Media' });
    else if (rating === 8) return ({'score': 45, 'valorRating': 'BB', 'color': 'fb8e00', 'rentabilidad': '4.94', 'text': 'Riesgo medio, sujeto a condiciones del mercado', 'volatilidad': 'Media' });
    else if (rating === 7) return ({'score': 40, 'valorRating': 'BB-', 'color': 'fa7300', 'rentabilidad': '5.17', 'text': 'Riesgo medio, puede empeorar según condiciones del mercado', 'volatilidad': 'Media' });
    else if (rating === 6) return ({'score': 35, 'valorRating': 'B+', 'color': 'f85900', 'rentabilidad': '5.75', 'text': 'Riesgo medio a alto y vulnerable al mercado', 'volatilidad': 'Alta' });
    else if (rating === 5) return ({'score': 30, 'valorRating': 'B', 'color': 'f73e00', 'rentabilidad': '6.33', 'text': 'Riesgo moderadamente alto', 'volatilidad': 'Alta' });
    else if (rating === 4) return ({'score': 25, 'valorRating': 'B-', 'color': 'f52300', 'rentabilidad': '6.91', 'text': 'Riesgo alto', 'volatilidad': 'Alta' });
    else if (rating === 3) return ({'score': 20, 'valorRating': 'CCC', 'color': 'f40800', 'rentabilidad': '8.18', 'text': 'Riesgo muy alto', 'volatilidad': 'Alta' });
    else if (rating === 2) return ({'score': 15, 'valorRating': 'CC', 'color': 'c80000', 'rentabilidad': '9.45', 'text': 'Riesgo muy alto, muy vulnerable al mercado', 'volatilidad': 'Alta' });
    else if (rating === 1) return ({'score': 10, 'valorRating': 'C', 'color': '880000', 'rentabilidad': '12.24', 'text': 'Riesgo extremadamente alto', 'volatilidad': 'Muy Alta' });
    else if (rating === 0) return ({'score': 5, 'valorRating': 'D', 'color': '490000', 'rentabilidad': '15.04', 'text': 'Riesgo extremadamente alto, presiones externas', 'volatilidad': 'Muy Alta' });
    else return null;
}

export const CEEData = (rating) => {
    if( rating === null || rating === '' || rating === undefined ) return null;
    if (rating === 'A') return ({'score': 100, 'valorRating': 'A', 'color': '00963F',  });
    else if (rating === 'B') return ({'score': 95, 'valorRating': 'B', 'color': '21B24B',  });
    else if (rating === 'C') return ({'score': 90, 'valorRating': 'C', 'color': '99ca3d',  });
    else if (rating === 'D') return ({'score': 85, 'valorRating': 'D', 'color': 'ece824',  });
    else if (rating === 'E') return ({'score': 80, 'valorRating': 'E', 'color': 'F0B418',  });
    else if (rating === 'F') return ({'score': 75, 'valorRating': 'F', 'color': 'E07627',  });
    else if (rating === 'G') return ({'score': 70, 'valorRating': 'G', 'color': 'E52E29', });
    else return null;
}

