import React, { useEffect, useState } from 'react'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './GraficasClimaticas.css';
import { Result, Divider } from 'antd';

function GraficasClimaticas({ datooo }) {
    const indicadores = [
        { NAMEDB: 'AGREG', name: "Riesgo Agregado - Edificio", key: 0, active: false, color: '#FF5733', visible: true },
        { NAMEDB: 'INCEN', name: "Inundación Costera - Edificio", key: 1, active: false, color: '#FF5733', visible: true },
        { NAMEDB: 'INUND', name: "Inundación Fluvial - Edificio", key: 2, active: false, color: '#33FF57', visible: true },
        { NAMEDB: 'HURAC', name: "Demanda Calefacción - Edificio", key: 3, active: false, color: '#5733FF', visible: true },
        { NAMEDB: 'TERRE', name: "Demanda Refrigeración - Edificio", key: 4, active: false, color: '#FF33A1', visible: true },
        { NAMEDB: 'INCEN', name: "Inundación Costera - Censo", key: 5, active: false, color: '#FF5733', visible: true },
        { NAMEDB: 'INUND', name: "Inundación Fluvial - Censo", key: 6, active: false, color: '#33FF57', visible: true },
        { NAMEDB: 'HURAC', name: "Demanda Calefacción - Censo", key: 7, active: false, color: '#5733FF', visible: true },
        { NAMEDB: 'TERRE', name: "Demanda Refrigeración - Censo", key: 8, active: false, color: '#FF33A1', visible: true },
    ]
    const [series, setSeries] = useState([]);
    const yLabels = ['muy bajo', 'bajo', 'medio', 'alto', 'muy alto'];
    const [yAxis, setYaxis] = useState([]);
    const [indicadores1, setIndicadores1] = useState(indicadores);

    useEffect(() => {
        const activeIndicador = indicadores1.filter((indicador) => indicador.active);
        const newSeries = activeIndicador.map((indicador, index) => ({
            name: indicador.name,
            color: indicador.color,
            data: indicador.data,
            yAxis: index,
        }));
        const newYaxis = activeIndicador.map((indicador, index) => ({
            labels: {
                style: {
                    color: activeIndicador[index].color
                },
                formatter: function () {
                    return yLabels[this.value]; // Utiliza el valor como índice en yLabels para obtener la etiqueta correspondiente
                }
            },
            title: {
                text: indicador.name,
                style: {
                    color: activeIndicador[index].color
                }
            },
        }));

        setSeries(newSeries);
        setYaxis(newYaxis);
    }, [indicadores1]);


    function addGrafico(e) {
        const indicadoresTmp = JSON.parse(JSON.stringify(indicadores1));
        const index = indicadoresTmp.findIndex((objeto) => objeto.key === e.key);
        
        const data = datooo.map((item) => {
            return item.map((element) => {
                switch (element.risk) {
                    case 'very high':
                        return 3;
                    case 'high':
                        return 2.5;
                    case 'medium':
                        return 1.6;
                    case 'low':
                        return 1.4;
                    case 'very low':
                        return 0.2;
                    default:
                        return 0;
                }
            });
        });
        indicadoresTmp[index] = {
            ...indicadoresTmp[index],
            data: data[index],
            active: !indicadoresTmp[index].active,
          };
        setIndicadores1(indicadoresTmp);
    }

    const botonera = indicadores1.map((item) => {
        return (
            <div className='div-padre-boton' onClick={() => addGrafico(item)}>
                {item.active ? <CheckCircleOutlined /> : ''}
                <p className='nombre-indicador-boton'>{item.name}</p>
            </div>
        )
    })

    const equis = series.length > 0 ? `Restablecer Todos` : null;
    const equisIcon = series.length > 0 ? <CloseCircleOutlined /> : null;

    const borrar = () => {
        const indicadoresCero = indicadores1.map((riesgo) => ({
            ...riesgo,
            active: false,
        }));
        setIndicadores1(indicadoresCero);
    };

    return (
        <div>
            <Divider orientation="left" style={{ marginTop: 0, marginBottom: 22 }}>Riesgos Climáticos</Divider>
            <div className='div-botonera'>
                {botonera}
            </div>
            <div>
                {series.length >= 1 ? (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            series,
                            yAxis,
                            xAxis: {
                                categories: [
                                    '1971-2000', '2001-2040', '2041-2070', '2071-2100'
                                ],
                            },
                            
                            title: { text: '' },
                            credits: { enabled: false },
                            tooltip: {
                                formatter: function () {
                                    const pointValue = this.point.y;
                                    const level = yLabels[Math.round(pointValue)];
                                    return `<b>${this.x}</b><br/>Riesgo: ${level}`;
                                }
                            }
                        }}
                    />
                ) : (
                    <Result
                       // title="No se puede mostrar información"
                       icon={null}
                        subTitle="Seleccione un indicador climatico para acceder a su gráfica."
                        style={{ margin: 'auto' }}
                    />
                )
                }
            </div>
            <div onClick={borrar} className='restablecerRiesgos' style={{ position: 'absolute', top: 75, right: 10, fontSize: '12px' }}>{equisIcon} {equis}</div>
        </div>
    )
};

export default GraficasClimaticas