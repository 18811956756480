import React from 'react'
import { useState } from 'react';
import logo1 from './logos/catastroBN.png'
import logo3 from './logos/catastroColor.png'
import { AutoComplete, Button, Space, Tooltip } from 'antd';
import { requestPost } from '../../hooks/Request/Request';
import ModalBuscador from './ModalBuscador';

const getRandomInt = (max, min = 0) => Math.floor(Math.random() * (max - min + 1)) + min;
const searchResult = (query) =>
    new Array(getRandomInt(5))
        .join('.')
        .split('.')
        .map((_, idx) => {
            const category = `${query}${idx}`;
            return {
                value: category,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        key={idx}
                    >
                        <span>
                            {category}
                        </span>
                        <span>Población <span style={{ color: 'var(--color-primary-color)' }}>Palencia</span></span>
                    </div>
                ),
            };
        });

const Buscador = ({setACtivoSeletected}) => {
    const data = [
        { calle: "Dato1", provincia: "Segovia" },
        { calle: "Dato2", provincia: "Bizkaia" },
        { calle: "Dato3", provincia: "Palencia" },
        { calle: "Dato4", provincia: "Toledo" },
        { calle: "Dato5", provincia: "Cádiz" },
        { calle: "Dato6", provincia: "Álava" }];
    const [input, setInput] = useState("")
    const [state, setstate] = useState(false)
    const onChange = (e) => {
        setInput(e.target.value)
    }
    let results = []
    if (!input) {
        results = data
    } else {
        results = data.filter((dato) =>
            dato.calle.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').trim().includes(input.trim().toLowerCase()) ||
            dato.provincia.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').trim().includes(input.trim().toLowerCase()))
    }
    const onclick = () => {
        state === true ? setstate(false) : setstate(true)
    }
    const resetText = () => {
        setInput("")
    }

    const [options, setOptions] = useState([]);
    const handleSearch = async (value) => {
        setOptions([]);

        if (state === false) {
        const retorno = [];
            if (value.length > 8) {
                const response = await requestPost(
                'https://terminal.veltisrating.com:8888/api/search_term',
                { search: value.toUpperCase() }
            )
            if (response.data.length > 0) {
                for (const iterator of response.data) {
                    retorno.push(
                        {
                            value: iterator.full_refcat,
                            label: (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <span>
                                        {iterator.full_refcat}
                                    </span>
                                    <span>Población <span style={{ color: 'var(--color-primary-color)' }}>{iterator.municipio_name}</span></span>
                                </div>
                            ),
                            datos: iterator,
                        }
                    )
                }
            }
            }
            setOptions(retorno);
        }
    };
    const onSelect = (value, option) => {
        setACtivoSeletected([option.datos]);
    };

    return (
        <>
            <Space.Compact block>

                <AutoComplete
                    allowClear={true}
                    style={{
                        width: 'calc(100%)',
                        borderRadius: 1,
                        background: 'var(--color-input-bg)',
                    }}
                    placeholder={state === false ? "Ref. Catastral, nº catastro..." : "Dirección postal, CP, Calle..."}
                    options={options}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                    popupClassName="certain-category-search-dropdown"
                    popupMatchSelectWidth={500}
                ></AutoComplete>
                <Tooltip title="Buscar por referencia catastral" placement="bottom">
                    <Button style={{ background: 'var(--color-input-bg)', }} icon={<input type="image" onClick={onclick} className="imagenCatastro" src={state === true ? logo1 : logo3} alt="Búsqueda por catastro" />} />
                </Tooltip>
                <ModalBuscador setACtivoSeletected={setACtivoSeletected}/>
            </Space.Compact>
        </>

    )
}
export default Buscador;