import About from "./view/pages/About";
import Body from "./view/pages/Main/Body";
import Contact from "./view/pages/Contact";
import Home from "./view/pages/Home";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";


import React from 'react'

const App = () => {
  const [logged, setLogged] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      // setIsLoading(true);
      // const loaderElement = document.querySelector(".loader-container");
      // if (loaderElement) {
      //   loaderElement.remove();
      //   setIsLoading(!isLoading);
      // }
      try {
        const token = Cookies.get('token');
        if (!token) {
          setLogged(false);
        }
        const response = await fetch('https://terminal.veltisrating.com:8888/api/ping', {
          headers: {
            'Content-Type': 'application/json', 'authorization': token
          }
        });

        if (response.status === 200) {
          setLogged(true);
        } else {
          setLogged(false);
          console.error('Error al autentificar la sesion');
        }
        setIsLoading(false);
      } catch (error) {
        setLogged(false);
        console.error('Error al autentificar la sesion');
        setIsLoading(false);
      }
    };
        
    const interval = setInterval(() => {
      checkAuthentication();
    }, 5000);
    return () => clearInterval(interval);

  }, [])

  if (logged === false) {
    return (
      <Home setLogged={setLogged} />
    )
  }
  else if( logged === null ) {
    return("");
  }

  // if (isLoading === true) return null;

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={< Body />} />
            <Route path="/about" element={<About />} />
            <Route path="/about/:topicId" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/main" element={< Body />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App

