import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Modal, Space, message, Form, Input, theme } from 'antd';
import React, { useState } from 'react';
import { PlusSquareOutlined, ExclamationCircleFilled, } from '@ant-design/icons';
import "./Drop.css";
import { RequestGet, requestPost } from '../../hooks/Request/Request';


const { useToken } = theme;

const Drop = ({ title, canAdd, data, tipo, seleteced, setseletected, setnoveltisselected}) => {

    const { token } = useToken();
    const [form] = Form.useForm();
    const [items, setItems] = useState(data);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [valueInput, setvalueInput] = useState();
    const [estadoOpen, setestadoOpen] = useState(false)
    const handleOk = () => { setIsModalOpen(false); addPantalla(valueInput); };
    const handleCancel = () => { setIsModalOpen(false); };
    const onClick = ({ key }) => { setestadoOpen(!estadoOpen); const index = items.findIndex((objeto) => objeto.key === Number(key)); if( Number(key) !== 0 ) { setseletected(items[index]); setnoveltisselected(null); } else { setseletected(null); setnoveltisselected(null);} };
    const nextItem = function () { return items.length; };
    const onClickk = (e) => { e.preventDefault(); form.resetFields(); setIsModalOpen(true); }
    const addPantalla = async (pantallaName) => {
        message.success(`Pantalla añadida: ${pantallaName}`);
        if (tipo === "mis_pantallas") {
            const response = await requestPost(
                'https://terminal.veltisrating.com:8888/api/addListaPantalla',
                { nombre_cartera: pantallaName }
            )
        } else if (tipo === "mis_vistas") {
            const response = await requestPost(
                'https://terminal.veltisrating.com:8888/api/addListaVista',
                { nombre_cartera: pantallaName }
            )
        }        
        updateData(true);
        setestadoOpen(true);
    }

    const updateData = async (open) => {
        const retorno = [{ label: "- Ninguna -", key: 0, }];
        if (open === true) {
            if (tipo === "mis_pantallas") {
                const response = await RequestGet('https://terminal.veltisrating.com:8888/api/getListaPantallas');
                if (response.data.length > 0) {
                    for (const cartera of response.data) {
                        retorno.push(
                            { label: cartera.nombre_pantalla, key: parseInt(cartera.id_pantalla), },
                        )
                    }
                }
            } else if (tipo === "veltis_pantallas") {
                const response = await RequestGet('https://terminal.veltisrating.com:8888/api/getListaPantallasVeltis');
                if (response.data.length > 0) {
                    for (const cartera of response.data) {
                        retorno.push(
                            { label: cartera.nombre_pantalla, key: parseInt(cartera.id_pantalla), },
                        )
                    }
                }
            } else if (tipo === "veltis_vistas") {
                const response = await RequestGet('https://terminal.veltisrating.com:8888/api/getListaVistasVeltis');
                if (response.data.length > 0) {
                    for (const cartera of response.data) {
                        retorno.push(
                            { label: cartera.nombre_vista, key: parseInt(cartera.id_vista), grid: JSON.parse(cartera.datos_vista), },
                        )
                    }
                }
            } else if (tipo === "mis_vistas") {
                const response = await RequestGet('https://terminal.veltisrating.com:8888/api/getListaVistas');
                if (response.data.length > 0) {
                    for (const cartera of response.data) {
                        retorno.push(
                            { label: cartera.nombre_vista, key: parseInt(cartera.id_vista), grid: JSON.parse(cartera.datos_vista), },
                        )
                    }
                }
            }
            setItems(retorno);
        }
        setestadoOpen(!estadoOpen);
    }




    return (
        <>
            <Modal title="Crear Nueva Pantalla" open={isModalOpen} onOk={(handleOk)} onCancel={handleCancel} icon={<ExclamationCircleFilled />}>
                <Form
                    form={form}
                    name="wrap"
                    labelCol={{
                        flex: '150px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    style={{
                        maxWidth: '100%',
                    }}
                    onFieldsChange={(a) => setvalueInput(a[0].value)}
                >
                    <Form.Item
                        label="Nombre pantalla"
                        name="username"
                        initialValue=""
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Dropdown
                menu={{
                    items,
                    //selectable: true,
                    onClick
                }}
                open={estadoOpen}
                placement="bottomLeft"
                onOpenChange={updateData}

                trigger={['click']}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        {title}
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>

            {canAdd ? <>&nbsp;<span onClick={onClickk}> <PlusSquareOutlined /> </span></> : ''}
        </>
    )
};
export default Drop;