import React from 'react'
import { Divider, List, Button } from 'antd';
function Wallet({ items, setCarteras }) {
    // funcion que altera el estado de los objetos activando el checkbox y alterando el atributo checked a true para poder manipularlo con las diversas funciones
    const onChange = function (e) {
        const carterastmp = items.map(objeto => ({ ...objeto }));;
        const index = carterastmp.findIndex((objeto) => objeto.id === e);
        const checkedd = carterastmp[index].checked;
        carterastmp.forEach(objeto => objeto.checked = false);
        if( checkedd ) carterastmp[index].checked = false;
        else carterastmp[index].checked = true;
        setCarteras(carterastmp);
    };

    return (
        <>
            <Divider orientation="left">Carteras Disponibles</Divider>
            <List
                bordered
                style={items.length > 3 ? { overflow: 'auto', height: '170px' } : {}}
                dataSource={items}
                size={'small'}
                renderItem={(item) => (
                    <List.Item
                        key={item.id}
                        data-id={item.id}
                        onClick={() => { onChange(item.id) }}
                    >
                        <Button type='text' size={'small'} block style={item.checked === true ? { backgroundColor: '#52c41a', fontWeight: 'bold' } : {}}>{item.name} </Button>
                    </List.Item>
                )}
            />
        </>
    )
}
export default Wallet