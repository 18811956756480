import React, { useState } from 'react'
import Graf from '../../../components/Grafica/Grafica'
import Mapa from '../../../components/Mapa/Mapa'
import Grid from '../../../components/Grid/Grid'

const BodyInf = ({ varestado, gridSize, setACtivoSeletected, filtros, setFiltros, carteras, setCarteras, usestateListaFiltros, setoverSelectedItem, overSelectedItem, activosInGrid, setactivosInGrid }) => {
   
    const [leyendaElegida, setLeyendaElegida] = useState({
        id: 0, name: 'Rating', activated: true, data: [
          { id: 1, score: 100, name: 'AAA', color: '1c4a16' },
          { id: 2, score: 95, name: 'AA+', color: '34641c' },
          { id: 3, score: 90, name: 'AA', color: '4c7f22' },
          { id: 4, score: 85, name: 'AA-', color: '659928' },
          { id: 5, score: 80, name: 'A+', color: '84a720' },
          { id: 6, score: 75, name: 'A', color: 'a4b617' },
          { id: 7, score: 70, name: 'A-', color: 'c3c50f' },
          { id: 8, score: 65, name: 'BBB+', color: 'e3d407' },
          { id: 9, score: 60, name: 'BBB', color: 'ffdf00' },
          { id: 10, score: 55, name: 'BBB-', color: 'fec400' },
          { id: 11, score: 50, name: 'BB+', color: 'fda900' },
          { id: 12, score: 45, name: 'BB', color: 'fb8e00' },
          { id: 13, score: 40, name: 'BB-', color: 'fa7300' },
          { id: 14, score: 35, name: 'B+', color: 'f85900' },
          { id: 15, score: 30, name: 'B', color: 'f73e00' },
          { id: 16, score: 25, name: 'B-', color: 'f52300' },
          { id: 17, score: 20, name: 'CCC', color: 'f40800' },
          { id: 18, score: 15, name: 'CC', color: 'c80000' },
          { id: 19, score: 10, name: 'C', color: '880000' },
          { id: 20, score: 5, name: 'D', color: '490000' },
        ]
      });
    return (
        <>
        <div data-v-4cbe18f4 className={varestado === 1 ? "main-section" : "main-section oculta"} style={{ overflow: 'hidden', width: 'calc(100% - 10px)' }} >
                <Grid setACtivoSeletected={setACtivoSeletected} setactivosInGrid={setactivosInGrid} filtros={filtros} setFiltros={setFiltros} carteras={carteras} usestateListaFiltros={usestateListaFiltros} leyendaElegida={leyendaElegida}/>
            </div>
            <div data-v-4cbe18f4 className={varestado === 2 ? "main-section" : "main-section oculta"} style={{ overflow: 'hidden', width: 'calc(100% - 10px)' }} >
                <Graf />
            </div>
            <div data-v-4cbe18f4 className={varestado === 3 ? "main-section" : "main-section oculta"} style={{ overflow: 'hidden', width: 'calc(100% - 10px)' }} >
                <Mapa mapClicked={varestado} gridSize={gridSize} activosInGrid={activosInGrid} setACtivoSeletected={setACtivoSeletected} setoverSelectedItem={setoverSelectedItem} overSelectedItem={overSelectedItem} leyendaElegida={leyendaElegida} setLeyendaElegida={setLeyendaElegida}/>
            </div>
        </>
    )
}

export default BodyInf