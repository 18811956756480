import { AutoComplete, Input } from "antd";
import { useState } from "react";
const Buscador = ({ filtros, setFiltros }) => {
    const filtrado = [...filtros];
    const searchResult = (query) => {
        const retorno = [];
        filtrado.map((item) => {
            return item.elementos.forEach((element) => {
                if (Object.values(element).find((valor) => typeof valor === "string" && valor.toLowerCase().includes(query.toLowerCase()))) {
                    retorno.push({
                        value: element.id,
                        label: (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                                key={element.id}
                            >
                                <span>
                                    {/* aspecto de los resultados de la busqueda */}
                                    Categoria "{item.name}" <h3>Indicador: {element.name}</h3>
                                </span>
                            </div>
                        ),
                    });
                }
            });
        });
        return retorno;
    };
    const [options, setOptions] = useState([]);
    const [d_value, setd_value] = useState();
    const handleSearch = (value) => {
        setOptions(value ? searchResult(value) : []);
    };
    const onSelect = (value) => {
        // funcion que hace que al seleccionar un resultado de busqueda este cambie su estado activated
        const filtradotmp = [...filtros];
        for (let i = 0; i < filtradotmp.length; i++) {
            const index = filtradotmp[i].elementos.findIndex((objeto) => objeto.id === Number(value));
            if (filtradotmp[i].elementos[index] !== undefined) {
                filtradotmp[i].elementos[index].activated = !filtradotmp[i].elementos[index].activated;
            }
        }
        setFiltros(filtradotmp);
        setd_value('');
    };
    return (
        <AutoComplete
            popupMatchSelectWidth={252}
            style={{
                width: '100%',
            }}
            options={options}
            onSelect={onSelect}
            onSearch={handleSearch}
            value={d_value}
        >
            <Input.Search size="large" placeholder="Introduzca su busqueda" enterButton />
        </AutoComplete>
    );
};
export default Buscador;
