import React from 'react';
import './CeeBilbao.css';

const CeeBilbao = ({ dataa }) => {
    return (
        <div className='CEE'>
            <div className='titulo' >
                <h2>CERTIFICADO DE EFICIENCIA ENERGÉTICA DE EDIFICIOS</h2>
            </div>
            <h3 className='subtitulo'>IDENTIFICACIÓN DEL EDIFICIO O DE LA PARTE QUE SE CERTIFICA:</h3>
            <table className='tabla'>
                <tr>
                    <th>Nombre del edificio</th>
                    <td>VIVIENDA UNIFAMILIAR</td>
                </tr>
                <tr>
                    <th>Dirección</th>
                    <td>CTRA. SAN RAFAEL 68 (70C s/catastro)</td>
                </tr>
                <tr>
                    <th>Municipio</th>
                    <td>REVENGA-SEGOVIA</td>
                    <th>Código Postal</th>
                    <td>40195</td>
                </tr>
                <tr>
                    <th>Provincia</th>
                    <td>Segovia</td>
                    <th>Comunidad Autónoma</th>
                    <td>Castilla y León</td>
                </tr>
                <tr>
                    <th>Zona climática</th>
                    <td>D2</td>
                    <th>Año construcción</th>
                    <td>2007</td>
                </tr>
                <tr>
                    <th>Normativa vigente (construccion / rehabilitación)</th>
                    <td>C.T.E.</td>
                </tr>
                <tr>
                    <th>Referencia/s catastral/es</th>
                    <td>7350505VL0274N0001OX</td>
                </tr>
            </table>

            <h3 className='subtitulo'>Tipo de edificio o parte del edificio que se certifica:</h3>
            <table className='tabla2'>
                <tr>
                    <td s><ul><li type='circle'>Edificio de nueva construcción</li></ul></td>
                    <td><ul><li type='disc'>Edificio Existente</li></ul></td>
                </tr>
                <tr>
                    <th></th>
                </tr>
                <tr>
                    <td>
                        <ul>
                            <li type='circle'>Vivienda</li>
                            <li type='circle'>Unifamiliar</li>
                            <ul>
                                <li type='circle'>Bloque</li>
                                <li type='circle'>Bloque completo</li>
                                <li type='circle'>Vivienda individual</li>
                            </ul>
                        </ul>
                    </td>
                    <td>
                        <ul><ul>
                            <li type='circle'>Terciario</li>
                            <li type='circle'>Edificio completo</li>
                            <li type='circle'>Local</li>
                        </ul></ul>
                    </td>
                </tr>
            </table>
            <h3 className='subtitulo'>DATOS DEL TÉCNICO CERTIFICADOR:</h3>
            <table className='tabla'>
                <tr>
                    <th>Nombre y Apellidos</th>
                    <td>MIGUEL RODRIGUEZ-VIÑA BOTELLA</td>
                    <th>NIF(NIE)</th>
                    <td>53393723M</td>
                </tr>
                <tr>
                    <th>Razón social</th>
                    <td>OCT CONTROLIA SAU</td>
                    <th>NIF</th>
                    <td>A82838350</td>
                </tr>
                <tr>
                    <th>Domicilio</th>
                    <td>CALLE FRANCISCO CAMPOS, 31</td>
                </tr>
                <tr>
                    <th>Municipio</th>
                    <td>MADRID</td>
                    <th>Comunidad Autónoma</th>
                    <td>Comunidad de Madrid</td>
                </tr>
                <tr>
                    <th>e-mail:</th>
                    <td>ADMINISTRACION@OCTCONTROLIA.COM</td>
                    <th>Teléfono</th>
                    <td>915158080</td>
                </tr>
                <tr>
                    <th>Titulación habilitante según normativa vigente</th>
                    <td>INGENIERO DE CAMINOS CANALES Y PUERTOS</td>
                </tr>
                <tr>
                    <th>Procedimiento reconocido de calificación energética utilizado y versión:</th>
                    <td>CEXv2.3</td>
                </tr>
            </table>
            <h3 className='subtitulo'>CALIFICACIÓN ENERGÉTICA OBTENIDA:</h3>
            <table className='calificacion'>
                <tr>
                    <th>CONSUMO DE ENERGÍA PRIMARIA NO RENOVABLE [kWh/m² año]</th>
                    <th>EMISIONES DE DIÓXIDO DE CARBONO [kgCO2/ m² año]</th>
                </tr>
                <tr>
                    <td>
                        <td style={{border: 0}}>
                            <div className='calificacion-A'><p>&lt; 51.6</p>A</div>
                            <div className='calificacion-B'><p>51.6-83.6</p>B</div>
                            <div className='calificacion-C'><p>83.6-129.6</p>C</div>
                            <div className='calificacion-D'><p>129.6-199.3</p>D</div>
                            <div className='calificacion-E'><p>199.3-357.4</p>E</div>
                            <div className='calificacion-F'><p>357.4-461.1</p>F</div>
                            <div className='calificacion-G'><p>&gt; 461.1</p>G</div>
                        </td>
                        <td style={{border: 0}}>
                            <div className='calificacion-2-A'>3.1 A</div>
                            <div className='calificacion-2-B' style={{ zIndex: -1 }}>B</div>
                            <div className='calificacion-2-C' style={{ zIndex: -1 }}>C</div>
                            <div className='calificacion-2-D' style={{ zIndex: -1 }}>D</div>
                            <div className='calificacion-2-E' style={{ zIndex: -1 }}>E</div>
                            <div className='calificacion-2-F' style={{ zIndex: -1 }}>F</div>
                            <div className='calificacion-2-G' style={{ zIndex: -1 }}>G</div>
                        </td>
                    </td>
                    <td>
                        <td style={{border: 0}}>
                            <div className='calificacion-A'><p>&lt; 11.6</p>A</div>
                            <div className='calificacion-B'><p>11.6-18.8</p>B</div>
                            <div className='calificacion-C'><p>18.8-29.2</p>C</div>
                            <div className='calificacion-D'><p>29.2-44.8</p>D</div>
                            <div className='calificacion-E'><p>44.8-79.2</p>E</div>
                            <div className='calificacion-F'><p>79.2-103.8</p>F</div>
                            <div className='calificacion-G'><p>&gt; 103.8</p>G</div>
                        </td>
                        <td style={{border: 0}}>
                            <div className='calificacion-2-A'>0.5 A</div>
                            <div className='calificacion-2-B' style={{ zIndex: -1 }}>B</div>
                            <div className='calificacion-2-C' style={{ zIndex: -1 }}>C</div>
                            <div className='calificacion-2-D' style={{ zIndex: -1 }}>D</div>
                            <div className='calificacion-2-E' style={{ zIndex: -1 }}>E</div>
                            <div className='calificacion-2-F' style={{ zIndex: -1 }}>F</div>
                            <div className='calificacion-2-G' style={{ zIndex: -1 }}>G</div>
                        </td>
                    </td>
                </tr>
            </table>

            <p style={{ marginTop: 15, marginBottom: 10 }}>El técnico abajo firmante declara responsablemente que ha realizado la certificación energética del edificio o de la parte que se certifica de acuerdo con el procedimiento establecido por la normativa vigente y que son ciertos los datos que figuran en el presente documento, y sus anexos:</p>
            <p>Fecha: 12/05/2023</p>
            <p style={{ marginTop: 40, textAlign: 'center', marginBottom: 10 }}>Firma del técnico certificador</p>
            <p>Anexo I. Descripción de las características energéticas del edificio.</p>
            <p>Anexo II. Calificación energética del edificio.</p>
            <p>Anexo III. Recomendaciones para la mejora de la eficiencia energética.</p>
            <p>Anexo IV. Pruebas, comprobaciones e inspecciones realizadas por el técnico certificador.</p>
            <p style={{ marginTop: 10, marginBottom: 40 }}>Registro del Órgano Territorial Competente:</p>
            <p>Fecha 20/06/2023</p>
            <p>Ref. Catastral 7350505VL0274N0001OX</p>
            <br></br>
            <br></br>
            <br></br>
        </div>
    )
}
export default CeeBilbao