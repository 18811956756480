import React, { useState } from 'react'
import { AutoComplete, Select } from 'antd';
import { RequestGet, requestPost } from '../../../hooks/Request/Request';

const options = [];
for (let i = 10; i < 36; i++) {
    options.push({
        value: i.toString(36) + i,
        label: i.toString(36) + i,
    });
}


const FilterSubCat = ({ indicesubcategoria, subcatindicador, indicecat, usestateListaFiltros, setusestateListaFiltros, subcategoria }) => {
    const getDefaultValue = (datos) => {
        let retorno = "-"
        if (datos.filter(opt => opt.selected).length > 0) {
            retorno = datos.filter(opt => opt.selected)[0].id;
        }
        return retorno;
    }

    const defaultOrClick = (subindicador, indicesubindicador, tipo) => {
        if( tipo !== "buscador-selector" )  {
            if (subindicador.indicadorfiltros === undefined) {
                const clonn = [...usestateListaFiltros];
                const idd = clonn[indicecat].subcategorias[indicesubcategoria].subcatindicador[indicesubindicador].indicadorfiltros.findIndex((objeto) => objeto.id === subindicador);
                for (let index = 0; index < clonn[indicecat].subcategorias[indicesubcategoria].subcatindicador[indicesubindicador].indicadorfiltros.length; index++) {
                    if (clonn[indicecat].subcategorias[indicesubcategoria].subcatindicador[indicesubindicador].indicadorfiltros[index].tipo === tipo) {
                        clonn[indicecat].subcategorias[indicesubcategoria].subcatindicador[indicesubindicador].indicadorfiltros[index].selected = false;
                    }
                }
                clonn[indicecat].subcategorias[indicesubcategoria].subcatindicador[indicesubindicador].indicadorfiltros[idd].selected = true;
                setusestateListaFiltros(clonn);
                localStorage.setItem("veltis_filtros", JSON.stringify(clonn));
                return subindicador;
            }
            else {
                const retorno = getDefaultValue(subindicador.indicadorfiltros.filter(opt => opt.tipo === tipo));
                return retorno;
            }
        } else {
            const clonn = [...usestateListaFiltros];
            clonn[indicecat].subcategorias[indicesubcategoria].subcatindicador[indicesubindicador].indicadorfiltros = [];
            clonn[indicecat].subcategorias[indicesubcategoria].subcatindicador[indicesubindicador].indicadorfiltros.push(
                { id: 0, nombre: "municipio", tipo: "buscador-selector", value: subindicador, selected: true },
            )
            setusestateListaFiltros(clonn);
        }
    }

    const [options2, setOptions2] = useState([]);

    const handleSearch = (value) => {
        const laca = async () => {
            const response = await RequestGet(
                'https://terminal.veltisrating.com:8888/api/searchMunByName/' + value.toLowerCase(),
            )
            const retorno = [];
            if (response.data.length > 0) {
                for (const iterator of response.data) {
                    retorno.push(
                        {
                            value: `${iterator.id_mun} - ${iterator.name_mun}`,
                            label: (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                    >
                                    <span>Población <span style={{ color: 'var(--color-primary-color)' }}>{iterator.name_mun}</span></span>
                                </div>
                            ),
                            datos: iterator,
                        }
                    )
                }
            }
            setOptions2(retorno);
        }
        laca();
    };

    async function seleccionarActivo(e) {
        const result = await requestPost(
            'https://terminal.veltisrating.com:8888/api/activo',
            { refcat: e.full_refcat }
        )
        const retorno = result.data;
        return retorno;
    }

    return (
        <div id="filter-card-2908" className="filter-card" style={{ marginBottom: '4px' }}>
            <fieldset className="aio-group-fieldset">
                <legend>
                    <span className="inline aio-group-title t-default bold aio-item-link" style={{ fontSize: '11px', lineHeight: '11px' }}> {subcategoria.subcatname} </span>
                </legend>
            </fieldset>
            {
                subcatindicador.map((subindicador, indicesubindicador) => {
                    return (
                        <div key={indicesubindicador} id="filter-card-3548" className="filter-card" style={{ marginBottom: '4px' }}>
                            <div store="" style={{ height: '20px', display: 'inline-block', width: '100%' }}>
                                <div className="aio-item-title number-range-filter-title aio-item-link">
                                    <span className="inline aio-filter-title" field-name="roa" display-name="ROA %" style={{ fontSize: '11px', lineHeight: '11px' }}> {subindicador.indicadorname} </span>
                                </div>
                                {
                                    subindicador.indicadortipo === "min_max" ?
                                        <>
                                            <div className="aio-item-content number-range-filter-content" style={{ paddingLeft: 2 }}>
                                                <Select
                                                    popupMatchSelectWidth={false}
                                                    size={'small'}
                                                    defaultValue={defaultOrClick(subindicador, indicesubindicador, 'min')}
                                                    value={defaultOrClick(subindicador, indicesubindicador, 'min')}
                                                    showArrow={false}
                                                    placeholder={"-"}
                                                    onChange={(e) => { defaultOrClick(e, indicesubindicador, 'min') }}
                                                    style={{
                                                        width: 48,
                                                        border: '0px',
                                                        fontSize: '5px',
                                                    }}
                                                    removeIcon={true}
                                                    options={
                                                        subindicador.indicadorfiltros.filter(opt => opt.tipo === "min").map((indicadorfiltro) => {
                                                            return {
                                                                value: indicadorfiltro.id,
                                                                label: indicadorfiltro.nombre,
                                                            }
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="aio-item-content number-range-filter-to"> a </div>
                                            <div className="aio-item-content number-range-filter-content">
                                                <Select
                                                    popupMatchSelectWidth={false}
                                                    size={'small'}
                                                    defaultValue={defaultOrClick(subindicador, indicesubindicador, 'max')}
                                                    value={defaultOrClick(subindicador, indicesubindicador, 'max')}
                                                    showArrow={false}
                                                    placeholder={"-"}
                                                    onChange={(e) => { defaultOrClick(e, indicesubindicador, 'max') }}
                                                    //onChange={handleChange} 
                                                    style={{
                                                        width: 48,
                                                        border: '0px',
                                                    }}
                                                    removeIcon={true}
                                                    //bordered={false}
                                                    options={subindicador.indicadorfiltros.filter(opt => opt.tipo === "max").map((indicadorfiltro) => {
                                                        return {
                                                            value: indicadorfiltro.id,
                                                            label: indicadorfiltro.nombre,
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </>
                                        : subindicador.indicadortipo === "selector" ?
                                        <>
                                            <div className="aio-item-content date-select-content" style={{ paddingLeft: 2 }}>
                                                <Select
                                                    popupMatchSelectWidth={false}
                                                    size={'small'}
                                                    defaultValue={defaultOrClick(subindicador, indicesubindicador, 'selector')}
                                                    value={defaultOrClick(subindicador, indicesubindicador, 'selector')}
                                                    showArrow={false}
                                                    placeholder={"-"}
                                                    onChange={(e) => { defaultOrClick(e, indicesubindicador, 'selector') }}
                                                    style={{
                                                        width: 120,
                                                        border: '0px',
                                                    }}
                                                    removeIcon={true}
                                                    //bordered={false}
                                                    options={subindicador.indicadorfiltros.map((indicadorfiltro) => {
                                                        return {
                                                            value: indicadorfiltro.id,
                                                            label: indicadorfiltro.nombre,
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <AutoComplete
                                                options={options2}
                                                style={{
                                                    width: 150,
                                                }}
                                                dropdownStyle={{
                                                    minWidth: 300
                                                }}
                                                placeholder="Introduzca el municipio"
                                                onSearch={handleSearch}
                                                onSelect={(e) => { defaultOrClick(e, indicesubindicador, subindicador.indicadortipo) }}
                                                onChange={seleccionarActivo}
                                            />
                                        </>
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default FilterSubCat