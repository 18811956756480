import { React, useEffect, useState, useRef } from 'react'
import { Col, Divider, Radio, Result, Row } from 'antd';
import Highcharts from 'highcharts';
import { UpCircleOutlined, RightCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import './IndicadoresClimaticos.css'
import { RequestGet } from '../../hooks/Request/Request';
import Sello from '../../view/pages/SelloVeltis.png'
import GraficasClimaticas from './GraficasClimaticas';
import { veltisTooltip } from '../../hooks/Tooltip/Tooltip';
import { isElementOfType } from 'react-dom/test-utils';
import { HighchartsReact } from 'highcharts-react-official';

function getRandomInt(max) { return Math.floor(Math.random() * max); }

const indiceData = [
    {
        id: 1, nombre: 'Riesgo - Inundación Costera', internal: "coas", sufix: '%', req: 'buildings-coastal-floods', req_sec: 'census-coastal-floods', historico: 81073, escenarios: 'RCP4.5', riesgo: 'medio', futuroCercano: getRandomInt(100), futuroMedio: getRandomInt(100), futuroLejano: getRandomInt(100)
    },
    {
        id: 2, nombre: 'Riesgo - Inundación Fluvial', internal: "flu", sufix: '%', req: 'buildings-fluvial-floods', req_sec: 'census-fluvial-floods', historico: 81073, escenarios: 'RCP4.5', riesgo: 'medio', futuroCercano: getRandomInt(100), futuroMedio: getRandomInt(100), futuroLejano: getRandomInt(100)
    },
    {
        id: 3, nombre: 'Riesgo - Demanda Calefacción', internal: "heat", sufix: '/kWh', req: 'buildings-heatings', req_sec: 'census-heatings', historico: 81073, escenarios: 'RCP4.5', riesgo: 'medio', futuroCercano: getRandomInt(100), futuroMedio: getRandomInt(100), futuroLejano: getRandomInt(100)
    },
    {
        id: 4, nombre: 'Riesgo - Demanda Refrigeración', internal: "cool", sufix: '/kWh', req: 'buildings-coolings', req_sec: 'census-coolings', historico: 81073, escenarios: 'RCP4.5', riesgo: 'medio', futuroCercano: getRandomInt(100), futuroMedio: getRandomInt(100), futuroLejano: getRandomInt(100)
    }
];

let maxRisk = 0;
let maxRisk1 = 0;
let maxRisk2 = 0;
let maxRisk_sc = 0;
let maxRisk1_sc = 0;
let maxRisk2_sc = 0;

const IndicadoresClimaticos = ({ dataa }) => {

    const [datooo, setdatooo] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState('tabla');
    const [datos, setDatos] = useState([]);
    const [options, setoptions] = useState({});
    const [options1, setoptions1] = useState({});
    const [options2, setoptions2] = useState({});
    const [options_sc, setoptions_sc] = useState({});
    const [options1_sc, setoptions1_sc] = useState({});
    const [options2_sc, setoptions2_sc] = useState({});
    const chartRef = useRef(null);
    const chartRef_sc = useRef(null);



    useEffect(() => { setLoading(true); }, [])

    useEffect(() => {

        const handleClickTest = async () => {

            const responseq = await RequestGet(
                'https://terminal.veltisrating.com:8888/api/getClimaticData/' + dataa.data[0].full_refcat,
            )

            if (responseq.totalRows === 0) {
                setDatos([]);
            } else {
                const arrayDatos = [/*"agg", "coas", "cool", "flu", "heat"*/];
                arrayDatos["coas"] = {
                    "base": {
                        "bu": {
                            "impacto": 0,
                            "riesgo": 0
                        },
                        "cusec": {
                            "impacto": 0,
                            "riesgo": 0
                        }
                    },
                    "rcp45": {
                        "short": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "medium": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "long": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        }
                    },
                    "rcp85": {
                        "short": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "medium": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "long": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        }
                    }
                }
                arrayDatos["cool"] = {
                    "base": {
                        "bu": {
                            "impacto": 0,
                            "riesgo": 0
                        },
                        "cusec": {
                            "impacto": 0,
                            "riesgo": 0
                        }
                    },
                    "rcp45": {
                        "short": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "medium": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "long": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        }
                    },
                    "rcp85": {
                        "short": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "medium": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "long": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        }
                    }
                }
                arrayDatos["flu"] = {
                    "base": {
                        "bu": {
                            "impacto": 0,
                            "riesgo": 0
                        },
                        "cusec": {
                            "impacto": 0,
                            "riesgo": 0
                        }
                    },
                    "rcp45": {
                        "short": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "medium": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "long": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        }
                    },
                    "rcp85": {
                        "short": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "medium": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "long": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        }
                    }
                }
                arrayDatos["heat"] = {
                    "base": {
                        "bu": {
                            "impacto": 0,
                            "riesgo": 0
                        },
                        "cusec": {
                            "impacto": 0,
                            "riesgo": 0
                        }
                    },
                    "rcp45": {
                        "short": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "medium": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "long": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        }
                    },
                    "rcp85": {
                        "short": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "medium": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        },
                        "long": {
                            "bu": {
                                "impacto": 0,
                                "riesgo": 0
                            },
                            "cusec": {
                                "impacto": 0,
                                "riesgo": 0
                            }
                        }
                    }
                }

                const base = responseq.data.filter(person => person.scenario === 'base');
                const rcp45 = responseq.data.filter(person => person.scenario === 'rcp45');
                const rcp85 = responseq.data.filter(person => person.scenario === 'rcp85');
                if (base.length > 0) {
                    arrayDatos["coas"]["base"] = procesarClimatico("coas", base, rcp45, rcp85, 'base', 'base');
                    arrayDatos["cool"]["base"] = procesarClimatico("cool", base, rcp45, rcp85, 'base', 'base');
                    arrayDatos["flu"]["base"] = procesarClimatico("flu", base, rcp45, rcp85, 'base', 'base');
                    arrayDatos["heat"]["base"] = procesarClimatico("heat", base, rcp45, rcp85, 'base', 'base');
                }
                if (rcp45.length > 0) {
                    arrayDatos["coas"]["rcp45"]["short"]["bu"]["impacto"] = 0;
                    arrayDatos["coas"]["rcp45"]["short"]["bu"]["riesgo"] = 0;
                    arrayDatos["coas"]["rcp45"]["short"]["cusec"]["impacto"] = 0;
                    arrayDatos["coas"]["rcp45"]["short"]["cusec"]["riesgo"] = 0;

                    arrayDatos["coas"]["rcp45"]["short"] = procesarClimatico("coas", base, rcp45, rcp85, 'rcp45', 'short');
                    arrayDatos["coas"]["rcp45"]["medium"] = procesarClimatico("coas", base, rcp45, rcp85, 'rcp45', 'medium');
                    arrayDatos["coas"]["rcp45"]["long"] = procesarClimatico("coas", base, rcp45, rcp85, 'rcp45', 'long');

                    arrayDatos["cool"]["rcp45"]["short"] = procesarClimatico("cool", base, rcp45, rcp85, 'rcp45', 'short');
                    arrayDatos["cool"]["rcp45"]["medium"] = procesarClimatico("cool", base, rcp45, rcp85, 'rcp45', 'medium');
                    arrayDatos["cool"]["rcp45"]["long"] = procesarClimatico("cool", base, rcp45, rcp85, 'rcp45', 'long');

                    arrayDatos["flu"]["rcp45"]["short"] = procesarClimatico("flu", base, rcp45, rcp85, 'rcp45', 'short');
                    arrayDatos["flu"]["rcp45"]["medium"] = procesarClimatico("flu", base, rcp45, rcp85, 'rcp45', 'medium');
                    arrayDatos["flu"]["rcp45"]["long"] = procesarClimatico("flu", base, rcp45, rcp85, 'rcp45', 'long');

                    arrayDatos["heat"]["rcp45"]["short"] = procesarClimatico("heat", base, rcp45, rcp85, 'rcp45', 'short');
                    arrayDatos["heat"]["rcp45"]["medium"] = procesarClimatico("heat", base, rcp45, rcp85, 'rcp45', 'medium');
                    arrayDatos["heat"]["rcp45"]["long"] = procesarClimatico("heat", base, rcp45, rcp85, 'rcp45', 'long');
                }
                if (rcp85.length > 0) {
                    //short
                    arrayDatos["coas"]["rcp85"]["short"]["bu"]["impacto"] = 0;
                    arrayDatos["coas"]["rcp85"]["short"]["bu"]["riesgo"] = 0;
                    arrayDatos["coas"]["rcp85"]["short"]["cusec"]["impacto"] = 0;
                    arrayDatos["coas"]["rcp85"]["short"]["cusec"]["riesgo"] = 0;

                    arrayDatos["coas"]["rcp85"]["short"] = procesarClimatico("coas", base, rcp45, rcp85, 'rcp85', 'short');
                    arrayDatos["coas"]["rcp85"]["medium"] = procesarClimatico("coas", base, rcp45, rcp85, 'rcp85', 'medium');
                    arrayDatos["coas"]["rcp85"]["long"] = procesarClimatico("coas", base, rcp45, rcp85, 'rcp85', 'long');

                    arrayDatos["cool"]["rcp85"]["short"] = procesarClimatico("cool", base, rcp45, rcp85, 'rcp85', 'short');
                    arrayDatos["cool"]["rcp85"]["medium"] = procesarClimatico("cool", base, rcp45, rcp85, 'rcp85', 'medium');
                    arrayDatos["cool"]["rcp85"]["long"] = procesarClimatico("cool", base, rcp45, rcp85, 'rcp85', 'long');

                    arrayDatos["flu"]["rcp85"]["short"] = procesarClimatico("flu", base, rcp45, rcp85, 'rcp85', 'short');
                    arrayDatos["flu"]["rcp85"]["medium"] = procesarClimatico("flu", base, rcp45, rcp85, 'rcp85', 'medium');
                    arrayDatos["flu"]["rcp85"]["long"] = procesarClimatico("flu", base, rcp45, rcp85, 'rcp85', 'long');

                    arrayDatos["heat"]["rcp85"]["short"] = procesarClimatico("heat", base, rcp45, rcp85, 'rcp85', 'short');
                    arrayDatos["heat"]["rcp85"]["medium"] = procesarClimatico("heat", base, rcp45, rcp85, 'rcp85', 'medium');
                    arrayDatos["heat"]["rcp85"]["long"] = procesarClimatico("heat", base, rcp45, rcp85, 'rcp85', 'long');
                }
                setDatos(arrayDatos);
            }



            let value = 0;
            let value1 = 0;
            let value2 = 0;
            let value_sc = 0;
            let value1_sc = 0;
            let value2_sc = 0;
            switch (maxRisk) {
                case 5:
                    value = 180;
                    break;
                case 4:
                    value = 140;
                    break;
                case 3:
                    value = 100;
                    break;
                case 2:
                    value = 60;
                    break;
                case 1:
                    value = 20;
                    break;
                default:
                    value = 0;
                    break;
            }
            switch (maxRisk1) {
                case 5:
                    value1 = 180;
                    break;
                case 4:
                    value1 = 140;
                    break;
                case 3:
                    value1 = 100;
                    break;
                case 2:
                    value1 = 60;
                    break;
                case 1:
                    value1 = 20;
                    break;
                default:
                    value1 = 0;
                    break;
            }
            switch (maxRisk2) {
                case 5:
                    value2 = 180;
                    break;
                case 4:
                    value2 = 140;
                    break;
                case 3:
                    value2 = 100;
                    break;
                case 2:
                    value2 = 60;
                    break;
                case 1:
                    value2 = 20;
                    break;
                default:
                    value2 = 0;
                    break;
            }

            switch (maxRisk_sc) {
                case 5:
                    value_sc = 180;
                    break;
                case 4:
                    value_sc = 140;
                    break;
                case 3:
                    value_sc = 100;
                    break;
                case 2:
                    value_sc = 60;
                    break;
                case 1:
                    value_sc = 20;
                    break;
                default:
                    value_sc = 0;
                    break;
            }
            switch (maxRisk1_sc) {
                case 5:
                    value1_sc = 180;
                    break;
                case 4:
                    value1_sc = 140;
                    break;
                case 3:
                    value1_sc = 100;
                    break;
                case 2:
                    value1_sc = 60;
                    break;
                case 1:
                    value1_sc = 20;
                    break;
                default:
                    value1_sc = 0;
                    break;
            }
            switch (maxRisk2_sc) {
                case 5:
                    value2_sc = 180;
                    break;
                case 4:
                    value2_sc = 140;
                    break;
                case 3:
                    value2_sc = 100;
                    break;
                case 2:
                    value2_sc = 60;
                    break;
                case 1:
                    value2_sc = 20;
                    break;
                default:
                    value2_sc = 0;
                    break;
            }

            if (chartRef !== null && chartRef.current != null) {
                const opciones = {
                    chart: {
                        type: 'gauge',
                        plotBackgroundColor: null,
                        plotBackgroundImage: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                        //spacingTop: -50
                        //marginTop: -20
                        //margin: -10,
                    },
                    accessibility: {
                        enabled: true
                    },

                    credits: {
                        enabled: false
                    },
                    title: {
                        text: '',
                    },
                    pane: {
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '40%'],
                        background: [
                            {
                                backgroundColor: {
                                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                    stops: [
                                        [0, '#55BF3B'], // verde
                                        [0.25, '#66BB6A'], // verde claro
                                        [0.5, '#FFEB3B'], // amarillo
                                        [0.75, '#FF9800'], // naranja
                                        [1, '#FF5722'], // rojo
                                    ],
                                },
                                borderWidth: 0,
                                outerRadius: '0%',
                                innerRadius: '0%',
                            },
                        ],
                    },
                    plotOptions: {
                        series: {
                            //center: ['50%', '50%']
                        },
                    },
                    yAxis: {
                        min: 0,
                        max: 200,
                        minorTickInterval: 'auto',
                        minorTickWidth: 1,
                        minorTickLength: 10,
                        minorTickPosition: 'inside',
                        minorTickColor: '#666',
                        tickWidth: 1,
                        tickPosition: 'inside',
                        tickLength: 10,
                        tickColor: '#666',
                        labels: {
                            step: 1,
                            formatter: function () {
                                if (this.value === 0) return 'Muy Bajo';
                                if (this.value === 50) return 'Bajo';
                                if (this.value === 100) return 'Medio';
                                if (this.value === 150) return 'Alto';
                                if (this.value === 200) return 'Muy Alto';
                                return '';
                            },
                            y: -6, //posición de los indicadores medio, bajo, alto.
                            style: {
                                fontSize: '12px',
                                fontWeight: 100,
                                color: 'black'
                            },
                        },
                        plotBands: [
                            {
                                from: 0,
                                to: 40,
                                color: '#55BF3B', // verde
                                thickness: '30%',
                            },
                            {
                                from: 40,
                                to: 80,
                                color: '#66BB6A', // verde claro
                                thickness: '30%',
                            },
                            {
                                from: 80,
                                to: 120,
                                color: '#e1be23', // amarillo
                                thickness: '30%',
                            },
                            {
                                from: 120,
                                to: 160,
                                color: '#FF9800', // naranja
                                thickness: '30%',
                            },
                            {
                                from: 160,
                                to: 200,
                                color: '#FF5722', // rojo
                                thickness: '30%',
                            },
                        ],
                    },
                    series: [
                        {
                            name: '',
                            data: [value], //Este es el valor numérico que hay que modificar para cambiar la dirección de la flecha
                            dataLabels: {
                                enabled: false,
                            },
                        },
                    ],
                };
                const opciones1 = {
                    chart: {
                        type: 'gauge',
                        plotBackgroundColor: null,
                        plotBackgroundImage: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                    },
                    accessibility: {
                        enabled: true
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: '',
                    },
                    pane: {
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '40%'],
                        background: [
                            {
                                backgroundColor: {
                                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                    stops: [
                                        [0, '#55BF3B'], // verde
                                        [0.25, '#66BB6A'], // verde claro
                                        [0.5, '#FFEB3B'], // amarillo
                                        [0.75, '#FF9800'], // naranja
                                        [1, '#FF5722'], // rojo
                                    ],
                                },
                                borderWidth: 0,
                                outerRadius: '0%',
                                innerRadius: '0%',
                            },
                        ],
                    },
                    yAxis: {
                        min: 0,
                        max: 200,
                        minorTickInterval: 'auto',
                        minorTickWidth: 1,
                        minorTickLength: 10,
                        minorTickPosition: 'inside',
                        minorTickColor: '#666',
                        tickWidth: 1,
                        tickPosition: 'inside',
                        tickLength: 10,
                        tickColor: '#666',
                        labels: {
                            step: 1,
                            formatter: function () {
                                if (this.value === 0) return 'Muy Bajo';
                                if (this.value === 50) return 'Bajo';
                                if (this.value === 100) return 'Medio';
                                if (this.value === 150) return 'Alto';
                                if (this.value === 200) return 'Muy Alto';
                                return '';
                            },
                            y: -6, //posición de los indicadores medio, bajo, alto.
                            style: {
                                fontSize: '12px',
                                fontWeight: 100,
                                color: 'black'
                            },
                        },
                        plotBands: [
                            {
                                from: 0,
                                to: 40,
                                color: '#55BF3B', // verde
                                thickness: '30%',
                            },
                            {
                                from: 40,
                                to: 80,
                                color: '#66BB6A', // verde claro
                                thickness: '30%',
                            },
                            {
                                from: 80,
                                to: 120,
                                color: '#e1be23', // amarillo
                                thickness: '30%',
                            },
                            {
                                from: 120,
                                to: 160,
                                color: '#FF9800', // naranja
                                thickness: '30%',
                            },
                            {
                                from: 160,
                                to: 200,
                                color: '#FF5722', // rojo
                                thickness: '30%',
                            },
                        ],
                    },
                    series: [
                        {
                            name: '',
                            data: [value1], //Este es el valor numérico que hay que modificar para cambiar la dirección de la flecha
                            dataLabels: {
                                enabled: false,
                            },
                        },
                    ],
                };
                const opciones2 = {
                    chart: {
                        type: 'gauge',
                        plotBackgroundColor: null,
                        plotBackgroundImage: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                    },
                    accessibility: {
                        enabled: true
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: '',
                    },
                    pane: {
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '40%'],
                        background: [
                            {
                                backgroundColor: {
                                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                    stops: [
                                        [0, '#55BF3B'], // verde
                                        [0.25, '#66BB6A'], // verde claro
                                        [0.5, '#FFEB3B'], // amarillo
                                        [0.75, '#FF9800'], // naranja
                                        [1, '#FF5722'], // rojo
                                    ],
                                },
                                borderWidth: 0,
                                outerRadius: '0%',
                                innerRadius: '0%',
                            },
                        ],
                    },
                    yAxis: {
                        min: 0,
                        max: 200,
                        minorTickInterval: 'auto',
                        minorTickWidth: 1,
                        minorTickLength: 10,
                        minorTickPosition: 'inside',
                        minorTickColor: '#666',
                        tickWidth: 1,
                        tickPosition: 'inside',
                        tickLength: 10,
                        tickColor: '#666',
                        labels: {
                            step: 1,
                            formatter: function () {
                                if (this.value === 0) return 'Muy Bajo';
                                if (this.value === 50) return 'Bajo';
                                if (this.value === 100) return 'Medio';
                                if (this.value === 150) return 'Alto';
                                if (this.value === 200) return 'Muy Alto';
                                return '';
                            },
                            y: -6, //posición de los indicadores medio, bajo, alto.
                            style: {
                                fontSize: '12px',
                                fontWeight: 100,
                                color: 'black'
                            },
                        },
                        plotBands: [
                            {
                                from: 0,
                                to: 40,
                                color: '#55BF3B', // verde
                                thickness: '30%',
                            },
                            {
                                from: 40,
                                to: 80,
                                color: '#66BB6A', // verde claro
                                thickness: '30%',
                            },
                            {
                                from: 80,
                                to: 120,
                                color: '#e1be23', // amarillo
                                thickness: '30%',
                            },
                            {
                                from: 120,
                                to: 160,
                                color: '#FF9800', // naranja
                                thickness: '30%',
                            },
                            {
                                from: 160,
                                to: 200,
                                color: '#FF5722', // rojo
                                thickness: '30%',
                            },
                        ],
                    },
                    series: [
                        {
                            name: '',
                            data: [value2], //Este es el valor numérico que hay que modificar para cambiar la dirección de la flecha
                            dataLabels: {
                                enabled: false,
                            },
                        },
                    ],
                };

                const opciones_sc = {
                    chart: {
                        type: 'gauge',
                        plotBackgroundColor: null,
                        plotBackgroundImage: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                    },
                    accessibility: {
                        enabled: true
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: '',
                    },
                    pane: {
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '40%'],
                        background: [
                            {
                                backgroundColor: {
                                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                    stops: [
                                        [0, '#55BF3B'], // verde
                                        [0.25, '#66BB6A'], // verde claro
                                        [0.5, '#FFEB3B'], // amarillo
                                        [0.75, '#FF9800'], // naranja
                                        [1, '#FF5722'], // rojo
                                    ],
                                },
                                borderWidth: 0,
                                outerRadius: '0%',
                                innerRadius: '0%',
                            },
                        ],
                    },
                    yAxis: {
                        min: 0,
                        max: 200,
                        minorTickInterval: 'auto',
                        minorTickWidth: 1,
                        minorTickLength: 10,
                        minorTickPosition: 'inside',
                        minorTickColor: '#666',
                        tickWidth: 1,
                        tickPosition: 'inside',
                        tickLength: 10,
                        tickColor: '#666',
                        labels: {
                            step: 1,
                            formatter: function () {
                                if (this.value === 0) return 'Muy Bajo';
                                if (this.value === 50) return 'Bajo';
                                if (this.value === 100) return 'Medio';
                                if (this.value === 150) return 'Alto';
                                if (this.value === 200) return 'Muy Alto';
                                return '';
                            },
                            y: -6, //posición de los indicadores medio, bajo, alto.
                            style: {
                                fontSize: '12px',
                                fontWeight: 100,
                                color: 'black'
                            },
                        },
                        plotBands: [
                            {
                                from: 0,
                                to: 40,
                                color: '#55BF3B', // verde
                                thickness: '30%',
                            },
                            {
                                from: 40,
                                to: 80,
                                color: '#66BB6A', // verde claro
                                thickness: '30%',
                            },
                            {
                                from: 80,
                                to: 120,
                                color: '#e1be23', // amarillo
                                thickness: '30%',
                            },
                            {
                                from: 120,
                                to: 160,
                                color: '#FF9800', // naranja
                                thickness: '30%',
                            },
                            {
                                from: 160,
                                to: 200,
                                color: '#FF5722', // rojo
                                thickness: '30%',
                            },
                        ],
                    },
                    series: [
                        {
                            name: '',
                            data: [value_sc], //Este es el valor numérico que hay que modificar para cambiar la dirección de la flecha
                            dataLabels: {
                                enabled: false,
                            },
                        },
                    ],
                };
                const opciones1_sc = {
                    chart: {
                        type: 'gauge',
                        plotBackgroundColor: null,
                        plotBackgroundImage: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                    },
                    accessibility: {
                        enabled: true
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: '',
                    },
                    pane: {
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '40%'],
                        background: [
                            {
                                backgroundColor: {
                                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                    stops: [
                                        [0, '#55BF3B'], // verde
                                        [0.25, '#66BB6A'], // verde claro
                                        [0.5, '#FFEB3B'], // amarillo
                                        [0.75, '#FF9800'], // naranja
                                        [1, '#FF5722'], // rojo
                                    ],
                                },
                                borderWidth: 0,
                                outerRadius: '0%',
                                innerRadius: '0%',
                            },
                        ],
                    },
                    yAxis: {
                        min: 0,
                        max: 200,
                        minorTickInterval: 'auto',
                        minorTickWidth: 1,
                        minorTickLength: 10,
                        minorTickPosition: 'inside',
                        minorTickColor: '#666',
                        tickWidth: 1,
                        tickPosition: 'inside',
                        tickLength: 10,
                        tickColor: '#666',
                        labels: {
                            step: 1,
                            formatter: function () {
                                if (this.value === 0) return 'Muy Bajo';
                                if (this.value === 50) return 'Bajo';
                                if (this.value === 100) return 'Medio';
                                if (this.value === 150) return 'Alto';
                                if (this.value === 200) return 'Muy Alto';
                                return '';
                            },
                            y: -6, //posición de los indicadores medio, bajo, alto.
                            style: {
                                fontSize: '12px',
                                fontWeight: 100,
                                color: 'black'
                            },
                        },
                        plotBands: [
                            {
                                from: 0,
                                to: 40,
                                color: '#55BF3B', // verde
                                thickness: '30%',
                            },
                            {
                                from: 40,
                                to: 80,
                                color: '#66BB6A', // verde claro
                                thickness: '30%',
                            },
                            {
                                from: 80,
                                to: 120,
                                color: '#e1be23', // amarillo
                                thickness: '30%',
                            },
                            {
                                from: 120,
                                to: 160,
                                color: '#FF9800', // naranja
                                thickness: '30%',
                            },
                            {
                                from: 160,
                                to: 200,
                                color: '#FF5722', // rojo
                                thickness: '30%',
                            },
                        ],
                    },
                    series: [
                        {
                            name: '',
                            data: [value1_sc], //Este es el valor numérico que hay que modificar para cambiar la dirección de la flecha
                            dataLabels: {
                                enabled: false,
                            },
                        },
                    ],
                };
                const opciones2_sc = {
                    chart: {
                        type: 'gauge',
                        plotBackgroundColor: null,
                        plotBackgroundImage: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                    },
                    accessibility: {
                        enabled: true
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: '',
                    },
                    pane: {
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '40%'],
                        background: [
                            {
                                backgroundColor: {
                                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                    stops: [
                                        [0, '#55BF3B'], // verde
                                        [0.25, '#66BB6A'], // verde claro
                                        [0.5, '#FFEB3B'], // amarillo
                                        [0.75, '#FF9800'], // naranja
                                        [1, '#FF5722'], // rojo
                                    ],
                                },
                                borderWidth: 0,
                                outerRadius: '0%',
                                innerRadius: '0%',
                            },
                        ],
                    },
                    yAxis: {
                        min: 0,
                        max: 200,
                        minorTickInterval: 'auto',
                        minorTickWidth: 1,
                        minorTickLength: 10,
                        minorTickPosition: 'inside',
                        minorTickColor: '#666',
                        tickWidth: 1,
                        tickPosition: 'inside',
                        tickLength: 10,
                        tickColor: '#666',
                        labels: {
                            step: 1,
                            formatter: function () {
                                if (this.value === 0) return 'Muy Bajo';
                                if (this.value === 50) return 'Bajo';
                                if (this.value === 100) return 'Medio';
                                if (this.value === 150) return 'Alto';
                                if (this.value === 200) return 'Muy Alto';
                                return '';
                            },
                            y: -6, //posición de los indicadores medio, bajo, alto.
                            style: {
                                fontSize: '12px',
                                fontWeight: 100,
                                color: 'black'
                            },
                        },
                        plotBands: [
                            {
                                from: 0,
                                to: 40,
                                color: '#55BF3B', // verde
                                thickness: '30%',
                            },
                            {
                                from: 40,
                                to: 80,
                                color: '#66BB6A', // verde claro
                                thickness: '30%',
                            },
                            {
                                from: 80,
                                to: 120,
                                color: '#e1be23', // amarillo
                                thickness: '30%',
                            },
                            {
                                from: 120,
                                to: 160,
                                color: '#FF9800', // naranja
                                thickness: '30%',
                            },
                            {
                                from: 160,
                                to: 200,
                                color: '#FF5722', // rojo
                                thickness: '30%',
                            },
                        ],
                    },
                    series: [
                        {
                            name: '',
                            data: [value2_sc], //Este es el valor numérico que hay que modificar para cambiar la dirección de la flecha
                            dataLabels: {
                                enabled: false,
                            },
                        },
                    ],
                };

                setoptions(opciones)
                setoptions1(opciones1)
                setoptions2(opciones2)

                setoptions_sc(opciones_sc)
                setoptions1_sc(opciones1_sc)
                setoptions2_sc(opciones2_sc)


                //startAnimation();
            }

            setLoading(false);
            setdatooo(datos);
            //setDatos(datos)
            chartRef.current.style.display = 'block';
            chartRef_sc.current.style.display = 'block';
        }
        if (chartRef !== null && chartRef.current != null) {
            chartRef.current.style.display = 'none';
        }
        if (chartRef_sc !== null && chartRef_sc.current != null) {
            chartRef_sc.current.style.display = 'none';
        }
        maxRisk = 0;
        maxRisk1 = 0;
        maxRisk2 = 0;
        maxRisk_sc = 0;
        maxRisk1_sc = 0;
        maxRisk2_sc = 0;
        setLoading(true);
        handleClickTest();
    }, [dataa, selectedOption])

    function procesarColor(color) {
        if (color === 'very high') return '#FF5722';
        else if (color === 'high') return '#FF9800';
        else if (color === 'medium') return '#FFB347';
        else if (color === 'low') return '#7fbf7f';
        else if (color === 'very low') return 'rgb(85, 191, 59)';
    }

    function procesarRiesgoTexto(riesgo, indicador, escala) {
        let riesgoActualNum = 0;
        let riesgoActualNum1 = 0;
        let riesgoActualNum2 = 0;
        let riesgoActualNum_sc = 0;
        let riesgoActualNum1_sc = 0;
        let riesgoActualNum2_sc = 0;

        //"coas", "cool", "flu", "heat"
        if (escala === 'bu') {
            if (riesgo === 'very high') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1 = 5;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2 = 5;
                riesgoActualNum = 5
            }
            else if (riesgo === 'high') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1 = 4;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2 = 4;
                riesgoActualNum = 4
            }
            else if (riesgo === 'medium') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1 = 3;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2 = 3;
                riesgoActualNum = 3
            }
            else if (riesgo === 'low') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1 = 2;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2 = 2;
                riesgoActualNum = 2
            }
            else if (riesgo === 'very low') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1 = 1;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2 = 1;
                riesgoActualNum = 1
            }
            else {

            }

            if (maxRisk < riesgoActualNum) {
                maxRisk = riesgoActualNum;
            }
            if (maxRisk1 < riesgoActualNum1) {
                maxRisk1 = riesgoActualNum1;
            }
            if (maxRisk2 < riesgoActualNum2) {
                maxRisk2 = riesgoActualNum2;
            }
        }
        else if (escala === 'cusec') {
            if (riesgo === 'very high') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1_sc = 5;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2_sc = 5;
                riesgoActualNum_sc = 5
            }
            else if (riesgo === 'high') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1_sc = 4;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2_sc = 4;
                riesgoActualNum_sc = 4
            }
            else if (riesgo === 'medium') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1_sc = 3;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2_sc = 3;
                riesgoActualNum_sc = 3
            }
            else if (riesgo === 'low') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1_sc = 2;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2_sc = 2;
                riesgoActualNum_sc = 2
            }
            else if (riesgo === 'very low') {
                if (indicador === 'coas' || indicador === 'flu' || indicador === 'fluv') riesgoActualNum1_sc = 1;
                else if (indicador === 'cool' || indicador === 'heat') riesgoActualNum2_sc = 1;
                riesgoActualNum_sc = 1
            }
            else {

            }

            if (maxRisk_sc < riesgoActualNum_sc) {
                maxRisk_sc = riesgoActualNum_sc;
            }
            if (maxRisk1_sc < riesgoActualNum1_sc) {
                maxRisk1_sc = riesgoActualNum1_sc;
            }
            if (maxRisk2_sc < riesgoActualNum2_sc) {
                maxRisk2_sc = riesgoActualNum2_sc;
            }
        }




        if (riesgo === 'very high') return 'Muy Alto';
        else if (riesgo === 'high') return 'Alto';
        else if (riesgo === 'medium') return 'Medio';
        else if (riesgo === 'low') return 'Bajo';
        else if (riesgo === 'very low') return 'Muy Bajo';
        else return '0';
    }

    function procesarClimatico(indicador, base, rcp45, rcp85, escenario, horizonte) {
        const arrayDatos = [/*"agg", "coas", "cool", "flu", "heat"*/];
        arrayDatos[indicador] = {
            "base": {
                "bu": {
                    "impacto": 0,
                    "riesgo": 0
                },
                "cusec": {
                    "impacto": 0,
                    "riesgo": 0
                }
            },
            "rcp45": {
                "short": {
                    "bu": {
                        "impacto": 0,
                        "riesgo": 0
                    },
                    "cusec": {
                        "impacto": 0,
                        "riesgo": 0
                    }
                },
                "medium": {
                    "bu": {
                        "impacto": 0,
                        "riesgo": 0
                    },
                    "cusec": {
                        "impacto": 0,
                        "riesgo": 0
                    }
                },
                "long": {
                    "bu": {
                        "impacto": 0,
                        "riesgo": 0
                    },
                    "cusec": {
                        "impacto": 0,
                        "riesgo": 0
                    }
                }
            },
            "rcp85": {
                "short": {
                    "bu": {
                        "impacto": 0,
                        "riesgo": 0
                    },
                    "cusec": {
                        "impacto": 0,
                        "riesgo": 0
                    }
                },
                "medium": {
                    "bu": {
                        "impacto": 0,
                        "riesgo": 0
                    },
                    "cusec": {
                        "impacto": 0,
                        "riesgo": 0
                    }
                },
                "long": {
                    "bu": {
                        "impacto": 0,
                        "riesgo": 0
                    },
                    "cusec": {
                        "impacto": 0,
                        "riesgo": 0
                    }
                }
            }
        }

        let escene;
        if (escenario === 'rcp45') escene = rcp45;
        else if (escenario === 'rcp85') escene = rcp85;
        else escene = base;

        let horizonte_2;
        if (horizonte === 'short') horizonte_2 = 'short';
        else if (horizonte === 'medium') horizonte_2 = 'middle';
        else if (horizonte === 'long') horizonte_2 = 'long';
        else horizonte_2 = 'base';

        let indicador_2;
        if (indicador === 'flu') indicador_2 = 'flu';
        else indicador_2 = indicador;

        let multiply;
        if (indicador === 'flu') multiply = 100;
        else if (indicador === 'coas') multiply = 100;
        else multiply = 1;

        if (escenario === 'base') {
            arrayDatos[indicador][escenario]["bu"]["impacto"] = base[0]["asset_" + indicador_2 + "_impact"] >= 0 ? new Intl.NumberFormat('en-DE').format(parseFloat(base[0]["asset_" + indicador_2 + "_impact"] * multiply).toFixed(2)) : 0;
            arrayDatos[indicador][escenario]["bu"]["riesgo"] = procesarRiesgoTexto(base[0]["asset_" + indicador + "_risk"], indicador, 'bu');
            arrayDatos[indicador][escenario]["bu"]["riesgo_color"] = procesarColor(base[0]["asset_" + indicador + "_risk"]);
            arrayDatos[indicador][escenario]["cusec"]["impacto"] = base[0]["census_" + indicador_2 + "_impact"] >= 0 ? new Intl.NumberFormat('en-DE').format(parseFloat(base[0]["census_" + indicador_2 + "_impact"] * multiply).toFixed(2)) : 0;
            arrayDatos[indicador][escenario]["cusec"]["riesgo"] = procesarRiesgoTexto(base[0]["census_" + indicador + "_risk"], indicador, 'cusec');
            arrayDatos[indicador][escenario]["cusec"]["riesgo_color"] = procesarColor(base[0]["census_" + indicador + "_risk"]);
            return arrayDatos[indicador][escenario];
        } else {
            //if (escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["asset_" + indicador_2 + "_impact"] === undefined) escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["asset_" + indicador_2 + "_impact"] = 0;
            //if (escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["census_" + indicador_2 + "_impact"] === undefined) escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["census_" + indicador_2 + "_impact"] = 0;

            arrayDatos[indicador][escenario][horizonte]["bu"]["impacto"] = escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["asset_" + indicador_2 + "_impact"] >= 0 ? new Intl.NumberFormat('en-DE').format(parseFloat(escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["asset_" + indicador_2 + "_impact"] * multiply).toFixed(2)) : 0;
            arrayDatos[indicador][escenario][horizonte]["bu"]["riesgo"] = procesarRiesgoTexto(escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["asset_" + indicador + "_risk"], indicador, 'bu');
            arrayDatos[indicador][escenario][horizonte]["bu"]["riesgo_color"] = procesarColor(escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["asset_" + indicador + "_risk"]);
            arrayDatos[indicador][escenario][horizonte]["cusec"]["impacto"] = escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["census_" + indicador_2 + "_impact"] >= 0 ? new Intl.NumberFormat('en-DE').format(parseFloat(escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["census_" + indicador_2 + "_impact"] * multiply).toFixed(2)) : 0;
            arrayDatos[indicador][escenario][horizonte]["cusec"]["riesgo"] = procesarRiesgoTexto(escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["census_" + indicador + "_risk"], indicador, 'cusec');
            arrayDatos[indicador][escenario][horizonte]["cusec"]["riesgo_color"] = procesarColor(escene.filter(person => person.horizon === horizonte_2 + '-term')[0]["census_" + indicador + "_risk"]);
            return arrayDatos[indicador][escenario][horizonte];
        }
    }

    if (datos["coas"] === undefined && loading === false) return (
        <Result
            //title="No se puede mostrar información"
            icon={null}
            subTitle="No hay datos disponibles para el activo seleccionado."
            style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}
        />
    );

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value)
    };

    // el html de este componente comprueba en multiples ocasiones los datos obtenidos del fetch que hay más arriba por lo que el codigo es extensisimo e ininteligible
    // los estilos de los td aplican un color al texto dependiendo de su calificación, el contenido de las etiquetas es esa misma comprobación pero mostrando el texto obtenido 
    return (
        <div style={{ paddingBottom: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'end', marginRight: '10px' }}>
                <Radio.Group onChange={handleOptionChange} value={selectedOption}>
                    {/*<Radio.Button value="tabla">Tabla</Radio.Button>
                    <Radio.Button value="graficas">Gráficas</Radio.Button>*/}
                </Radio.Group>
            </div>
            {selectedOption === 'tabla' ? (
                <div style={{ paddingBottom: 0 }}>
                    <div ref={chartRef} className='velocimetroo' style={{ display: 'none' }}>
                        <Divider orientation="left">Riesgo Agregado Total - <span style={{textDecoration:'1px dashed underline'}}>Edificio</span></Divider>
                        <Row>
                            <Col flex="1 1 auto" style={{ width: 100, backgroundColor: 'white', height: 300 }}>
                                <div style={{ fontWeight: 'bold' }}>
                                    <center>RIESGO TOTAL</center>
                                </div>
                                <div>
                                    <HighchartsReact
                                        containerProps={{ style: { height: "300px", width: "100%" } }}
                                        highcharts={Highcharts}
                                        options={options}
                                    />
                                </div>
                            </Col>
                            <Col flex="1 1 auto" style={{ width: 100, backgroundColor: 'white', height: 300 }}>
                                <div>
                                    <center>INUNDACIONES</center>
                                </div>
                                <div>
                                    <HighchartsReact
                                        containerProps={{ style: { height: "300px", width: "100%" } }}
                                        highcharts={Highcharts}
                                        options={options1}
                                    />
                                </div>
                            </Col>
                            <Col flex="1 1 auto" style={{ width: 100, backgroundColor: 'white', height: 300 }}>
                                <div>
                                    <center>TEMPERATURA</center>
                                </div>
                                <div>
                                    <HighchartsReact
                                        containerProps={{ style: { height: "300px", width: "100%" } }}
                                        highcharts={Highcharts}
                                        options={options2}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div ref={chartRef_sc} className='velocimetroo' style={{ display: 'none', position: 'relative', top: -100 }}>
                        <Divider orientation="left">Riesgo Agregado Total - <span style={{textDecoration:'1px dashed underline'}}>Área Homogénea</span></Divider>
                        <Row>
                            <Col flex="1 1 auto" style={{ width: 100, backgroundColor: 'white', height: 300 }}>
                                <div style={{ fontWeight: 'bold' }}>
                                    <center>RIESGO TOTAL</center>
                                </div>
                                <div>
                                    <HighchartsReact
                                        containerProps={{ style: { height: "300px", width: "100%" } }}
                                        highcharts={Highcharts}
                                        options={options_sc}
                                    />
                                </div>
                            </Col>
                            <Col flex="1 1 auto" style={{ width: 100, backgroundColor: 'white', height: 300 }}>
                                <div>
                                    <center>INUNDACIONES</center>
                                </div>
                                <div>
                                    <HighchartsReact
                                        containerProps={{ style: { height: "300px", width: "100%" } }}
                                        highcharts={Highcharts}
                                        options={options1_sc}
                                    />
                                </div>
                            </Col>
                            <Col flex="1 1 auto" style={{ width: 100, backgroundColor: 'white', height: 300 }}>
                                <div>
                                    <center>TEMPERATURA</center>
                                </div>
                                <div>
                                    <HighchartsReact
                                        containerProps={{ style: { height: "300px", width: "100%" } }}
                                        highcharts={Highcharts}
                                        options={options2_sc}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {
                        loading ?
                            <Result key={0} icon={<img src={Sello} alt='sello veltis' style={{ height: 'auto', width: '75%' }} />} title="Cargando información" subTitle="" style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}  />
                            :

                            <div className='contenttt' style={{ position: 'relative', top: -200 }} key={1}>

                                <Divider orientation="left">Riesgo Individual</Divider>
                                {
                                    indiceData.map((element) => (
                                        <>
                                            <div className="sc-bVhZWL gZRWkQ" style={{ paddingBottom: '20px' }}>
                                                <section id="transactions" className="sc-fudrna bQleFN">
                                                    <header>
                                                        <div>
                                                            <h4 className="sc-ijBzsJ jLmhIK">{element.nombre}</h4>
                                                            <p className="sc-tbhkc iihNdL" />
                                                        </div>
                                                    </header>
                                                    <div className="cards__container" style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', alignItems: 'center', justifyContent: 'center' }}>
                                                        <article id="transactions" className="sc-ivAKqu byaMit">
                                                            <div className="sc-gVhppA iLnozd">
                                                                <div className="sc-ddtzlP ceXVlo">
                                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                                        <p className="sc-bDaekV bYxYhS" style={{ fontSize: 'large', fontWeight: 600 }}>Edificio</p>
                                                                        <p style={{ color: 'red' }}>
                                                                            <span role="img" aria-label="down-circle" className="anticon anticon-down-circle">
                                                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="down-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                                                    <path d="M690 405h-46.9c-10.2 0-19.9 4.9-25.9 13.2L512 563.6 406.8 418.2c-6-8.3-15.6-13.2-25.9-13.2H334c-6.5 0-10.3 7.4-6.5 12.7l178 246c3.2 4.4 9.7 4.4 12.9 0l178-246c3.9-5.3.1-12.7-6.4-12.7z" />
                                                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                                                                </svg>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="sc-kBaCpg biRCTt" />
                                                            </div>
                                                            <ul>
                                                                <div className="sc-gJmMlv ldDgjY">
                                                                    <table className="tabla_climaticos">
                                                                        <thead className="titulos_tabla_climaticos">
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>{veltisTooltip('Histórico', 'Periodo de análisis desde 1971 a 2010')}</th>
                                                                                <th>{veltisTooltip('Escenarios', '')}</th>
                                                                                <th>{veltisTooltip('Futuro Cercano', 'Proyección desde 2011 a 2040')}</th>
                                                                                <th>{veltisTooltip('Futuro Medio', 'Proyección desde 2041 a 2070')}</th>
                                                                                <th>{veltisTooltip('Futuro Lejano', 'Proyección desde 2071 a 2100')}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="contenido_tabla_climaticos">
                                                                            <tr className="fila-impacto">
                                                                                <td rowSpan={2}>
                                                                                    {veltisTooltip('Impacto', 'Procentaje del daño sobre el coste de construcción para un periodo de 1 año.')}
                                                                                </td>
                                                                                <td rowSpan={2}>{datos[element.internal]["base"]["bu"]["impacto"]}{element.sufix}</td>
                                                                                <td>RCP 4.5</td>
                                                                                <td>{datos[element.internal]["rcp45"]["short"]["bu"]["impacto"]}{element.sufix}</td>
                                                                                <td>{datos[element.internal]["rcp45"]["medium"]["bu"]["impacto"]}{element.sufix}</td>
                                                                                <td>{datos[element.internal]["rcp45"]["long"]["bu"]["impacto"]}{element.sufix}</td>
                                                                            </tr>
                                                                            <tr className="fila-impacto">
                                                                                <td>RCP 8.5</td>
                                                                                <td>{datos[element.internal]["rcp85"]["short"]["bu"]["impacto"]}{element.sufix}</td>
                                                                                <td>{datos[element.internal]["rcp85"]["medium"]["bu"]["impacto"]}{element.sufix}</td>
                                                                                <td>{datos[element.internal]["rcp85"]["long"]["bu"]["impacto"]}{element.sufix}</td>
                                                                            </tr>
                                                                            <tr className="fila-riesgo">
                                                                                <td rowSpan={2}>
                                                                                    {veltisTooltip('Riesgo', '')}
                                                                                </td>
                                                                                <td rowSpan={2} style={{ color: datos[element.internal]["base"]["bu"]["riesgo_color"] }}>{datos[element.internal]["base"]["bu"]["riesgo"]}</td>
                                                                                <td>RCP 4.5</td>
                                                                                <td style={{ color: datos[element.internal]["rcp45"]["short"]["bu"]["riesgo_color"] }}>{datos[element.internal]["rcp45"]["short"]["bu"]["riesgo"]}</td>
                                                                                <td style={{ color: datos[element.internal]["rcp45"]["medium"]["bu"]["riesgo_color"] }}>{datos[element.internal]["rcp45"]["medium"]["bu"]["riesgo"]}</td>
                                                                                <td style={{ color: datos[element.internal]["rcp45"]["long"]["bu"]["riesgo_color"] }}>{datos[element.internal]["rcp45"]["long"]["bu"]["riesgo"]}</td>
                                                                            </tr>
                                                                            <tr className="fila-riesgo">
                                                                                <td>RCP 8.5</td>
                                                                                <td style={{ color: datos[element.internal]["rcp85"]["short"]["bu"]["riesgo_color"] }}>{datos[element.internal]["rcp85"]["short"]["bu"]["riesgo"]}</td>
                                                                                <td style={{ color: datos[element.internal]["rcp85"]["medium"]["bu"]["riesgo_color"] }}>{datos[element.internal]["rcp85"]["medium"]["bu"]["riesgo"]}</td>
                                                                                <td style={{ color: datos[element.internal]["rcp85"]["long"]["bu"]["riesgo_color"] }}>{datos[element.internal]["rcp85"]["long"]["bu"]["riesgo"]}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </ul>
                                                        </article>
                                                        <article id="transactions" className="sc-ivAKqu byaMit">
                                                            <div className="sc-gVhppA iLnozd">
                                                                <div className="sc-ddtzlP ceXVlo">
                                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                                        <p className="sc-bDaekV bYxYhS" style={{ fontSize: 'large', fontWeight: 600 }}>Sección Censal</p>
                                                                        <p style={{ color: 'red' }}>
                                                                            <span role="img" aria-label="down-circle" className="anticon anticon-down-circle">
                                                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="down-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                                                    <path d="M690 405h-46.9c-10.2 0-19.9 4.9-25.9 13.2L512 563.6 406.8 418.2c-6-8.3-15.6-13.2-25.9-13.2H334c-6.5 0-10.3 7.4-6.5 12.7l178 246c3.2 4.4 9.7 4.4 12.9 0l178-246c3.9-5.3.1-12.7-6.4-12.7z" />
                                                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                                                                </svg>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="sc-kBaCpg biRCTt" />
                                                            </div>
                                                            <ul>
                                                                <div className="sc-gJmMlv ldDgjY">
                                                                    <table className="tabla_climaticos">
                                                                        <thead className="titulos_tabla_climaticos">
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>{veltisTooltip('Histórico', 'Periodo de análisis desde 1971 a 2010')}</th>
                                                                                <th>{veltisTooltip('Escenarios', '')}</th>
                                                                                <th>{veltisTooltip('Futuro Cercano', 'Proyección desde 2011 a 2040')}</th>
                                                                                <th>{veltisTooltip('Futuro Medio', 'Proyección desde 2041 a 2070')}</th>
                                                                                <th>{veltisTooltip('Futuro Lejano', 'Proyección desde 2071 a 2100')}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="contenido_tabla_climaticos">
                                                                            <tr className="fila-impacto">
                                                                                <td rowSpan={2}>
                                                                                    {veltisTooltip('Impacto', 'Procentaje del daño sobre el coste de construcción para un periodo de 1 año.')}
                                                                                </td>
                                                                                <td rowSpan={2}>{datos[element.internal]["base"]["cusec"]["impacto"]}{element.sufix}</td>
                                                                                <td>RCP 4.5</td>
                                                                                <td>{datos[element.internal]["rcp45"]["short"]["cusec"]["impacto"]}{element.sufix}</td>
                                                                                <td>{datos[element.internal]["rcp45"]["medium"]["cusec"]["impacto"]}{element.sufix}</td>
                                                                                <td>{datos[element.internal]["rcp45"]["long"]["cusec"]["impacto"]}{element.sufix}</td>
                                                                            </tr>
                                                                            <tr className="fila-impacto">
                                                                                <td>RCP 8.5</td>
                                                                                <td>{datos[element.internal]["rcp85"]["short"]["cusec"]["impacto"]}{element.sufix}</td>
                                                                                <td>{datos[element.internal]["rcp85"]["medium"]["cusec"]["impacto"]}{element.sufix}</td>
                                                                                <td>{datos[element.internal]["rcp85"]["long"]["cusec"]["impacto"]}{element.sufix}</td>
                                                                            </tr>
                                                                            <tr className="fila-riesgo">
                                                                                <td rowSpan={2}>
                                                                                    {veltisTooltip('Riesgo', '')}
                                                                                </td>
                                                                                <td rowSpan={2} style={{ color: datos[element.internal]["base"]["cusec"]["riesgo_color"] }}>{datos[element.internal]["base"]["cusec"]["riesgo"]}</td>
                                                                                <td>RCP 4.5</td>
                                                                                <td style={{ color: datos[element.internal]["rcp45"]["short"]["cusec"]["riesgo_color"] }}>{datos[element.internal]["rcp45"]["short"]["cusec"]["riesgo"]}</td>
                                                                                <td style={{ color: datos[element.internal]["rcp45"]["medium"]["cusec"]["riesgo_color"] }}>{datos[element.internal]["rcp45"]["medium"]["cusec"]["riesgo"]}</td>
                                                                                <td style={{ color: datos[element.internal]["rcp45"]["long"]["cusec"]["riesgo_color"] }}>{datos[element.internal]["rcp45"]["long"]["cusec"]["riesgo"]}</td>
                                                                            </tr>
                                                                            <tr className="fila-riesgo">
                                                                                <td>RCP 8.5</td>
                                                                                <td style={{ color: datos[element.internal]["rcp85"]["short"]["cusec"]["riesgo_color"] }}>{datos[element.internal]["rcp85"]["short"]["cusec"]["riesgo"]}</td>
                                                                                <td style={{ color: datos[element.internal]["rcp85"]["medium"]["cusec"]["riesgo_color"] }}>{datos[element.internal]["rcp85"]["medium"]["cusec"]["riesgo"]}</td>
                                                                                <td style={{ color: datos[element.internal]["rcp85"]["long"]["cusec"]["riesgo_color"] }}>{datos[element.internal]["rcp85"]["long"]["cusec"]["riesgo"]}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </ul>
                                                        </article>
                                                    </div>
                                                    <div className="sc-izAXJK iSQncy" />
                                                </section>
                                            </div>
                                            <Divider orientation="left"></Divider>
                                        </>
                                    ))
                                }
                            </div>

                    }
                </div>
            )
                :
                (
                    <GraficasClimaticas datooo={datos} />
                )}
        </div >
    )
}

export default IndicadoresClimaticos;