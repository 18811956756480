import React, { useState } from 'react';
import { ColorPicker, Layout, Menu, theme, Slider, Button, Input, Space, Tooltip} from 'antd';
import { EyeOutlined, DeleteOutlined, EyeInvisibleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import './AfeccionDrawer.css';
import AddLayer from './AddLayer';

const { Content, Sider } = Layout;

const items2 = [
  {
    value: 'activo',
    key: 0,
    label: 'Activo',
    opacity: 100,
    color: 'purple',
    active: false,
  },
  {
    value: 'inundaciones',
    key: 1,
    label: 'Inundaciones',
    opacity: 20,
    color: 'orange',
    active: false,
  },
  {
    value: 'ambiental',
    key: 2,
    label: 'Ambiental',
    opacity: 80,
    color: 'blue',
    active: false,

  },

  {
    value: 'cambio climatico',
    key: 3,
    label: 'Cambio Climático',
    opacity: 60,
    color: 'green',
    active: false,
  },

  {
    value: 'zonas sismogenicas',
    key: 4,
    label: 'Zonas Sismogénicas',
    opacity: 40,
    color: 'yellow',
    active: false,
  },
  {
    value: 'tendidos electricos',
    key: 5,
    label: 'Tendidos Eléctricos',
    opacity: 30,
    color: 'red',
    active: false,
  },
];

const ContenidoDrawer = ({setoverSelectedItem, overSelectedItem, setCapas}) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [visibleIcons, setVisibleIcons] = useState({});
  const [selectedContent, setSelectedContent] = useState(null);
  const [clickedItem, setClickedItem] = useState(null);
  const [newArray, setNewArray] = useState(items2);
  const [active, setActive] = useState(false);
  const [isInputVisible, setInputVisible] = useState(false);
  const [nombre, setNombre] = useState({selectedContent})

  setCapas(newArray);

  const handleMouseEnter = (value) => {
    setHoveredItem(value);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleIconClick = (key) => {
    setVisibleIcons((prevVisibleIcons) => ({
      ...prevVisibleIcons,
      [key]: !prevVisibleIcons[key],
    }));
    setNewArray((prevArray) => {
      const newArray = [...prevArray];
      const clickedItemIndex = newArray.findIndex((item) => item.key === clickedItem);

      if (clickedItemIndex !== -1) {
        const updatedItem = {
          ...newArray[clickedItemIndex],
          active: !active,
        };
        newArray[clickedItemIndex] = updatedItem;
      }
      return newArray
    })
  };

  const mostrarContent = (item) => {
    setSelectedContent(item.label);
    setClickedItem(item.key);
  };

  const handleChangeColor = (newColor) => {
    setNewArray((prevArrayColor) => {
      const newArray = [...prevArrayColor];
      const clickedItemIndex = newArray.findIndex((item) => item.key === clickedItem);

      if (clickedItemIndex !== -1) {
        const updatedItem = {
          ...newArray[clickedItemIndex],
          color: newColor,
        };
        newArray[clickedItemIndex] = updatedItem;
      }
      return newArray
    })

  };
  const handleChangeOpacity = (opacity) => {
    setNewArray((prevArray) => {
      const newArray = [...prevArray];
      const clickedItemIndex = newArray.findIndex((item) => item.key === clickedItem);

      if (clickedItemIndex !== -1) {
        const updatedItem = {
          ...newArray[clickedItemIndex],
          opacity: opacity,
        };
        newArray[clickedItemIndex] = updatedItem;
      }
      return newArray
    })
  };

  const borraritem = (key) => {
    const selected = newArray.findIndex((item) => item.key === key)
    newArray.splice(selected, 1)
    
    return newArray
  }

  const renderMenuItems = (items) => {
    return items.map((item) => (
        <Menu.Item
          key={item.key}
          onClick={() => mostrarContent(item)}
          onMouseEnter={() => handleMouseEnter(item.value)}
          onMouseLeave={handleMouseLeave}
          className='afecciones'
        >
          <div className='afecciones'>
            <div>{item.label}</div>
            <div>
              {hoveredItem === item.value && (
                <>
                  {visibleIcons[item.key] ? (
                    <EyeOutlined onClick={() => handleIconClick(item.key)} />
                  ) : (
                    <EyeInvisibleOutlined onClick={() => handleIconClick(item.key)} />
                  )}
                  {item.key === 0 ? '' : <DeleteOutlined onClick={() => borraritem(item.key)} />}
                </>
              )}
            </div>
          </div>
        </Menu.Item>
      ));
  }

  const handleButtonClick = () => {
    setInputVisible(!isInputVisible);
  };

  const onChangeName = (e) => {
    setNombre(e.target.value)
  }

  const actualizarNombre = () => {
    setNewArray((prevArray) => {
      const newArray = [...prevArray];
      const clickedItemIndex = newArray.findIndex((item) => item.key === clickedItem);

      if (clickedItemIndex !== -1) {
        const updatedItem = {
          ...newArray[clickedItemIndex],
          label: nombre,
        };
        newArray[clickedItemIndex] = updatedItem;
      }
      return newArray
    })

    setInputVisible(false);
  }

  function renderContent(items) {
    //ignorar el warning del map, antdesing necesita que se devuelva un array por lo que hay que usar map
    //si se usa forEach no se devuelve un array y por lo tanto no se muestra nada
    // eslint-disable-next-line array-callback-return
    return items.map((item) => {
      if (item.key === clickedItem) {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2>{item.label}</h2>
              <Tooltip title="Editar nombre capa" placement="top">
              <Button className='boton-editar' type="primary" icon={<EditOutlined />} onClick={handleButtonClick} />
              </Tooltip>
            </div>
            {isInputVisible && (
              <div>
                <Space>
                  <Space.Compact>
                    <Input style={{height: 30}} placeholder="Editar nombre capa..." onChange={onChangeName}/>
                    <Button className='boton-guardar' type="primary" onClick={actualizarNombre}><SaveOutlined/></Button>
                  </Space.Compact>
                </Space>
              </div>
            )}

            <div style={{ marginTop: 20 }}>
              <div className="slider-container">
                <h3>Opacidad(%)</h3>
                <Slider
                  min={0}
                  max={100}
                  defaultValue={item.opacity}
                  onChange={handleChangeOpacity}
                />
              </div>
              <div className="color-container">
                <h3>Selecciona un color:</h3>
                <ColorPicker defaultValue={item.color} onChange={handleChangeColor} format='rgb'></ColorPicker>
              </div>
            </div>
          </>
        )
      }
    })
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken();


  return (
    <>
    <AddLayer newArray={newArray} setNewArray={setNewArray} setoverSelectedItem={setoverSelectedItem} overSelectedItem={overSelectedItem}></AddLayer>
      <Layout>
        <Content style={{ padding: '0' }}>
          <Layout style={{ padding: ' 0', background: colorBgContainer }}>
            <Sider style={{ background: colorBgContainer }} width={240}>
              <Menu mode='vertical' defaultSelectedKeys={[]} defaultOpenKeys={[]}>
                {renderMenuItems(newArray)}
              </Menu>
            </Sider>
            <Content style={{ padding: '0 24px', minHeight: 600 }}>
              {renderContent(newArray)}
            </Content>
          </Layout>
        </Content>
      </Layout>
    </>
  );
};

export default ContenidoDrawer;