import React, { useState, useEffect } from 'react';
import { Divider, Radio, Result, Space, Input, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Chart, registerables } from 'chart.js';
import TablaRiesgos from './TablaRiesgos';
import './RiesgosSistemicos2.css';
import { requestPost } from '../../hooks/Request/Request';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import img1 from './11111.PNG'
import img2 from './22222.PNG'


Chart.register(...registerables);
const riesgos = [
    { NAMEDB: 'CICL_NAC', name: "Compraventas Vivienda Total", key: 7, riesgo: 23, unidad: '', active: false, color: '#FF3357', visible: true },
    { NAMEDB: 'EVO_NAC', name: "Evolución Precio unitario", key: 17, riesgo: 88, unidad: 'Eur/m2', active: false, color: '#A1FF33', visible: true },
    { NAMEDB: 'VOL_NAC', name: "Volatilidad precio (var%anual)", key: 40, riesgo: 77, unidad: '%', active: false, color: '#33FFA1', visible: true },
    { NAMEDB: 'PIB_NAC', name: "PIB", key: 34, riesgo: 42, unidad: '%', active: false, color: '#57FF33', visible: true },
    { NAMEDB: 'CVN', name: "Compraventas vivienda nueva", key: 12, riesgo: 74, unidad: '', active: false, color: '#FF5733', visible: true },
    { NAMEDB: 'CV2M', name: "Compraventas segunda mano", key: 11, riesgo: 12, unidad: '', active: false, color: '#33A157', visible: true },
    { NAMEDB: 'E3M_NAC', name: "Euribor 3M", key: 14, riesgo: 95, unidad: '', active: false, color: '#A133FF', visible: true },
    { NAMEDB: 'BURB_NAC', name: "Burbuja Inmobiliaria", key: 2, riesgo: 59, unidad: '%', active: false, color: '#33FF57', visible: true },
    { NAMEDB: 'R_PS_PV', name: "Tensión Precio Suelo/Vivienda", key: 39, riesgo: 5, unidad: '%', active: false, color: '#FF3357', visible: true },
    { NAMEDB: 'AFI_NAC', name: "Empleo afiliados", key: 15, riesgo: 2, unidad: '', active: false, color: '#FF3357', visible: true },
    { NAMEDB: 'TPR_NAC', name: "Tasa de Paro Registrado", key: 29, riesgo: 15, unidad: '%', active: false, color: '#5733FF', visible: true },
    { NAMEDB: 'PAR_NAC', name: "Paro Registrado", key: 32, riesgo: 23, unidad: '', active: false, color: '#33FFA1', visible: true },
    { NAMEDB: 'BURB3', name: "Esfuerzo en años hipoteca", key: 4, riesgo: 56, unidad: 'años', active: false, color: '#FF33A1', visible: true },
    { NAMEDB: 'DSC_NAC', name: "Desconfianza Empresarial", key: 13, riesgo: 34, unidad: '', active: false, color: '#5733FF', visible: true },
    { NAMEDB: 'ICC_NAC', name: "Índice Confianza del Consumidor", key: 20, riesgo: 59, unidad: '', active: false, color: '#FF5733', visible: true },
    { NAMEDB: 'INF_NAC', name: "Inflación", key: 22, riesgo: 73, unidad: '%', active: false, color: '#A133FF', visible: true },
    { NAMEDB: 'MOR_NAC', name: "Tasa de morosidad", key: 27, riesgo: 24, unidad: '%', active: false, color: '#33A157', visible: true },
    { NAMEDB: 'PSUELO', name: "Precio Suelo", key: 35, riesgo: 15, unidad: 'Eur/m2', active: false, color: '#33A157', visible: true },
    { NAMEDB: 'PRR_NAC', name: "Prima de Riesgo", key: 36, riesgo: 44, unidad: '', active: false, color: '#FF5733', visible: true },
    { NAMEDB: 'LIC_NAC', name: "Licitaciones", key: 24, riesgo: 31, unidad: '', active: false, color: '#33FFA1', visible: true },
    { NAMEDB: 'RLI', name: "Ratio Licitaciones", key: 38, riesgo: 36, unidad: '%', active: false, color: '#A133FF', visible: true },
    { NAMEDB: 'REL_NAC', name: "Reloj electoral", key: 37, riesgo: 50, unidad: '', active: false, color: '#5733FF', visible: true },
    { NAMEDB: 'STOCK_PROXY', name: "Proxy de stock viviendas", key: 31, riesgo: 23, unidad: '', active: false, color: '#FF3357', visible: true },
    { NAMEDB: 'LYG_NAC', name: "Ley y Gobierno", key: 23, riesgo: 33, unidad: '', active: false, color: '#FF3357', visible: true },
    { NAMEDB: 'ENI_NAC', name: "PIB UE", key: 16, riesgo: 58, unidad: '%', active: false, color: '#33FFA1', visible: true },
    { NAMEDB: 'ICM_NAC', name: "ICM", key: 19, riesgo: 59, unidad: '', active: false, color: '#33A157', visible: true },
    { NAMEDB: 'TII_NAC', name: "Transparencia INT'L", key: 30, riesgo: 83, unidad: '', active: false, color: '#A133FF', visible: true },
    { NAMEDB: 'TDC_NAC', name: "Tipo de cambio Eur U$D", key: 28, riesgo: 8, unidad: '', active: false, color: '#FF5733', visible: true },
    { NAMEDB: 'MSP_NAC', name: "Mediático Socio-Político", key: 25, riesgo: 71, unidad: '', active: false, color: '#A1FF33', visible: true },
    { NAMEDB: 'MII_NAC', name: "Mediáticos Internacionales", key: 26, riesgo: 92, unidad: '', active: false, color: '#57FF33', visible: true },
    { NAMEDB: 'EXP_NAC', name: "Exportaciones", key: 18, riesgo: 13, unidad: '', active: false, color: '#57FF33', visible: true },
    { NAMEDB: 'CRD_NAC', name: "Crédito al sector privado", key: 10, riesgo: 29, unidad: '', active: false, color: '#57FF33', visible: true },
    { NAMEDB: 'CPM_NAC', name: "Condiciones Políticas Monetarias", key: 9, riesgo: 43, unidad: '', active: false, color: '#A1FF33', visible: true },
    { NAMEDB: 'PRN_NAC', name: "Pernoctaciones", key: 33, riesgo: 3, unidad: '', active: false, color: '#A1FF33', visible: true },
    { NAMEDB: 'ALQ_P', name: "Alquiler", key: 1, riesgo: 2, unidad: 'Eur/m²', active: false, color: '#FF5733', visible: true },
    { NAMEDB: 'BURB2', name: "Porcentaje hipotecas/vivienda", key: 3, riesgo: 65, unidad: '%', active: false, color: '#5733FF', visible: true },
    { NAMEDB: 'BURB4', name: "Alquiler / Renta", key: 5, riesgo: 79, unidad: '%', active: false, color: '#33A1FF', visible: true },
    { NAMEDB: 'ALQ_RB', name: "Alquiler / Activo", key: 6, riesgo: 10, unidad: '%', active: false, color: '#A133FF', visible: true },
    { NAMEDB: 'CNS_NAC', name: "Consumo privado", key: 8, riesgo: 5, unidad: '', active: false, color: '#33FFA1', visible: true },
    { NAMEDB: 'IMP_NAC', name: "Importaciones", key: 21, riesgo: 53, unidad: '', active: false, color: '#5733FF', visible: true },
];

//Función para que según el riesgo que tenga cada objeto en el array (rango del 0 al 100) se le asigne un color, label y longitud de la barra.
const getOptionClass = (now) => {
    if (now <= 100 && now > 80) {
        return { color: "red", label: 'Muy alto', width: `${now}%` };
    } else if (now <= 80 && now > 60) {
        return { color: "orange", label: 'Alto', width: `${now}%` };
    } else if (now <= 60 && now > 40) {
        return { color: "yellow", label: 'Medio', width: `${now}%` };
    } else if (now <= 40 && now > 20) {
        return { color: "green", label: 'Bajo', width: `${now}%` };
    } else if (now <= 20 && now > 0) {
        return { color: "soft-green", label: 'Muy bajo', width: `${now}%` };
    } else {
        return { color: "white", label: '-', width: `${now}%` }
    }
}


const RiesgosSistemicos2 = ({dataa}) => {

    const [selectedOption, setSelectedOption] = useState('graficas');
    const [riesgos1, setRiesgos] = useState(riesgos)
    const [filtered, setFiltered] = useState(riesgos1)
    const [series, setSeries] = useState([]);
    const [yAxis, setYaxis] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const activeRiesgos = riesgos1.filter((riesgo) => riesgo.active);
        const newSeries = activeRiesgos.map((riesgo, index) => ({
            name: riesgo.name,
            color: riesgo.color,
            data: riesgo.data,
            yAxis: index,
        }));

        const newYaxis = activeRiesgos.map((riesgo) => ({
            labels: {
                style: {
                    color: riesgo.color
                }
            },
            title: {
                text: riesgo.unidad ? `${riesgo.name} (${riesgo.unidad})` : riesgo.name,
                style: {
                    color: riesgo.color
                }
            },
        }));

        setSeries(newSeries);
        setYaxis(newYaxis);
    }, [riesgos1]);

    // Definir la función que quieres ejecutar solo la primera vez
    const defaultLoadChart = async () => { await addDefaultGrafico(0, 1, 3); };

    // El segundo argumento de useEffect es un array de dependencias
    // Si este array está vacío, la función se ejecutará solo la primera vez
    useEffect(() => { setTimeout(() => { defaultLoadChart(); }, 1000); }, []);

    async function addDefaultGrafico(a, b, c) {
        const riesgosTmp = JSON.parse(JSON.stringify(riesgos1));
        //console.log(riesgosTmp)
        //console.log(a)
        let index = a;
        let nombreDB = riesgos1[index].NAMEDB;
        let response = await requestPost(
            'https://terminal.veltisrating.com:8888/api/getMacro',
            { indicador: nombreDB }
        );
        riesgosTmp[index] = {
            ...riesgosTmp[index],
            data: response.data.values,
            active: !riesgosTmp[index].active,
        };

        index = b;
        nombreDB = riesgos1[index].NAMEDB;
        response = await requestPost(
            'https://terminal.veltisrating.com:8888/api/getMacro',
            { indicador: nombreDB }
        );
        riesgosTmp[index] = {
            ...riesgosTmp[index],
            data: response.data.values,
            active: !riesgosTmp[index].active,
        };

        index = c;
        nombreDB = riesgos1[index].NAMEDB;
        response = await requestPost(
            'https://terminal.veltisrating.com:8888/api/getMacro',
            { indicador: nombreDB }
        );
        riesgosTmp[index] = {
            ...riesgosTmp[index],
            data: response.data.values,
            active: !riesgosTmp[index].active,
        };

        setRiesgos(riesgosTmp);
    };

    async function addGrafico(e) {
        const riesgosTmp = JSON.parse(JSON.stringify(riesgos1));
        const index = riesgosTmp.findIndex((objeto) => objeto.key === e.key);
        const nombreDB = riesgos1[index].NAMEDB;
        const response = await requestPost(
            'https://terminal.veltisrating.com:8888/api/getMacro',
            { indicador: nombreDB }
        );
        riesgosTmp[index] = {
            ...riesgosTmp[index],
            data: response.data.values,
            active: !riesgosTmp[index].active,
        };
        setRiesgos(riesgosTmp);
    };


    const items = filtered.map((riesgo) => {
        const riesgoColor = getOptionClass(riesgo.riesgo)
        if (riesgo.visible) {
            return (
                <div className={riesgo.active === true ? "riesgo selected" : "riesgo"} onClick={() => addGrafico(riesgo)} key={riesgo.key}>
                    <p >{riesgo.name}</p>
                    <div>
                    </div>
                    <div className="progress">
                        <div className={`progress-bar ${riesgoColor.color}`} style={{ width: `${riesgoColor.width}` }}>
                            <span className={`progress-bar-text ${riesgoColor.color}`}>{riesgoColor.label}</span>
                        </div>
                    </div>
                    <div className={riesgo.active === true ? "overlay selected" : "overlay"}></div>
                </div>
            )
        } else {
            return null
        }
    }
    )

    const activeRiesgos = riesgos1.filter((riesgo) => riesgo.active);
    //Añade el listado de seleccionados encima de la gráfica. Al pulsar se deselecciona ese riesgo.
    const botonera = activeRiesgos.map((riesgo) => {
        return (
            <div className='div-padre-boton' onClick={() => addGrafico(riesgo)}>
                <p className='nombre-indicador-boton'><CloseCircleOutlined /> {riesgo.name}</p>
            </div>
        )
    })

    const equis = series.length > 0 ? `Restablecer Todos` : null
    const equisIcon = series.length > 0 ? <CloseCircleOutlined /> : null


    function handleOptionChange(e) {
        setSelectedOption(e.target.value)
    };


    function handleInputChange(e) {
        setInputValue(e.target.value);
    };

    function updateFilteredRiesgos() {
        const input1 = inputValue.toLowerCase().replace(/\s/g, '');

        const nuevosRiesgos = riesgos1.map((riesgo) => {
            const riesgoNombre = riesgo.name.toLowerCase().replace(/\s/g, '');
            return {
                ...riesgo,
                visible: riesgoNombre.includes(input1),
            };
        });
        setFiltered(nuevosRiesgos);
    };

    useEffect(() => {
        updateFilteredRiesgos()
    }, [inputValue, riesgos1, selectedOption])

    function borrar() {
        setInputValue('');
        const riesgosCero = riesgos1.map((riesgo) => ({
            ...riesgo,
            active: false,
        }));
        setFiltered(riesgosCero);
        setRiesgos(riesgosCero);
    };

    function selectedOptionSistemicos(selected) {
        if (selectedOption === 'graficas') {
            return (
                <div><Divider orientation="left" style={{ marginTop: 0, marginBottom: 22 }}>Riesgos Sistémicos</Divider>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div className='scrollable-column' >
                            <Space>
                                <Space.Compact>
                                    <Input
                                        placeholder="Buscar riesgo..."
                                        value={inputValue}
                                        onChange={handleInputChange}
                                    />
                                </Space.Compact>
                            </Space>
                            <div className='riesgos'>{items}</div>
                        </div>
                        <div className='grafica'>
                            {series.length >= 1 ? (
                                <>
                                    <div className='div-botonera'>
                                        {botonera}
                                    </div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={{
                                            series,
                                            yAxis,
                                            accessibility: {
                                                enabled: true
                                            },
                                            xAxis: {
                                                categories: ['2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
                                            },
                                            title: { text: '' },
                                            credits: { enabled: false },
                                            legend: {
                                                labelFormatter: function () {
                                                    const riesgo = riesgos1.find(riesgo => riesgo.name === this.name);
                                                    if (riesgo && riesgo.unidad) {
                                                        return this.name + ' (' + riesgo.unidad + ')';
                                                    }
                                                    return this.name;
                                                },
                                            },
                                        }} />
                                </>
                            ) : (
                                <Result
                                    icon={null}
                                    title=""
                                    subTitle="Seleccione un riesgo sistémico para acceder a su gráfica."
                                    style={{ margin: 'auto' }}
                                />
                            )}
                        </div>
                        <div onClick={borrar} className='restablecerRiesgos' style={{ position: 'absolute', top: 75, right: 10, fontSize: '12px' }}>
                            {equisIcon} {equis}</div>
                    </div>
                    <div style={{ fontSize: 12 }}>
                        <br />
                        <p>
                            <b style={{ fontWeight: 'bold' }}>CONTEXTO DE CICLO INMOBILIARIO ACTUAL</b>
                            <br />
                            Las siguientes 3 gráficas aportan información muy relevante sobre el comportamiento del sector inmobiliario.
                            <br />
                            <b style={{ fontWeight: 'bold' }}>1.- Evolución del precio unitario (Eur/m2):</b>El precio en periodo de desaceleración y contracción tarda años en reflejar la realidad económica e inmobiliaria. Esto ha supuesto importes pérdidas en todas las crisis. Por lo que es conveniente para saber si está subiendo por reflejo de la economía o no, poder compararlo con indicadores con el económico (PIB) y de compraventa de viviendas.
                            <br />
                            <b style={{ fontWeight: 'bold' }}>2.- Comportamiento de la economía (PIB):</b> Refleja el comportamiento de la economía, dato muy relevante ya que cuanto el PIB baja del entorno 1,5% habitualmente conlleva la ralentización del sector inmobiliario.
                            <br />
                            <b style={{ fontWeight: 'bold' }}>3.- Compraventa de viviendas totales:</b> Refleja el comportamiento del sector inmobiliario, cuando las compraventas suben la evolución del mercado es positiva y cuando bajan negativa.
                            <br />
                            Cuando se marque otra gráfica que desaparezca el texto.
                        </p>
                    </div>
                </div >
            )
        }
        else if (selectedOption === 'tabla') {
            return (<div><Divider orientation="left" style={{ marginTop: 0, marginBottom: 22 }}>Riesgos Sistémicos</Divider><div><TablaRiesgos /></div></div>)
        }
        else {
            return (<div>
                <Divider orientation="left" style={{ marginTop: 0, marginBottom: 22 }}>Ciclo Inmobiliario Local (CIL - VELTIS)</Divider>
                <center><img src={img1} style={{ width: '70%' }}></img></center>
                <Divider orientation="left" style={{ marginTop: 0, marginBottom: 22 }}>Nota Ritmo Inmobiliario</Divider>
                <center><img src={img2} style={{ width: '90%' }}></img></center>
            </div>)
        }
    }

    const abrirNuevaPestaña = () => {
        window.open('https://terminal.veltisrating.com:8888/api/view_pdf_sistemicos/' + dataa.data[0].full_refcat, '_blank');
    };

    return (
        <div style={{ paddingBottom: 50 }}>
            <div style={{ display: 'flex', justifyContent: 'end', marginRight: 10, marginBottom: 10 }}>
                <Button type="primary" onClick={abrirNuevaPestaña}>
                    Abrir informe (R. Sistémicos)
                </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', marginRight: '10px' }}>
                <Radio.Group onChange={handleOptionChange} value={selectedOption}>
                    <Radio.Button value="ciclo_inm">Ciclo Inmobiliario</Radio.Button>
                    <Radio.Button value="graficas">Gráficas</Radio.Button>
                    <Radio.Button value="tabla">Tabla</Radio.Button>
                </Radio.Group>
            </div>

            <p>
                {selectedOptionSistemicos()}
            </p >
        </div >
    );
};

export default RiesgosSistemicos2;