import { Button, Checkbox, Col, Form, Input, Row, message } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import { requestPost } from '../../hooks/Request/Request';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Registro = ({ changeEstado }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Cuenta creada con éxito',
      style: {
        marginTop: '12vh',
      },
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Error en el registro',
      style: {
        marginTop: '12vh',
      },
    });
  };


  async function usuarioRegistro() {
    try {
      const values = await form.validateFields();
      const response = await requestPost('https://terminal.veltisrating.com:8888/api/register', values);
      if (response.response === 200) {
        success()
      } else {
        warning()
      }
    } catch (error) {
      console.error('Error en el formulario:', error);
    }
  };


  return (
    <>
      {contextHolder}
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        size='small'
        style={{
          maxWidth: 750,
          marginLeft: 0
        }}
      >
        <Form.Item
          name="email"
          label="E-mail"
          size='small'
          autoComplete='off'
          rules={[
            {
              type: 'email',
              message: 'Por favor, inserte un email válido',
            },
            {
              required: true,
              message: 'Por favor, introduzca su email',
            },
          ]}
        >
          <Input style={{ fontSize: 18, marginLeft: 10, width: 300 }} />
        </Form.Item>

        <Form.Item
          name="password"
          label="Contraseña"
          rules={[
            {
              required: true,
              message: 'Por favor, introduzca la contraseña',
            },
          ]}
          hasFeedback
        >
          <Input.Password style={{ fontSize: 18, marginLeft: 10, width: 300 }} />
        </Form.Item>

        <Form.Item
          name="confirm"
          label='Repetir contraseña'
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Confirme su contraseña',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Las contraseñas no coinciden'));
              },
            }),
          ]}
        >
          <Input.Password style={{ fontSize: 18, marginLeft: 10, width: 300 }} />
        </Form.Item>

        <Form.Item
          name="nombre_empresa"
          label="Empresa"
          rules={[
            {
              required: true,
              message: 'Introduzca el nombre de su empresa',
            },
          ]}
        >
          <Input style={{ fontSize: 18, marginLeft: 10, width: 300 }} />
        </Form.Item>

        <br></br>

        <Form.Item >
          <Row gutter={0}>
            <Col span={14}>
              <Form.Item
                name="captcha"
                rules={[
                  {
                    required: true,
                    message: 'Confirme que es humano',
                  },
                ]}
              >
                <ReCAPTCHA
                  sitekey="6LevFc4mAAAAANQGWhVUkZ26pi014cVCKcAGfjQI"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Acepte las condiciones')),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox
            rules={[
              {
                required: true,
                message: 'Confirme que es humano',
              },
            ]}>
            He leído los <a href="">términos y condiciones</a>
          </Checkbox>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <div className='botonesRegistro'>
            <Button type="primary" onClick={usuarioRegistro} className='registrar'>
              Registrar
            </Button>
            <button type="button" onClick={changeEstado} className="registro-button">Ya estoy registrado</button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default Registro;

