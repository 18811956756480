import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
function BarraSeleccion({ selectedFilters, setSelectedFilters }) {
    function handleClick(e) {
        const filtradotmp = [...selectedFilters];
        for (let i = 0; i < filtradotmp.length; i++) {
            const index = filtradotmp[i].elementos.findIndex((objeto) => objeto.id === Number(e.id));
            if (filtradotmp[i].elementos[index] !== undefined) {
                filtradotmp[i].elementos[index].activated === true ? filtradotmp[i].elementos[index].activated = false : void (0);
            }
        }
        setSelectedFilters(filtradotmp);
    }
    const filtros = [...selectedFilters];
    const filtrado = filtros.map((item) => {
        return item.elementos.forEach((element) => {
            if (element.activated === true) {
                return (
                    <Button key={element.id} size={'small'} onClick={() => handleClick(element)} >{element.name} <CloseOutlined /></Button>
                )
            }
        });
    });
    return (
        <div style={{ padding: '3px', border: '1px solid #d9d9d9', borderRadius: '5px', width: '100%', display: 'flex', alignItems: 'center', gap: '3px', flexWrap: 'wrap', maxWidth: '100%', overflow: 'none' }}>
            {filtrado}
        </div>
    )
}
export default BarraSeleccion;