import { Button, Drawer, Tooltip } from 'antd';
import { useState } from 'react';
import './AfeccionDrawer.css'
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import ContenidoDrawer from './ContenidoDrawer';

const AfeccionDrawer = ({ setoverSelectedItem, overSelectedItem, setCapas }) => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(!open);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title={open ? '' : "Afecciones"} placement="bottom">
                <Button className={open ? 'boton' : 'boton2'} type="primary" onClick={showDrawer}>
                    {open ? <DownOutlined /> : <UpOutlined />}
                </Button>
            </Tooltip>
            <Drawer title='Afecciones'
                placement="left"
                onClose={onClose}
                open={open}
                width={'570'}
                style={{ position: 'absolute', marginTop: 78 }}
                mask={false}
                className='drawer'
            >
                <ContenidoDrawer setoverSelectedItem={setoverSelectedItem} overSelectedItem={overSelectedItem} setCapas={setCapas}></ContenidoDrawer>
            </Drawer>
        </>
    );
};

export default AfeccionDrawer;