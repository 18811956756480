import React from 'react';
import { useParams } from "react-router-dom";

const About = () => {
    const params = useParams();

    return (
        <div>
            <h1>This is the About page {params.topicId}</h1>
            <p>
                <a href="/about/33">LinkedIn handle</a>
            </p>
        </div>
    )
}

export default About