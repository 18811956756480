import './ValoracionActivos.css'
import { BsFillHouseFill, BsHouseCheckFill, BsFillBuildingFill, BsBuildingGear, BsBuildingFillGear, BsFillTreeFill } from 'react-icons/bs';
import { FaToilet, FaSwimmingPool, FaBuilding } from 'react-icons/fa';
import { MdOutlineBedroomChild, MdSevereCold, MdSportsFootball, MdHighQuality, MdOutlineElevator } from 'react-icons/md';
import { GiHeatHaze } from 'react-icons/gi';

const ContentTabla = ({ selectedOption, dataa }) => {

    const item = dataa.data[0]
    var valorMercado = item['V.MERCADO'];
    var valorSeguro = item['V.SEGURO'];

    const datos = [
        { id: 1, nombre: 'Valor de tasación', descripcion:new Intl.NumberFormat('en-DE').format(Math.trunc(valorMercado)), uni: '€', icon: <FaBuilding size={20} />, category: 'tasacion' },
        { id: 2, nombre: 'Valor de seguro', descripcion: new Intl.NumberFormat('en-DE').format(Math.trunc(valorSeguro)), uni: '€', icon: <FaBuilding size={20} />, category: 'tasacion' },
        { id: 3, nombre: 'Valor desglosado', descripcion: 'N/A', uni: null, icon: <FaBuilding size={20} />, category: 'tasacion' },
        { id: 4, nombre: 'Valor unitario de tasación', descripcion: new Intl.NumberFormat('en-DE').format(Math.trunc(item.VALUNTAS)), uni: '€', icon: <FaBuilding size={20} />, category: 'tasacion' },
        { id: 5, nombre: 'Fecha de emisión', descripcion: item.FECHA, uni: null, icon: <FaBuilding size={20} />, category: 'tasacion' },
        { id: 6, nombre: 'Finalidad de la tasación', descripcion: item.FINALIDAD, uni: null, icon: <FaBuilding size={20} />, category: 'tasacion' },
        { id: 7, nombre: 'Visitado', descripcion: item.VISITADO, uni: null, icon: <FaBuilding size={20} />, category: 'tasacion' },
        { id: 8, nombre: 'Valoración', descripcion: new Intl.NumberFormat('en-DE').format(Math.trunc(valorMercado)), uni: '€', icon: <FaBuilding size={20} />, category: 'valoracion' },
        { id: 9, nombre: 'Fecha de valoración', descripcion: 'N/A', uni: null, icon: <FaBuilding size={20} />, category: 'valoracion' },
        { id: 10, nombre: 'Valor unitario(€/m2)', descripcion: 'N/A', uni: null, icon: <FaBuilding size={20} />, category: 'valoracion' },
        { id: 11, nombre: '% Variación desde incorporación a la cartera', descripcion: 'N/A', uni: null, icon: <FaBuilding size={20} />, category: 'valoracion' },
        { id: 12, nombre: 'Renta de alquiler esperada', descripcion: 'N/A', uni: null, icon: <FaBuilding size={20} />, category: 'valoracion' },
        { id: 13, nombre: 'Rentabilidad media esperada', descripcion: 'N/A', uni: null, icon: <FaBuilding size={20} />, category: 'valoracion' },
        { id: 14, nombre: 'Calificación de Eficiencia Energética', descripcion: 'N/A', uni: null, icon: <FaBuilding size={20} />, category: 'inmueble' },
        { id: 15, nombre: 'Fecha del CEE', descripcion: 'N/A', uni: null, icon: <BsFillBuildingFill size={20} />, category: 'inmueble' },
        { id: 16, nombre: 'Año rehabilitación parcial', descripcion: item.ANOREHABIP, uni: null, icon: <BsBuildingGear size={20} />, category: 'inmueble' },
        { id: 17, nombre: 'Año rehabilitación total', descripcion: item.ANOREHABIL, uni: null, icon: <BsBuildingFillGear size={20} />, category: 'inmueble' },
        { id: 18, nombre: 'Superficie construida', descripcion: item.CONSTPRIVA, uni: `m²`, icon: <BsFillHouseFill size={20} />, category: 'inmueble' },
        { id: 19, nombre: 'Superficie construida con comunes', descripcion: item.CONSTPP, uni: `m²`, icon: <BsFillHouseFill size={20} />, category: 'inmueble' },
        { id: 20, nombre: 'Superficie útil', descripcion: item.UTIL, uni: `m²`, icon: <BsHouseCheckFill size={20} />, category: 'inmueble' },
        { id: 21, nombre: 'Número de baños', descripcion: item.NUMBANOS1, uni: null, icon: <FaToilet size={20} />, category: 'inmueble' },
        { id: 22, nombre: 'Número de dormitorios', descripcion: item.NUMDORMI1, uni: null, icon: <MdOutlineBedroomChild size={20} />, category: 'inmueble' },
        { id: 23, nombre: 'Tipo Calefacción', descripcion: item.TIPOCALEF, uni: null, icon: <GiHeatHaze size={20} />, category: 'inmueble' },
        { id: 24, nombre: 'Aire acondicionado', descripcion: item.AIREACOND, uni: null, icon: <MdSevereCold size={20} />, category: 'inmueble' },
        { id: 25, nombre: 'Calidades constructivas', descripcion: item.CALIDADCON, uni: null, icon: <MdHighQuality size={20} />, category: 'inmueble' },
        { id: 26, nombre: 'Nº de planta (del inmueble)', descripcion: item.ALTURAPLAN, uni: null, icon: <FaBuilding size={20} />, category: 'inmueble' },
        { id: 27, nombre: 'Año construcción', descripcion: item.ANOCONSTRU, uni: null, icon: <BsFillBuildingFill size={20} />, category: 'edificio' },
        { id: 28, nombre: 'Número de plantas totales', descripcion: item.ALTURA, uni: null, icon: <BsFillBuildingFill size={20} />, category: 'edificio' },
        { id: 29, nombre: 'Zona Verde', descripcion: item.ZONAVERDE, uni: null, icon: <BsFillTreeFill size={20} />, category: 'edificio' },
        { id: 30, nombre: 'Piscina', descripcion: item.PISCINA, uni: null, icon: <FaSwimmingPool size={20} />, category: 'edificio' },
        { id: 31, nombre: 'Zona deportiva', descripcion: item.ZONADEPOR, uni: null, icon: <MdSportsFootball size={20} />, category: 'edificio' },
        { id: 32, nombre: 'Ascensores', descripcion: item.ASCENSORES, uni: null, icon: <MdOutlineElevator size={20} />, category: 'edificio' },
    ];

    function formatearFecha(fecha) {
        const fechaObj = new Date(fecha);
        const dia = fechaObj.getDate().toString().padStart(2, '0'); // Día con dos dígitos
        const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0'); // Mes con dos dígitos
        const año = fechaObj.getFullYear().toString();
        return `${dia}/${mes}/${año}`;
    }
    
    const tasacion = datos.filter((item) => item.category === 'tasacion')
    const valoracion = datos.filter((item) => item.category === 'valoracion')
    const inmueble = datos.filter((item) => item.category === 'inmueble')
    const edificio = datos.filter((item) => item.category === 'edificio')


    const verTasacion = tasacion.map((itema) => {
        let descripcion = itema.descripcion;
        if (descripcion === '01') {
            descripcion = 'SI';
        } else if (descripcion === '02') {
            descripcion = 'NO';
        } else if (descripcion === null) {
            descripcion = 'N/A';
        } else if (!isNaN(descripcion)) {
            descripcion = descripcion;
        } else if(itema.nombre.includes('Fecha')){
            descripcion = formatearFecha(descripcion)
        }
        if (itema.uni !== null) {
            descripcion += ' ' + itema.uni;
        } 
        return (
            <ul className='todoTabla' key={itema.id}>
                <li className='dataTabla'><div className='icon'>{itema.icon}</div> {itema.nombre}: {descripcion !== null && descripcion !== "" ? descripcion : 'N/A'}</li>
            </ul>
        )
    })
    
    const verValoracion = valoracion.map((itema) => {
        let descripcion = itema.descripcion;
        if (descripcion === '01') {
            descripcion = 'SI';
        } else if (descripcion === '02') {
            descripcion = 'NO';
        } else if (descripcion === null) {
            descripcion = 'N/A';
        } else if (!isNaN(descripcion)) {
            descripcion = descripcion;
        }
        if (itema.uni !== null) {
            descripcion += ' ' + itema.uni;
        }
        return (
            <ul className='todoTabla' key={itema.id}>
                <li className='dataTabla'><div className='icon'>{itema.icon}</div> {itema.nombre}: {descripcion !== null && descripcion !== "" ? descripcion : 'N/A'}</li>
            </ul>
        )
    })
    
    const verInmueble = inmueble.map((itema) => {
        let descripcion = itema.descripcion;
        if (descripcion === '01') {
            descripcion = 'SI';
        } else if (descripcion === '02') {
            descripcion = 'NO';
        } else if (descripcion === null) {
            descripcion = 'N/A';
        } else if (!isNaN(descripcion) && descripcion.includes('.')) {
            descripcion = Math.round(parseFloat(descripcion));
        } else if(!isNaN(descripcion) && itema.nombre.includes('Fecha')){
            descripcion = formatearFecha(descripcion)
        }
        if (itema.uni !== null) {
            descripcion += ' ' + itema.uni;
        }
        return (
            <ul className='todoTabla' key={itema.id}>
                <li className='dataTabla'><div className='icon'>{itema.icon}</div> {itema.nombre}: {descripcion !== null && descripcion !== "" ? descripcion : 'N/A'}</li>
            </ul>
        )
    })
    
    const verEdificio = edificio.map((itema) => {
        let descripcion = itema.descripcion;
        if (descripcion === '01') {
            descripcion = 'SI';
        } else if (descripcion === '02') {
            descripcion = 'NO';
        } else if (descripcion === null) {
            descripcion = 'N/A';
        } else if (!isNaN(descripcion) && descripcion.includes('.')) {
            descripcion = Math.round(parseFloat(descripcion));
        }
        if (itema.uni !== null) {
            descripcion += ' ' + itema.uni;
        }
        return (
            <ul className='todoTabla' key={itema.id}>
                <li className='dataTabla'><div className='icon'>{itema.icon}</div> {itema.nombre}: {descripcion !== null && descripcion !== "" ? descripcion : 'N/A'}</li>
            </ul>
        )
    })
    
    


    return (
        <div>
            {selectedOption === 'valoracion' ?
                <div>
                    <h2>Datos de tasación:</h2>
                    <br />
                    <div className='grid'>
                        {verTasacion}
                    </div>
                    <h2>Datos de la Valoración Automática:</h2>
                    <br />
                    <div className='grid'>
                        {verValoracion}
                    </div>
                </div>
                :
                <div>
                    <h2>Datos del inmueble:</h2>
                    <br />
                    <div className='grid'>
                        {verInmueble}
                    </div>
                    <h2>Datos del edificio:</h2>
                    <br />
                    <div className='grid'>
                        {verEdificio}
                    </div>
                </div>
            }
        </div>

    )

}
export default ContentTabla;