import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import './Grid.css';
import { RatingData } from '../../hooks/Rating/Rating';
import { Tag } from 'antd';
import { FundProjectionScreenOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';




const rendererRating = (params) => {
    const rating = RatingData(params.value);
    if (rating !== null) {
        return (
            <Tag color={`#${rating.color}`} style={{ color: 'white', width: 36, height: '100%', textAlign: 'center' }}>
                <div style={{ position: 'relative', top: -2 }}>
                    {rating.valorRating}
                </div>
            </Tag>
        )
    }
    else {
        return ("");
    }
}

const rendererPrimeraColumna = (params) => {
    return <div style={{ display: 'inline-flex', cursor: 'pointer' }}>{params.value} <div style={{ position: 'absolute', right: 10, cursor: 'pointer' }}><FundProjectionScreenOutlined /></div></div>;
}

const original = [
    { field: 'full_refcat', headerName: 'Ref. Catastral', headerCheckboxSelection: true, checkboxSelection: true, minWidth: 210, width: 210, pinned: 'left', suppressMovable: true, resizable: false, sortable: false, cellClass: 'primera_columna', cellRenderer: rendererPrimeraColumna },
    { field: 'rating_parcela', headerName: 'Rating Activo', minWidth: 75, width: 75, pinned: 'right', suppressMovable: true, resizable: false, cellClass: 'mi-clase', cellRenderer: rendererRating },
];

const Grid = ({ setACtivoSeletected, setactivosInGrid, filtros, setFiltros, carteras, usestateListaFiltros, leyendaElegida }) => {

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 10px)', width: '100%' }), []);
    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState(original);
    const [rowData, setRowData] = useState([]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            sortable: true,
            lockPinned: true,
            resizable: true,
            suppressAutoSize: true,
            floatingFilter: false,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
        };
    }, []);

    useEffect(() => {
        let anyAdded = false;
        const newArrat = [...original];
        for (const categoriaFiltro of filtros) {
            for (const Filtroo of categoriaFiltro.elementos) {
                if (Filtroo.activated) {
                    newArrat.push(Filtroo.grid);
                    anyAdded = true;
                }
            }
        }
        if (anyAdded) {
            setColumnDefs(newArrat)
        }
    }, [filtros])


    useEffect(() => {
        if( carteras.length > 0 )  getData();
    }, [carteras, usestateListaFiltros])

    const localeText = {
        // for filter panel
        page: 'Pagina',
        more: 'Mas',
        to: 'a',
        of: 'de',
        next: 'Siguente',
        last: 'Último',
        first: 'Primero',
        previous: 'Anteror',
        loadingOoo: 'Cargando...',

        // for set filter
        selectAll: 'Seleccionar Todo',
        searchOoo: 'Buscar...',
        blanks: 'En blanco',

        // for number filter and text filter
        filterOoo: 'Filtrar',
        applyFilter: 'Aplicar Filtro...',
        equals: 'Igual',
        notEqual: 'No Igual',

        // for number filter
        lessThan: 'Menos que',
        greaterThan: 'Mayor que',
        lessThanOrEqual: 'Menos o igual que',
        greaterThanOrEqual: 'Mayor o igual que',
        inRange: 'En rango de',

        // for text filter
        contains: 'Contiene',
        notContains: 'No contiene',
        startsWith: 'Empieza con',
        endsWith: 'Termina con',

        // filter conditions
        andCondition: 'Y',
        orCondition: 'O',

        // the header of the default group column
        group: 'Grupo',

        // tool panel
        columns: 'Columnas',
        filters: 'Filtros',
        valueColumns: 'Valos de las Columnas',
        pivotMode: 'Modo Pivote',
        groups: 'Grupos',
        values: 'Valores',
        pivots: 'Pivotes',
        toolPanelButton: 'BotonDelPanelDeHerramientas',

        // other
        noRowsToShow: 'No hay filas para mostrar',

        // enterprise menu
        pinColumn: 'Columna Pin',
        valueAggregation: 'Agregar valor',
        autosizeThiscolumn: 'Autoajustar esta columna',
        autosizeAllColumns: 'Ajustar todas las columnas',
        groupBy: 'Agrupar',
        ungroupBy: 'Desagrupar',
        resetColumns: 'Reiniciar Columnas',
        expandAll: 'Expandir todo',
        collapseAll: 'Colapsar todo',
        toolPanel: 'Panel de Herramientas',
        export: 'Exportar',
        csvExport: 'Exportar a CSV',
        excelExport: 'Exportar a Excel (.xlsx)',
        excelXmlExport: 'Exportar a Excel (.xml)',


        // enterprise menu pinning
        pinLeft: 'Pin Izquierdo',
        pinRight: 'Pin Derecho',


        // enterprise menu aggregation and status bar
        sum: 'Suman',
        min: 'Minimo',
        max: 'Maximo',
        none: 'Nada',
        count: 'Contar',
        average: 'Promedio',
        avg: 'Promedio',

        // standard menu
        copy: 'Copiar',
        copyWithHeaders: 'Copiar con cabeceras',
        paste: 'Pegar',

    };

    const getData = () => {
        const carterasSelected = [];
        const filtrosActivos = [];
        for (const iterator of carteras) {
            if (iterator.checked) carterasSelected.push(iterator.cartera_id);
        }

        for (const cats of usestateListaFiltros) {
            for (const filindicadores of cats.subcategorias) {
                for (const indicadorr of filindicadores.subcatindicador) {
                    for (const indicadorselect of indicadorr.indicadorfiltros) {
                        if (indicadorselect.selected && indicadorselect.nombre !== '-' && indicadorselect.nombre !== 'min' && indicadorselect.nombre !== 'max') {
                            indicadorselect.indicadorsrc = indicadorr.indicadorsrc;
                            filtrosActivos.push(indicadorselect)
                        }
                    }
                }
            }
        }
        //setactivosInGrid([]);
        if (gridRef !== null && gridRef.current !== null && gridRef.current.api !== null && gridRef.current.api !== undefined) gridRef.current.api.setRowData([]);

        const token = Cookies.get('token');
        fetch('https://terminal.veltisrating.com:8888/api/query_griddata', {
            method: 'post',
            body: JSON.stringify({
                startRow: 0,
                endRow: 3000,
                rowGroupCols: [],
                valueCols: [],
                pivotCols: [],
                pivotMode: false,
                groupKeys: [],
                filterModel: {},
                sortModel: [],
                carterasSeletected: carterasSelected,
                filtrosActivos: filtrosActivos,
                leyendaElegida: leyendaElegida
            }),
            headers: { 'Content-Type': 'application/json; charset=utf-8', 'authorization': token }
        })
            .then((response) => response.json())
            .then((data) => {
                setRowData(data.rows)
                const retorno = [];
                for (const iterator of data.rows) {
                    retorno.push(iterator)
                }
                setactivosInGrid(retorno);
                /*gridRef.current.api.setRowData(data.rows)*/
            }
            );
    }


    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setACtivoSeletected(selectedRows);
    }, []);

    const onSelectionRangedChanged = useCallback(() => {
        // Obtener la instancia de AG-Grid
        var gridOptions = gridRef.current.api;

    }, []);





    const onGridReady = useCallback((params) => {
        const { api } = params;
        //const gridOptions = api.gridOptions;
        //getData();
        //params.api.setServerSideDatasource(datasource);
    }, []);


    const sidebar = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columnas',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                minWidth: 225,
                width: 225,
                maxWidth: 225,
            }
        ],
        position: 'right',
        defaultToolPanel: '',
    };

    return (
        <div className="ag-theme-balham" style={gridStyle}>
            <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                getRowId={params => params.data.full_refcat}
                defaultColDef={defaultColDef}
                localeText={localeText}
                //rowModelType={'serverSide'}
                rowData={rowData}
                onGridReady={onGridReady}
                rowSelection={'multiple'}
                onSelectionChanged={onSelectionChanged}
                onRangeSelectionChanged={onSelectionRangedChanged}
                rowHeight={20}
                groupHeaderHeight={75}
                headerHeight={55}
                floatingFiltersHeight={30}
                pivotGroupHeaderHeight={50}
                pivotHeaderHeight={100}
                sideBar={sidebar}
                rowGroupPanelShow={'always'}
                enableCharts={true}
                enableRangeSelection={true}
                enableRangeHandle={true}
                suppressPinning={true}
            >
            </AgGridReact>
        </div >
    );
}

export default Grid