
import Cookies from 'js-cookie';

const requestPost = async (url, dataPost, signal) => {
    const token = Cookies.get('token');
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'authorization': token },
        body: JSON.stringify(dataPost),
        signal: signal,
    };
    const response = await fetch(url, requestOptions).then(response => response.json());
    return (response);
};

const RequestGet = async (url, signal) => {
    const token = Cookies.get('token');
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'authorization': token },
        signal: signal,
    };
    const response = await fetch(url, requestOptions).then(response => response.json());

    return (response);
};

async function RequestDelete (url, dataPost, signal) {
    const token = Cookies.get('token');
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'authorization': token },
        body: JSON.stringify(dataPost),
        signal: signal,
    };
    const response = await fetch(url, requestOptions).then(response => response.json());
    return (response);
}

/**
 * Para cancelar las request basta con, en el componente que se quiera poder cancelar, añadir las siguientes lineas:
 * 
 * const controller = new AbortController()
 * const signal = controller.signal
 * 
 * y en el en el useEffect para que se cancele al cargar una nueva:
 * 
 * return () => controller.abort()
 */

export { requestPost, RequestGet, RequestDelete };
