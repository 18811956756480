import { Tag } from "antd";
import { CEEData } from "../Rating/Rating";

export const cellStyleInteger = (params) => {
    if (params.value >= 0) return { color: 'green' };
    else return { color: 'red' };
}

export const cellStyleTaxonomia = (params) => {
    if (parseInt(params.value) === 2) return { color: 'green' };
    if (parseInt(params.value) === 1) return { color: 'orange' };
    if (parseInt(params.value) === 0) return { color: 'red' };
    else return { color: 'red' };
}

export const rendererCalidad = (params) => {
    switch (Number(params.value)) {
        case 1: return 'Muy Alta'; 
        case 2: return 'Alta'; 
        case 3: return 'Media Alta'; 
        case 4: return 'Media'; 
        case 5: return 'Media Baja'; 
        case 6: return 'Baja'; 
        case 7: return 'Muy Baja'; 
        default: return 'Otra'; 
    }
}

export const rendererTaxonomia = (params) => {
    switch (Number(params.value)) {
        case 2: return 'Verde'; 
        case 1: return 'Sostenible'; 
        case 0: return 'No sostenible'; 
        default: return 'N/A'; 
    }
}

export const numberFormatter = (params) => {
    
    return Number(parseFloat(params.value).toFixed(2))
}

export const rendererCEE = (params) => {
    const rating = CEEData(params.value);
    if (rating !== null) {
        return (
            <Tag color={`#${rating.color}`} style={{ color: 'white', width: 36, height: '100%', textAlign: 'center' }}>
                <div style={{ position: 'relative', top: -2 }}>
                    {rating.valorRating}
                </div>
            </Tag>
        )
    }
    else {
        return ("");
    }
}
