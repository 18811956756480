import { NavLink, Outlet } from "react-router-dom";
import Layer2 from "../view/pages/Main/Layer2";
import Layer1 from "../view/pages/Main/Layer1";

export default function Layout({setACtivoSeletected, carteras, setCarteras, userName, userData}) {
    <li><NavLink to="/">Home</NavLink></li>
    return (
        <div id="__nuxt">
            <div id="__layout">
                <section className="el-container app-wrapper purge-css-ignore gf keep-all bg-neutral-10 is-vertical">
                    <header id="header" className="el-header" style={{ height: 'auto', textAlign: 'start', fontSize: '12px' }}>
                        <Layer1 userName={userName} userData={userData} />
                        <Layer2 setACtivoSeletected={setACtivoSeletected} carteras={carteras} setCarteras={setCarteras} />
                    </header>
                    <Outlet />
                    <div id="myModal" className="modal">
                        <img id="modalImg" className="modal-content" alt="modalImg"/>
                        <div id="modalCaption" />
                    </div>
                    <div className="el-dialog__wrapper" style={{ display: 'none' }}>
                        <div role="dialog" aria-modal="true" aria-label="Create Portfolio" className="el-dialog gf" style={{ marginTop: '15vh' }}>
                            <div className="el-dialog__header">
                                <span className="el-dialog__title">Create Portfolio</span>
                                <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                    <i className="el-dialog__close el-icon el-icon-close" />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}