import React, { useEffect, useState } from 'react';
import { Button, Modal, Collapse, Input, message, Popconfirm, Tooltip } from 'antd';
import { QuestionCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import Wallet from '../../../components/Wallet/Wallet';
import Buscador from '../../../components/Buscador/Buscador';
import { RequestGet, requestPost } from '../../../hooks/Request/Request';
import { fetchCarteras } from '../../../hooks/Carteras/Carteras';
const { Panel } = Collapse;
const { TextArea } = Input;



const Layer2 = ({ setACtivoSeletected, carteras, setCarteras }) => {

    // constantes del modal
    const [modal, setModal] = useState(false);
    function abrirModal() {
        setModal(true);
    }
    function cerrarModal() {
        setModal(false);
    }

    // constantes del text area
    const [textareaValue, setTextareaValue] = useState('');
    const [isNotValid, setIsNotValid] = useState(true);

    const handleTextareaChange = (event) => {
        //const str = event.target.value.replace(/(?:\r\n|\r|\n)/g, '\n');

        /*setTextareaValue(String(event.target.value));
        const regex = /.*[^;]$/;
        const newText = event.target.value;
        setIsNotValid(!regex.test(newText));*/
        setTextareaValue(String(event.target.value));
    };

    const [textareaMod, setTextareaMod] = useState('');
    const handleTextareaChangeMod = (event) => {
        setTextareaMod(String(event.target.value));
    };


    // funcion para tomar el valor del input como nombre
    const [nombre, setNombre] = useState('');
    function handleNombreChange(e) {
        setNombre(e.target.value);
    }

    // funcion para añadir la cartera
    async function addWallet() {
        let str = textareaValue.split("\n");
        if (str[0] === "") str = [];
        const result = await requestPost(
            'https://terminal.veltisrating.com:8888/api/addUserCartera',
            { nombre_cartera: nombre, activos_cartera: str }
        )
        const carterastmp = [...carteras];
        carterastmp.push(
            {
                id: result.data[0].cartera_id, cartera_id: result.data[0].cartera_id, name: nombre, checked: false
            }
        )
        setCarteras(carterastmp);
        message.success(`Cartera añadida con exito`);
        fetchCarteras(carteras, setCarteras);
    }

    // constantes del PopConfirm para borrar carteras
    const confirm = async () => {
        const carterastmp = [...carteras];
        for (let index = 0; index < carterastmp.length; index++) {
            const element = carterastmp[index];
            if (element.checked === true) {
                carterastmp.splice(index, 1);
                index = index - 1;
                const result = requestPost(
                    'https://terminal.veltisrating.com:8888/api/removeUserCartera',
                    { id_cartera: element.cartera_id }
                )
            }
        }
        if (carterastmp.length === carteras.length) {
            message.error('error al borrar registro');
        } else {
            message.success(`registro borrado con exito`);
        }
        setCarteras(carterastmp);
        fetchCarteras(carteras, setCarteras);
    };

    const cancel = () => {
    };

    // primer fetch que trae las carteras de la bbdd
    useEffect(() => {
        // declare the async data fetching function
        async function fetchCarterasFirst() {
            // get the data from the api
            const data = await RequestGet('https://terminal.veltisrating.com:8888/api/getUserCarteras');
            // convert data to json
            //const json = await data.json();
            const arrCarteras = [];
            let i = 0;
            for (const iterator of data.data) {
                arrCarteras.push({
                    id: iterator.cartera_id,
                    cartera_id: iterator.cartera_id,
                    name: iterator.cartera_title,
                    cartera_cnt_activos: iterator.cartera_cnt_activos,
                    cartera_valor_activos: iterator.cartera_valor_activos,
                    cartera_avg_rating_activos: iterator.cartera_avg_rating_activos,
                    cartera_avg_var_activos: iterator.cartera_avg_var_activos,
                    checked: i === 0 ? true : false
                });
                ++i;
            }
            setCarteras(arrCarteras);
        }
        fetchCarterasFirst();
    }, [])

    const [selectedCartera, setSelectedCartera] = useState(carteras);
    const [modActivated, setModActivated] = useState(false);

    useEffect(() => {
        const arrayTmp = [];
        carteras.forEach((item) => {
            if (item.checked === true) {
                arrayTmp.push(item.name, item.id)
            }
        })
        if (arrayTmp.length > 0) {
            setModActivated(true);
        } else if (arrayTmp.length === 0) {
            setModActivated(false);
        }
        setSelectedCartera(arrayTmp);
        setCambioNombre(arrayTmp[0]);

    }, [carteras])

    // state que controla el nombre modificado de la cartera
    const [cambioNombre, setCambioNombre] = useState('');
    function handleNombreMod(e) {
        setCambioNombre(e.target.value);
    }

    // funcion para modificar cartera seleccionada
    const [modNombre, setModNombre] = useState(true);
    useEffect(() => {
        if (cambioNombre !== undefined) {
            if (cambioNombre.trim() === '') {
                setModNombre(true);
            } else {
                setModNombre(false);
            }
        }
    }, [cambioNombre]);

    async function modWallet() {
        const datosCartera = selectedCartera;
        let carteraId = datosCartera[1];
        let str = textareaMod.split("\n");
        if (str[0] === "") str = [];
        if (cambioNombre === '') {
            const arrayTmp = [];
            carteras.forEach((item) => {
                if (item.checked === true) {
                    arrayTmp.push(item.name)
                }
            })
            setCambioNombre(arrayTmp[0]);
        }
        const result = await requestPost(
            'https://terminal.veltisrating.com:8888/api/modUserCartera',
            { id_cartera: carteraId, nombre_cartera: cambioNombre, activos_cartera: str }
        )
        if (result.response === 200) {
            const carterastmp = [...carteras];
            const index = carterastmp.findIndex((objeto) => objeto.id === carteraId);
            carterastmp[index].name = cambioNombre;
            setCarteras(carterastmp);
            message.success(`Cartera modificada con exito`);
        } else {
            message.error('Error al modificar cartera');
        }
        fetchCarteras(carteras, setCarteras);
    }


    return (
        <div id="tags-view" className="tags-view" style={{ paddingLeft: '26.5px', paddingRight: '26.5px' }} data-v-38c8ef2a>
            <div className="tags-view-section hide-on-print" style={{ display: 'flex' }} data-v-38c8ef2a>
                <div style={{ marginLeft: 'auto', order: 2, minWidth: '48px', overflow: 'hidden' }} data-v-38c8ef2a>
                    <div data-v-268811c3 data-v-38c8ef2a className="right-float right-8">
                        <div role="tooltip" id="el-popover-3272" aria-hidden="true" className="el-popover el-popper task-download-popover" tabIndex={0} style={{ display: 'none' }}></div>
                        <span data-v-268811c3 className="tags-view-button el-popover__reference" aria-describedby="el-popover-3272" tabIndex={0} style={{ position: 'relative', display: 'none' }}>
                            <i data-v-268811c3 className="el-icon-download" />
                        </span>
                    </div>

                    <div data-v-38c8ef2a className="right-float hidden-xs-only">
                        <a target="_self" href="#" className="referral-link">
                            <i className="gf-icon-gift" /> Versión BETA</a>
                    </div>

                    {/* ---- modulos de carteras ---- */}
                    <Button type="primary" onClick={abrirModal}>
                        <b>{selectedCartera.length > 0 ? `Cartera: ${selectedCartera[0]}` : 'Carteras'} </b>
                    </Button>
                    <Modal name="Carteras" open={modal} onCancel={cerrarModal} footer={[
                        <div >
                            <Wallet items={carteras} setCarteras={setCarteras} />
                        </div>,
                        <br />,
                        <Collapse>
                            <Panel header="Crear Nueva Cartera" key="1">
                                <label for="fname">Nombre Cartera:</label><br />
                                <Input placeholder="Nombre de la cartera" value={nombre} onChange={handleNombreChange} />
                                <br />
                                {/* modulo para subir archivos, esta sin probar, en principio se podría reutilizar el input de encima para darle nombre al archivo */}
                                {/*<UploadModule name={nombre} />*/}
                                <br />
                                <label for="fcastas">Referencias Castastrales:</label><br />
                                <TextArea
                                    rows={4}
                                    value={textareaValue}
                                    onChange={handleTextareaChange}
                                    placeholder='Introducir referencais castastrales separadas por lineas, ejemplo:&#10;Referencia catastral #1&#10;Referencia catastral #2&#10;Referencia catastral #3' />
                                <br />
                                {!isNotValid && <p style={{ color: "red" }}>Texto no válido, introduzca punto y coma ( ; ) al final de cada referencia</p>}
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '20px' }}>
                                    <Tooltip placement="top" title={'Ejemplo de dato a introducir: \n ReferenciaCatastral (20 digitos) ;'}>
                                        <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                                    </Tooltip>
                                    <Button type='primary' onClick={addWallet}>
                                        Crear Cartera
                                    </Button>
                                </div>
                            </Panel>
                        </Collapse>,
                        <br />,
                        <Collapse collapsible={modActivated === true ? '' : 'disabled'}>
                            <Panel header="Modificar Cartera Seleccionada" key="1">
                                <label for="fname">Nombre Cartera:</label><br />
                                <Input placeholder="Nombre de la cartera" value={cambioNombre} onChange={handleNombreMod} />
                                <br />
                                {/* modulo para subir archivos, esta sin probar, en principio se podría reutilizar el input de encima para darle nombre al archivo */}
                                {/*<UploadModule name={nombre} />*/}
                                <br />
                                <label for="fcastas">Añadir Referencias Castastrales:</label><br />
                                <TextArea
                                    rows={4}
                                    value={textareaMod}
                                    onChange={handleTextareaChangeMod}
                                    placeholder='Introducir referencais castastrales separadas por lineas, ejemplo:&#10;Referencia catastral #1&#10;Referencia catastral #2&#10;Referencia catastral #3' />
                                <br />
                                {!isNotValid && <p style={{ color: "red" }}>Texto no válido, introduzca punto y coma ( ; ) al final de cada referencia</p>}
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '20px' }}>
                                    <Tooltip placement="top" title={'Ejemplo de dato a introducir: \n ReferenciaCatastral (20 digitos) ;'}>
                                        <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                                    </Tooltip>
                                    <Button type='primary' onClick={modWallet} disabled={modNombre}>
                                        Modificar Cartera
                                    </Button>
                                </div>
                            </Panel>
                        </Collapse>,
                        <br />,
                        <div style={{ float: 'right', marginRight: 5, marginLeft: 5, display:'none' }}>
                            <Button type="default" disabled = {!modActivated} icon={<PrinterOutlined />} size={'medium'}>
                            {modActivated ? 
                                <a href={'https://terminal.veltisrating.com/hud/informe_cartera/' + selectedCartera[1]}
                                    target="_blank"
                                    rel="noreferrer">
                                    Imprimir informe
                                </a>
                                :
                                'Imprimir informe'
                            }
                            </Button>
                        </div>,
                        <Popconfirm
                            title="Borrar Carteras"
                            description="¿Esta seguro de borrar las carteras seleccionadas?"
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="Si"
                            cancelText="No"
                            disabled={!modActivated}
                        >
                            <Button danger disabled={!modActivated}> Borrar Cartera</Button>

                        </Popconfirm>

                    ]} />
                </div>
                <div className="search-bar-section" style={{ width: '350px' }} data-v-69d2778f data-v-38c8ef2a>
                    <Buscador setACtivoSeletected={setACtivoSeletected} />

                </div>
                {/*<div data-v-38c8ef2a style={{ flexGrow: '0.1', overflow: 'hidden' }}>
                    <a data-v-38c8ef2a href="/stock/AAPL/summary" className="tags-view-stock-tag">
                        <div className="title t-default fw-bold">AAPL</div>
                        <div className="sub">
                            <span className="color-main-red">▼0.04% </span>
                        </div>
                        <span className="el-icon-close" />
                    </a>
                    <a data-v-38c8ef2a href="/stock/MSFT/summary" className="tags-view-stock-tag">
                        <div className="title t-default fw-bold">MSFT</div>
                        <div className="sub">
                            <span className="color-main-red">▼0.75% </span>
                        </div>
                        <span className="el-icon-close" />
                    </a>
                </div>*/}
            </div>
        </div>
    )
}

export default Layer2