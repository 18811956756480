import { Button, Input, Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import './AddLayer.css';
import Buscador from '../Buscador_filtros/Buscador_filtros';
import Navegacion from '../Navegacion/Navegacion';
import Descripcion from '../Descripcion/Descripcion';


function AddLayer ( { newArray, setNewArray, setoverSelectedItem, overSelectedItem } ) {
	
	const items = [
		{
			id: 0,
			name: 'Climaticos',
			elementos: [
				{
					id: 0,
					name: 'Inundación',
					description: 'Mucha lluvia',
					activated: false
				},
				{
					id: 1,
					name: 'Sequía',
					description: 'Mucho calor',
					activated: false
				},{
					id: 2,
					name: 'Vendaval',
					description: 'Mucho viento',
					activated: false
				},
			]
		},
		{
			id: 1,
			name: 'Ambiental',
			elementos: [
				{
					id: 3,
					name: 'Contaminación',
					description: 'Polución',
					activated: false
				},
				{
					id: 4,
					name: 'Incendio',
					description: 'Fuego provocado',
					activated: false
				},{
					id: 5,
					name: 'Otros',
					description: 'Otros',
					activated: false
				},
			]
		},
	]

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [nombre, setNombre] = useState('');
	const [elementos, setElementos] = useState(items);
	const [hover, setHover] = useState();
	
  const showModal = () => {
	  setIsModalOpen(true);
  };

  
  const handleOk = (e) => {
	  e.preventDefault();
    setIsModalOpen(false);
	const elements = [];
	const filtros = [...elementos];
    filtros.map((item) => {
		return item.elementos.forEach((element) => {
			if (element.activated === true) {
				elements[0] = element
            }
        });
    })

	const arrayTmp = [...newArray];

	function randomNumber(maxNumber){
		return String(Math.floor(Math.random() * maxNumber))
	}
    
	const nuevaCapa = {
		value: nombre !== "" ? String(nombre) : elements[0] !== undefined ? String(elements[0].name) : "",
		key: arrayTmp.length + 1,
		label: nombre !== "" ? String(nombre) : elements[0] !== undefined ? String(elements[0].name) : "",
		content: 'Contenido ' + nombre !== "" ? String(nombre) : elements[0] !== undefined ? String(elements[0].name) : "",
		opacity: 100,
		color: `rgb(${randomNumber(255)}, ${randomNumber(255)}, ${randomNumber(255)})`,
		active: false,
    };

    arrayTmp.push(nuevaCapa);
	setNewArray(arrayTmp);
	setNombre('');
	setElementos(items);

  };

  const handleCancel = () => {
    setIsModalOpen(false);
	setNombre('');
	setElementos(items);
  };

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };

  
  return (
    <div>
		<Tooltip title="Añadir nueva capa" placement="top">
		<Button type="primary" onClick={showModal} className='boton-addlayer'>
			Add Layer
		</Button>
      </Tooltip>
		<Modal name="Vistas" open={isModalOpen} onCancel={handleCancel} footer={
			<Button type="primary" style={{ display: 'flex', justifyContent: 'center' }} onClick={handleOk}>Crear Filtro</Button>
		} width={1000}>
			<div>
				<h2>Añadir Capa</h2>
				<br />
				<div>Nombre de la nueva capa:</div>
				<Input
					placeholder="Nombre del Filtro"
					value={nombre}
					onChange={handleNombreChange}
				/>
				<br /><br />
				<div>Buscador campos:</div>
				<Buscador filtros={elementos} setFiltros={setElementos} />
				<br /><br />
				<div className='pantallaSelecFiltros'>
					<div className='pantallaIzda'>
						<Navegacion filtros={elementos} setFiltros={setElementos} setSelectedItem={setHover} setoverSelectedItem={setoverSelectedItem} />
						<br />
					</div>
					<div className='pantallaDrch'>
						<Descripcion overSelectedItem={overSelectedItem} />
					</div>
				</div>
			</div>
		</Modal>
    </div>
  );
}

export default AddLayer;


