import { Button, Col, Divider, Row, Segmented, Tooltip } from 'antd';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from 'react'
import { PrinterOutlined } from '@ant-design/icons';
import './PanelActivo.css';
import GraficoEcon from '../GraficoEcon/GraficoEcon';
import BarraRating from '../BarraRating/BarraRating';
import { RatingData } from '../../hooks/Rating/Rating';
import erro_img from './logos/no_thumbnail.png'
import { veltisTooltip } from '../../hooks/Tooltip/Tooltip';



const PanelActivo = ({ moco, dataa, datos }) => {
    const [segmentedValor, setsegmentedValor] = useState('total');
    const [loading, setLoading] = useState(false);
    const [areaMunData, setAreaMunData] = useState(false);

    const datosGrafica = [];
    if (dataa.data[0].arr_var === null) dataa.data[0].arr_var = [];
    dataa.data[0].arr_var.forEach(element => {
        let cosa = (Math.abs(element) * 100);
        datosGrafica.push(cosa);
    });

    if (moco === null) {
        moco = {
            color: ''
        }
    }

    const optionnn2 = {
        chart: {
            type: 'area',
            //marginRight: 20,
        },
        legend: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
        rangeSelector: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        title: {
            text: null
        },

        xAxis: {
            allowDecimals: false,
            labels: {
                formatter: function () {
                    return this.value; // clean, unformatted number for year
                }
            },
            accessibility: {
                rangeDescription: 'Range: 1940 to 2017.'
            },
            crosshair: true,
        },
        yAxis: {
            title: null,
            //opposite: true,
            labels: {
                formatter: function () {
                    return Highcharts.numberFormat(this.value, 0, ',', '.') + '€';
                },
            },
            crosshair: true,
        },
        shared: true,
        tooltip: {
            pointFormat: 'Valor por Indicadores de Riesgo ({point.x}): <b>{point.y}</b>€/m<sup>2</sup><br/>',
            crosshairs: true,
            //hideDelay: 500000,
            valueSuffix: '',
            split: true,
            distance: 0,
            padding: 5,
        },
        plotOptions: {
            series: {
                lineWidth: 0.5
            },
            area: {
                pointStart: 2004,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        series: [{
            name: 'USA',
            data: segmentedValor === 'total' ? dataa.data[0].arr_preciototal : dataa.data[0].arr_precio,
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 1,
                    x2: 0,
                    y2: 0
                },
                stops: [
                    [0, '#FFFFFF'],
                    [1, '#3AB4FE']
                ]
            }
        }]
    }

    const optionnn3 = {
        chart: {
            type: 'area',
        },
        legend: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
        rangeSelector: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        title: {
            text: null
        },

        xAxis: {
            allowDecimals: false,
            labels: {
                formatter: function () {
                    return this.value; // clean, unformatted number for year
                }
            },
            accessibility: {
                rangeDescription: 'Range: 1940 to 2017.'
            },
            crosshair: true,
        },
        yAxis: {
            title: null,
            labels: {
                formatter: function () {
                    return Highcharts.numberFormat(this.value, 0, ',', '.') + '';
                },
            },
            crosshair: true,
        },
        shared: true,
        tooltip: {
            pointFormat: 'Score ({point.x}): <b>{point.y}</b><br/>',
            crosshairs: true,
            valueSuffix: '',
            split: true,
            distance: 0,
            padding: 5,
        },
        plotOptions: {
            series: {
                lineWidth: 0.5
            },
            area: {
                pointStart: 2004,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        series: [{
            name: 'USA',
            data: datosGrafica.slice(2),
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 1,
                    x2: 0,
                    y2: 0
                },
                stops: [
                    [0, '#490000'],
                    [0.1, '#880000'],
                    [0.15, '#c80000'],
                    [0.2, '#f40800'],
                    [0.25, '#f52300'],
                    [0.3, '#f73e00'],
                    [0.35, '#f85900'],
                    [0.4, '#fa7300'],
                    [0.45, '#fb8e00'],
                    [0.5, '#fda900'],
                    [0.55, '#fec400'],
                    [0.6, '#ffdf00'],
                    [0.65, '#e3d407'],
                    [0.70, '#c3c50f'],
                    [0.75, '#a4b617'],
                    [0.8, '#84a720'],
                    [0.85, '#659928'],
                    [0.9, '#4c7f22'],
                    [0.95, '#34641c'],
                    [1, '#1c4a16'],
                ]
            }
        }]
    }

    useEffect(() => {
        Highcharts.setOptions({
            lang: {
                contextButtonTitle: "Menú contextual",
                decimalPoint: ",",
                downloadJPEG: "Descargar imagen JPEG",
                downloadPDF: "Descargar documento PDF",
                downloadPNG: "Descargar imagen PNG",
                downloadSVG: "Descargar vector SVG",
                loading: "Cargando...",
                months: [
                    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
                    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
                ],
                noData: "Sin datos para mostrar",
                printChart: "Imprimir gráfico",
                resetZoom: "Restablecer zoom",
                resetZoomTitle: "Restablecer nivel de zoom 1:1",
                shortMonths: [
                    "Ene", "Feb", "Mar", "Abr", "May", "Jun",
                    "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
                ],
                thousandsSep: ".",
                weekdays: [
                    "Domingo", "Lunes", "Martes", "Miércoles",
                    "Jueves", "Viernes", "Sábado"
                ]
            }
        });
    }
        , [])


    let imgError = erro_img;
    const [imgResFachada, setImgResFachada] = useState('');
    let imgFachada = `https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral=${dataa.data[0].full_refcat.substring(0, 14)}`;
    const [imgResPlano, setImgResPlano] = useState('');
    let imgPlano = `https://www1.sedecatastro.gob.es/Cartografia/GeneraGraficoParcela.aspx?del=${dataa.data[0].codigo_provincia}&mun=${dataa.data[0].id_mun_cat}&refcat=${dataa.data[0].full_refcat.substring(0, 14)}&AnchoPixels=120&AltoPixels=120`;

    useEffect(() => {
        setImgResFachada(imgError);
        setImgResPlano(imgError);
        checkIfImageExists(imgFachada, (exists) => {
            if (exists) {
                setImgResFachada(imgFachada);
            } else {
                setImgResFachada(imgError);
            }
        });

        checkIfImageExists(imgPlano, (exists) => {
            if (exists) {
                setImgResPlano(imgPlano);
            } else {
                setImgResPlano(imgError);
            }
        });
    }, [imgFachada, imgPlano, imgError]);

    function checkIfImageExists(url, callback) {
        const img = new Image();
        img.src = url;

        if (img.complete) {
            callback(true);
        } else {
            img.onload = () => {
                callback(true);
            };

            img.onerror = () => {
                callback(false);
            };
        }
    }

    const datoCEE = (ant) => {
        //const ant = parseInt(datos.tabla14_antiguedad_efectiva);
        if (ant === 'G') { return <span className='ceeactivo g'>G</span> }
        else if (ant === 'F') { return <span className='ceeactivo f'>F</span> }
        else if (ant === 'E') { return <span className='ceeactivo e'>E</span> }
        else if (ant === 'D') { return <span className='ceeactivo d'>D</span> }
        else if (ant === 'C') { return <span className='ceeactivo c'>C</span> }
        else if (ant === 'B') { return <span className='ceeactivo b'>B</span> }
        else if (ant === 'A') { return <span className='ceeactivo a'>A</span> }
        else { return <span className='ceeactivo D'></span> }
    }

    const ejemploActivo = [{
        id: 0,
        nombret: `Tipo de Uso: ${dataa.data[0].uso}`,
        tipoCalle: dataa.data[0].via_tipo,
        calle: dataa.data[0].via_nombre,
        datosCalle: `${dataa.data[0].primer_num_portal} ${dataa.data[0].primera_letra} Es.:${dataa.data[0].escalera != '' ? dataa.data[0].escalera : '-'} Pl.:${dataa.data[0].planta} Pt.:${dataa.data[0].puerta}`,
        municipio: dataa.data[0].municipio_name,
        provincia: dataa.data[0].provincia_name,
        codigoPostal: dataa.data[0].codigo_postal,
        antiguedad: new Date().getFullYear() - parseInt(dataa.data[0].antiguedad) + ` años (${dataa.data[0].antiguedad})`,
        refCat: dataa.data[0].full_refcat,
        valorActivo: 156941.040,
        sup: dataa.data[0].sup_elememtos_constru_asociados,
        calidad: transformarCalidad(dataa.data[0].tabla14_categoria)+`(${dataa.data[0].tabla14_categoria})`,
        cee_predicted: calificacion(),
        val_tipo_m2: dataa.data[0].val_tipo_m2,
        val_estandar_m2: dataa.data[0].val_estandar_m2,
        val_construccion_pt: dataa.data[0].val_construccion_pt,
        val_construccion_pe: dataa.data[0].val_construccion_pe,
        valor_ref_dgc: dataa.data[0].valor_ref_dgc,
    }]

    function transformarCalidad(numero) {
        switch (numero) {
            case 1:
                return 'Excelente';
            case 2:
                return 'Muy buena';
            case 3:
            case 4:
                return 'Buena';
            case 5:
                return 'Media';
            case 6:
                return 'Baja';
            case 7:
            case 8:
            case 9:
                return 'Muy baja';
            default:
                return 'desconocida';
        }
    }

    function calificacion() {
        if (dataa.data[0].cee_real !== null) {
            return datoCEE(dataa.data[0].cee_real);
        } else if (dataa.data[0].cee_predicted !== null && dataa.data[0].cee_predicted !== 'NULL' && dataa.data[0].cee_predicted !== 'null') {
            return datoCEE(dataa.data[0].cee_predicted);
        } else {
            return 'sin datos';
        }
    };

    const datosActivo = ejemploActivo.map((item) => {
        return (
            <div className='datosActivo' key={item.id}>
                {/*<h2> {item.nombret} </h2>*/}
                <div className='datosCalle'>
                    <h3><b style={{ fontWeight: '700', fontSize: '105%' }}>Calle:</b> {item.tipoCalle} {item.calle} {item.datosCalle}</h3>
                    <h3>{item.codigoPostal} {item.municipio} &#40;{item.provincia}&#41;</h3>
                </div>
                <div className='datosCat'>
                    <h4><b style={{ fontWeight: '700', fontSize: '105%' }}>Ref Cat:</b> {item.refCat}</h4>
                </div>
                <div className='datosCat'>
                    <h4><b style={{ fontWeight: '700', fontSize: '105%' }}>Superficie (cc):</b> {item.sup}m<sup>2</sup></h4>
                </div>
                <div className='datosCat'>
                    <h4><b style={{ fontWeight: '700', fontSize: '105%' }}>Calidad (constructiva):</b> {item.calidad}</h4>
                </div>
                <div className='datosCat'>
                    <h4><b style={{ fontWeight: '700', fontSize: '105%' }}>Antigüedad:</b> {item.antiguedad}</h4>
                </div>                
                {/*<div className='datosCat'>
                    <h4><b style={{ fontWeight: '700', fontSize: '105%' }}>val_tipo_m2(zona):</b> {item.val_tipo_m2}</h4>
                </div>
                <div className='datosCat'>
                    <h4><b style={{ fontWeight: '700', fontSize: '105%' }}>val_estandar_m2(zona):</b> {item.val_estandar_m2}</h4>
                </div>
                <div className='datosCat'>
                    <h4><b style={{ fontWeight: '700', fontSize: '105%' }}>val_construccion_pe(zona):</b> {item.val_construccion_pe}</h4>
                </div>
                <div className='datosCat'>
                    <h4><b style={{ fontWeight: '700', fontSize: '105%' }}>val_construccion_pt(zona):</b> {item.val_construccion_pt}</h4>
                </div>*/}
            </div>
        )
    })

    const changeSegmented = (e) => {
        if (e === 'Precio €/m2') setsegmentedValor('otro');
        else setsegmentedValor('total');
    }

    const data = datos.map((item) => {
        const filteredDatos = item.datos.filter((element) => {
            const elemento = String(element.dato).trim();
            return elemento !== 'null' && elemento !== 'undefined' && elemento !== '-' && elemento !== '0';
        });
        if (filteredDatos.length === 0) {
            return null;
        }
        return (
            <table className='table' key={item.id}>
                <thead>
                    <tr className="table-header" key={item.id}>
                        <th colSpan="2">{item.nombre}</th>
                    </tr>
                </thead>
                <tbody>
                    {item.datos.map((element) => {
                        let elemento = String(element.dato).trim();
                        if (elemento === 'null' || elemento === 'undefined' || elemento === '-' || elemento === '0') {
                            return ''
                        } else {
                            return (
                                <tr className="table-data">
                                    <td>{element.nombre}</td>
                                    <td>{elemento}</td>
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
        )
    })

    const datosRatingActivo = RatingData(dataa.data[0].rating_parcela);
    const datosRatingBarrio = RatingData(dataa.data[0].rating_cusec);
    const datosRatingMunicipio = RatingData(dataa.data[0].rating_mun);


    const catastro = (dataa.data[0].full_refcat)
    const cmun = (dataa.data[0].id_mun_cat)
    const cprov = (dataa.data[0].codigo_provincia)
    const latitud = (dataa.data[0].latitude)
    const longitud = (dataa.data[0].longitude)
    const verCatastro = `https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCConCiud.aspx?UrbRus=U&RefC=${catastro}&esBice=&RCBice1=&RCBice2=&DenoBice=&from=OVCBusqueda&pest=rc&RCCompleta=${catastro}&final=&del=${parseInt(cprov)}&mun=${parseInt(cmun)}`
    const verStreetView = `https://www.google.com/maps/@${latitud},${longitud},90a,20y,271.88h,83.44t/data=!3m1!1e3?layer=c&cbp=1,0,,0,5&cbll=${latitud},${longitud}`

    const abrirNuevaPestaña = () => {
        window.open('https://terminal.veltisrating.com:8888/api/view_pdf_all/' + dataa.data[0].full_refcat, '_blank');
    };

    return (
        <div style={{ paddingBottom: 50 }}>

            {
                /*<div style={{ float: 'right', marginRight: 10, }}>
                <Button type="default" icon={<DownloadOutlined />} size={'medium'}>
                    Añadir a cartera
                </Button>
            </div>*/
            }
            <div style={{ float: 'right', marginRight: 5, }}>
                {/*<Button type="default" icon={<PrinterOutlined />} size={'medium'}>
                    <a href={'https://terminal.veltisrating.com/old/hud/informe/index.php?refcat=' + dataa.data[0].full_refcat}
                        target="_blank"
                        rel="noreferrer">
                        Imprimir informe
                    </a>
                </Button>*/}
                <Button type="primary" onClick={abrirNuevaPestaña}>
                    Abrir Informe Riesgos
                </Button>
            </div>

            <Divider orientation="left">Información Activo</Divider>
            <Row>
                <Col flex="0 1 170px" style={{ height: 300, backgroundColor: 'white' }}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ height: 128, width: 170 }}>
                            <img width={'100%'} height={'100%'} className={'imgcat'} style={{ border: '1px solid grey' }} src={imgResFachada} alt='imgResFachada' />
                            <div style={{ position: 'relative', top: -32, left: 0, width: 80, }}>
                                <Tooltip title="Ver activo en catastro" overlayStyle={{ fontSize: 11 }} >
                                    <a href={verCatastro} target='_blank' rel="noopener noreferrer">
                                        <img src='https://www1.sedecatastro.gob.es/Cartografia/Imagenes/logosec.png' width={20} height={20} style={{ cursor: 'pointer', backgroundColor: 'grey', borderRadius: 3, border: '1px solid black' }} alt='imgResFachada' />
                                    </a>
                                </Tooltip>
                                <Tooltip title="Ver activo en Street View - Google Maps" overlayStyle={{ fontSize: 11 }}>
                                    <a href={verStreetView} target='_blank' rel="noopener noreferrer">
                                        <img src={'https://www.google.es/earth/outreach/images/icon-streetview-pegman.png'} width={20} height={20} style={{ cursor: 'pointer', backgroundColor: 'grey', borderRadius: 3, border: '1px solid black', marginLeft: 5 }} alt='imgResFachada' />
                                    </a>
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{ height: 128, width: 170, marginTop: 15, }}>
                            <img width={'100%'} height={'100%'} className={'imgcat'} style={{ border: '1px solid grey' }} src={imgResPlano} alt='imgResFachada' />
                        </div>
                    </div>
                </Col>
                <Col flex="1 1 150px" style={{ height: 300 }}>
                    <div style={{ marginLeft: 10 }}>
                        {datosActivo}
                    </div>
                </Col>
                <Col flex="0 1 150px" style={{ height: 300, backgroundColor: 'white', border: '1px dotted black' }}>
                    <div className='wrapper2'>
                        <div style={{ marginTop: 1, color: 'black', float: 'left', fontSize: 15, fontWeight: 'bold', width: '100%', borderBottom: '1px solid black' }}>
                            Rating
                        </div>
                        <div className='container2' style={{ marginTop: 10, width: 60, borderRadius: 10, backgroundColor: '#' + moco.color + '59', border: '1px solid #' + moco.color }}>
                            {moco.valorRating ? moco.valorRating : "N/A"}
                        </div>
                        <div style={{ marginTop: 10, color: '#' + moco.color, fontWeight: 'bold' }}>
                            {moco.text}
                        </div>
                    </div>
                </Col>
            </Row>

            {/*

            <Divider orientation="left">Rango Rating Área Homogénea & Rating Activo</Divider>

            <BarraRating moco={moco} loading={loading} areaMunData={areaMunData} />
            <GraficoEcon dataa={dataa} loading={loading} setLoading={setLoading} setAreaMunData={setAreaMunData} />

            <Divider orientation="left" style={{ marginTop: '2%' }}> </Divider>
            <table style={{ marginTop: '2%', marginBottom: '2%' }} className="table2">
                <thead>
                    <tr className="table-header2">
                        <th>Escala</th>
                        <th>RATING</th>
                        <th>Volatilidad</th>
                        <th>Score</th>
                        <th>Rentabilidad mínima exigir</th>
                        <th>{veltisTooltip(<>Haircut(12m) <br /> Max.pérdida en un año</>, "Determina la mayor pérdida que se ha experimentado en un período de un año, considerando un intervalo de tiempo que abarca desde 2004 hasta el día de hoy.")}</th>
                        <th>{veltisTooltip("**Haircut (LT)", "Calcula la variación porcentual en el valor desde el año 2004 hasta el día de hoy.")}</th>
                        {/*<th>**Rentabilidad media histórica</th>}
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-data2">
                        <td>Activo</td>
                        <td>{datosRatingActivo && datosRatingActivo.valorRating ? datosRatingActivo.valorRating : 'N/A'}</td>
                        <td>{datosRatingActivo && datosRatingActivo.volatilidad ? datosRatingActivo.volatilidad : 'N/A'}</td>
                        <td>{datosRatingActivo && datosRatingActivo.score ? datosRatingActivo.score : 'N/A'}</td>
                        <td>{datosRatingActivo && datosRatingActivo.rentabilidad ? datosRatingActivo.rentabilidad : 'N/A'}</td>
                        <td>{dataa.data[0].var_pct}</td>
                        {/*<td>*</td>}
                        <td>{dataa.data[0].rentabilidad_historica}</td>
                    </tr>
                    <tr className="table-data2">
                        <td>Área Homogénea</td>
                        <td>{datosRatingBarrio && datosRatingBarrio.valorRating ? datosRatingBarrio.valorRating : 'N/A'}</td>
                        <td>{datosRatingBarrio && datosRatingBarrio.volatilidad ? datosRatingBarrio.volatilidad : 'N/A'}</td>
                        <td>{datosRatingBarrio && datosRatingBarrio.score ? datosRatingBarrio.score : 'N/A'}</td>
                        <td>{datosRatingBarrio && datosRatingBarrio.rentabilidad ? datosRatingBarrio.rentabilidad : 'N/A'}</td>
                        <td>{dataa.data[0].avg_var_pct_cusec}</td>
                        {/*<td>*</td>}
                        <td>{dataa.data[0].avg_rentabilidad_historica_cusec}</td>
                    </tr>
                    <tr className="table-data2">
                        <td>Municipio</td>
                        <td>{datosRatingMunicipio && datosRatingMunicipio.valorRating ? datosRatingMunicipio.valorRating : 'N/A'}</td>
                        <td>{datosRatingMunicipio && datosRatingMunicipio.volatilidad ? datosRatingMunicipio.volatilidad : 'N/A'}</td>
                        <td>{datosRatingMunicipio && datosRatingMunicipio.score ? datosRatingMunicipio.score : 'N/A'}</td>
                        <td>{datosRatingMunicipio && datosRatingMunicipio.rentabilidad ? datosRatingMunicipio.rentabilidad : 'N/A'}</td>
                        <td>{dataa.data[0].avg_var_pct_mun}</td>
                        {/*<td>*</td>}
                        <td>{dataa.data[0].avg_rentabilidad_historica_mun}</td>
                    </tr>
                </tbody>
            </table>



            <Divider orientation="left" style={{ marginTop: '2%' }}> </Divider>
            <div style={{ marginTop: '2%', marginBottom: '2%', display: 'flex' }}>
                {data}
            </div>

            */}

            {/*

            <Divider orientation="left">Valor por Indicadores de Riesgo (VIR)</Divider>
            <div className='indic_riesg'>
                <div className='graficos_indic_riesg'>
                    <div className='xxx'>
                        <Segmented
                            style={{
                                margin: '0 auto',
                            }}
                            defaultValue={'Valor total'}
                            options={['Valor total', 'Precio €/m2']}
                            onChange={changeSegmented}
                        />
                    </div>
                    <Row>
                        <Col flex="1 1 auto" style={{ height: 300 }}>
                            <HighchartsReact
                                containerProps={{ style: { height: "100%" } }}
                                highcharts={Highcharts}
                                options={optionnn2}
                            />
                        </Col>
                    </Row>
                </div>
                <div className='graficos_indic_riesg'>
                    <div className='xxx'>
                        <Segmented
                            style={{
                                margin: '0 auto',
                            }}
                            defaultValue={'Histórico Rating'}
                            options={['Histórico Rating']}
                        />
                    </div>
                    <Row>
                        <Col flex="1 1 auto" style={{ height: 300 }}>
                            <HighchartsReact
                                containerProps={{ style: { height: "100%" } }}
                                highcharts={Highcharts}
                                options={optionnn3}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            */ }
        </div>
    )
}

export default PanelActivo