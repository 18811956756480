import { Button, Tabs } from "antd";
function Componente({ filtro, setSelectedItem, setFiltros, filtros, catid, setoverSelectedItem }) {
    // funcion que cambia el atributo activated y lo añade al array para mostrar en descripcion
    function handleClick(e, catid) {
        const filtrostmp = [...filtro];
        const index = filtrostmp.findIndex((objeto) => objeto.id === Number(e.id));
        filtrostmp[index].activated = !filtrostmp[index].activated;
        const filtrado = [];
        if (filtrostmp[index].activated === true) {
            filtrado.push(filtrostmp[index]);
        }
        setSelectedItem(filtrado);

        const newFiltros = [...filtros];
        const indexCategoria = newFiltros.findIndex((objeto) => objeto.id === Number(catid));
        newFiltros[indexCategoria].elementos = filtrostmp;
        setFiltros(newFiltros);
    }
    function handleOver(e, catid) {
        const filtrostmp = [...filtro];
        const index = filtrostmp.findIndex((objeto) => objeto.id === Number(e.id));
        setoverSelectedItem(filtrostmp[index]);
    }
    if (filtro !== undefined && filtro.length > 0) {
        return filtro.map((item) => {
            return <Button key={item.id} id={item.id} size={'small'} onMouseEnter={() => { handleOver(item, catid) }} onMouseLeave={() => { setoverSelectedItem(null) }}   onClick={() => { handleClick(item, catid) }} style={item.activated === true ? { backgroundColor: '#52c41a', margin: 2 } : { margin: 2 }}>{item.name}</Button>
        });
    } else {
        return <span>-No data-</span>;
    }
};
const Navegacion = ({ filtros, setFiltros, setSelectedItem, setoverSelectedItem }) => {
    return (
        <>
            <Tabs
                defaultActiveKey="0"
                tabPosition="top"
                style={{
                    height: 220,
                    overflow: 'auto'
                }}

                items={filtros.map((filtro) => {
                    // funcion para mostrar los filtros activos en cada uno de los tabs
                    let contador = 0;
                    for (let j = 0; j < filtro.elementos.length; j++) {
                        const element = filtro.elementos[j];
                        if (element.activated === true) {
                            contador++;
                        }
                    }
                    let nombreTab = `${filtro.name} ${contador > 0 ? `(${contador})` : ""}`;
                    return {
                        label: nombreTab,
                        key: filtro.id,
                        children: <Componente key={filtro.id} filtro={filtro.elementos} setSelectedItem={setSelectedItem} setFiltros={setFiltros} filtros={filtros} catid={filtro.id} setoverSelectedItem={setoverSelectedItem} />
                    };
                })}
            />
        </>
    );
};
export default Navegacion;