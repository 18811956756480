import { React } from 'react'
import './RiskClimatic.css';
import RiesgosSistemicos2 from '../RiesgosSistemicos/RiesgosSistemicos2';

const RiskClimatic = ({dataa}) => {

    return (
        <>
          <RiesgosSistemicos2 dataa={dataa}/>
        </>
    )
}

export default RiskClimatic