import React, { useEffect, useState } from 'react'
import { SplitPane } from "react-collapse-pane";
import "./Body.css";
import Filters from './Filters';
import Drop from '../../../components/Drop/Drop';
import {
    TableOutlined,
} from '@ant-design/icons';
import BodyInf from './BodyInf';
import InfPanel from '../../../components/InfPanel/InfPanel';
import { Segmented, Tabs, Button, Modal, Tooltip, Popconfirm, message, Dropdown } from 'antd';
import {
    CloseCircleOutlined,
    FilterOutlined,
    QuestionCircleOutlined,
    WindowsOutlined
} from '@ant-design/icons';

import Navegacion from '../../../components/Navegacion/Navegacion';
import Descripcion from '../../../components/Descripcion/Descripcion';
import BarraSeleccion from '../../../components/Seleccion/BarraSeleccion';
import Buscador from '../../../components/Buscador_filtros/Buscador_filtros';
import { RequestDelete, RequestGet, requestPost } from '../../../hooks/Request/Request';
import { cellStyleInteger, rendererCalidad, numberFormatter, rendererCEE, rendererTaxonomia, cellStyleTaxonomia } from '../../../hooks/GridHook/GridHook';
import Layout from '../../../components/Layout';
import { fetchCarteras } from '../../../hooks/Carteras/Carteras';

const listaFiltros = [
    {
        catid: 1,
        catname: "Activo",
        subcategorias: [
            {
                subcatid: 1,
                subcatname: "Subcategoria1",
                subcatindicador: [
                    {
                        indicadorid: 1,
                        indicadorname: "Superficie c.c.",
                        indicadorsrc: "sup_elememtos_constru_asociados",
                        indicadortipo: "min_max",
                        indicadorfiltros: [
                            { id: 0, nombre: "-", tipo: "min", value: 0, selected: false },
                            { id: 1, nombre: "10m2", tipo: "min", value: 10, selected: false },
                            { id: 2, nombre: "20m2", tipo: "min", value: 20, selected: false },
                            { id: 3, nombre: "30m2", tipo: "min", value: 30, selected: false },
                            { id: 4, nombre: "40m2", tipo: "min", value: 40, selected: false },
                            { id: 5, nombre: "50m2", tipo: "min", value: 50, selected: false },
                            { id: 6, nombre: "-", tipo: "max", value: 0, selected: false },
                            { id: 7, nombre: "100m2", tipo: "max", value: 100, selected: false },
                            { id: 8, nombre: "150m2", tipo: "max", value: 150, selected: false },
                            { id: 9, nombre: "200m2", tipo: "max", value: 200, selected: false },
                            { id: 10, nombre: "300m2", tipo: "max", value: 300, selected: false },
                            { id: 11, nombre: "500m2", tipo: "max", value: 500, selected: false },
                        ]
                    },
                    {
                        indicadorid: 2,
                        indicadorname: "Antigüedad Efectiva",
                        indicadorsrc: "tabla14_antiguedad_efectiva",
                        indicadortipo: "selector",
                        indicadorfiltros: [
                            { id: 0, nombre: "-", tipo: "selector", value: new Date().getFullYear() - 0, selected: false },
                            { id: 1, nombre: "0 años", tipo: "selector", value: new Date().getFullYear(), selected: false },
                            { id: 2, nombre: "1 años", tipo: "selector", value: new Date().getFullYear() - 1, selected: false },
                            { id: 3, nombre: "2 años", tipo: "selector", value: new Date().getFullYear() - 2, selected: false },
                            { id: 4, nombre: "3 años", tipo: "selector", value: new Date().getFullYear() - 3, selected: false },
                            { id: 5, nombre: "5 años", tipo: "selector", value: new Date().getFullYear() - 5, selected: false },
                            { id: 6, nombre: "10 años", tipo: "selector", value: new Date().getFullYear() - 10, selected: false },
                            { id: 7, nombre: "30 años", tipo: "selector", value: new Date().getFullYear() - 30, selected: false },
                            { id: 8, nombre: "50 años", tipo: "selector", value: new Date().getFullYear() - 50, selected: false },
                            { id: 9, nombre: "100 años", tipo: "selector", value: new Date().getFullYear() - 100, selected: false },
                        ]
                    },
                    {
                        indicadorid: 3,
                        indicadorname: "Certificación Energética",
                        indicadorsrc: "cee_predicted",
                        indicadortipo: "selector",
                        indicadorfiltros: [
                            { id: 0, nombre: "-", tipo: "selector", value: 0, selected: false },
                            { id: 1, nombre: "A", tipo: "selector", value: 'A', selected: false },
                            { id: 2, nombre: "B", tipo: "selector", value: 'B', selected: false },
                            { id: 3, nombre: "C", tipo: "selector", value: 'C', selected: false },
                            { id: 4, nombre: "D", tipo: "selector", value: 'D', selected: false },
                            { id: 5, nombre: "E", tipo: "selector", value: 'E', selected: false },
                            { id: 6, nombre: "F", tipo: "selector", value: 'F', selected: false },
                            { id: 7, nombre: "G", tipo: "selector", value: 'G', selected: false },
                        ]
                    },
                    {
                        indicadorid: 4,
                        indicadorname: "Taxonomía",
                        indicadorsrc: "taxonomia",
                        indicadortipo: "selector",
                        indicadorfiltros: [
                            { id: 0, nombre: "-", tipo: "selector", value: 0, selected: false },
                            { id: 1, nombre: "Verde", tipo: "selector", value: 2, selected: false },
                            { id: 2, nombre: "Sostenible", tipo: "selector", value: 1, selected: false },
                            { id: 3, nombre: "No Sostenible", tipo: "selector", value: 0, selected: false },
                        ]
                    },
                ]
            },
            {
                subcatid: 2,
                subcatname: "Buscar Municipio",
                subcatindicador: [
                    {
                        indicadorid: 1,
                        indicadorname: "Municipio",
                        indicadorsrc: "codigo_municipio",
                        indicadortipo: "buscador-selector",
                        indicadorfiltros: []
                    }
                ]
            },
        ]
    },
    /*{
        catid: 2,
        catname: "Económicos",
        subcategorias: [
            {
                subcatid: 1,
                subcatname: "Subcategoria",
                subcatindicador: [
                    {
                        indicadorid: 1,
                        indicadorname: "Value %",
                        indicadorsrc: "pib",
                        indicadortipo: "min_max",
                        indicadorfiltros: [
                            { id: 0, nombre: "-", tipo: "min", value: 0, selected: false },
                            { id: 1, nombre: "5%", tipo: "min", value: 5, selected: false },
                            { id: 2, nombre: "8%", tipo: "min", value: 8, selected: false },
                            { id: 3, nombre: "10%", tipo: "min", value: 10, selected: false },
                            { id: 4, nombre: "-", tipo: "max", value: 0, selected: false },
                            { id: 5, nombre: "15%", tipo: "max", value: 15, selected: false },
                            { id: 6, nombre: "20%", tipo: "max", value: 20, selected: false },
                            { id: 7, nombre: "25%", tipo: "max", value: 25, selected: false },
                        ]
                    }
                ]
            }
        ]
    },*/
    {
        catid: 3,
        catname: "Climáticos",
        subcategorias: [
            {
                subcatid: 1,
                subcatname: "Subcategoria",
                subcatindicador: [
                    {
                        indicadorid: 1,
                        indicadorname: "Risk Sismo",
                        indicadorsrc: "risk_sismo",
                        indicadortipo: "min_max",
                        indicadorfiltros: [
                            { id: 0, nombre: "-", tipo: "min", value: 0, selected: false },
                            { id: 1, nombre: "10", tipo: "min", value: 10, selected: false },
                            { id: 2, nombre: "20", tipo: "min", value: 20, selected: false },
                            { id: 3, nombre: "30", tipo: "min", value: 30, selected: false },
                            { id: 4, nombre: "40", tipo: "min", value: 40, selected: false },
                            { id: 5, nombre: "50", tipo: "min", value: 50, selected: false },
                            { id: 6, nombre: "60", tipo: "min", value: 60, selected: false },
                            { id: 7, nombre: "70", tipo: "min", value: 70, selected: false },
                            { id: 8, nombre: "80", tipo: "min", value: 80, selected: false },
                            { id: 9, nombre: "90", tipo: "min", value: 90, selected: false },
                            { id: 10, nombre: "100", tipo: "min", value: 100, selected: false },
                            { id: 11, nombre: "-", tipo: "max", value: 0, selected: false },
                            { id: 12, nombre: "10", tipo: "max", value: 10, selected: false },
                            { id: 13, nombre: "20", tipo: "max", value: 20, selected: false },
                            { id: 14, nombre: "30", tipo: "max", value: 30, selected: false },
                            { id: 15, nombre: "40", tipo: "max", value: 40, selected: false },
                            { id: 16, nombre: "50", tipo: "max", value: 50, selected: false },
                            { id: 17, nombre: "60", tipo: "max", value: 60, selected: false },
                            { id: 18, nombre: "70", tipo: "max", value: 70, selected: false },
                            { id: 19, nombre: "80", tipo: "max", value: 80, selected: false },
                            { id: 20, nombre: "90", tipo: "max", value: 90, selected: false },
                            { id: 21, nombre: "100", tipo: "max", value: 100, selected: false },
                        ]
                    },
                    {
                        indicadorid: 2,
                        indicadorname: "Risk Desertificación",
                        indicadorsrc: "risk_desertificacion",
                        indicadortipo: "min_max",
                        indicadorfiltros: [
                            { id: 0, nombre: "-", tipo: "min", value: 0, selected: false },
                            { id: 1, nombre: "10", tipo: "min", value: 10, selected: false },
                            { id: 2, nombre: "20", tipo: "min", value: 20, selected: false },
                            { id: 3, nombre: "30", tipo: "min", value: 30, selected: false },
                            { id: 4, nombre: "40", tipo: "min", value: 40, selected: false },
                            { id: 5, nombre: "50", tipo: "min", value: 50, selected: false },
                            { id: 6, nombre: "60", tipo: "min", value: 60, selected: false },
                            { id: 7, nombre: "70", tipo: "min", value: 70, selected: false },
                            { id: 8, nombre: "80", tipo: "min", value: 80, selected: false },
                            { id: 9, nombre: "90", tipo: "min", value: 90, selected: false },
                            { id: 10, nombre: "100", tipo: "min", value: 100, selected: false },
                            { id: 11, nombre: "-", tipo: "max", value: 0, selected: false },
                            { id: 12, nombre: "10", tipo: "max", value: 10, selected: false },
                            { id: 13, nombre: "20", tipo: "max", value: 20, selected: false },
                            { id: 14, nombre: "30", tipo: "max", value: 30, selected: false },
                            { id: 15, nombre: "40", tipo: "max", value: 40, selected: false },
                            { id: 16, nombre: "50", tipo: "max", value: 50, selected: false },
                            { id: 17, nombre: "60", tipo: "max", value: 60, selected: false },
                            { id: 18, nombre: "70", tipo: "max", value: 70, selected: false },
                            { id: 19, nombre: "80", tipo: "max", value: 80, selected: false },
                            { id: 20, nombre: "90", tipo: "max", value: 90, selected: false },
                            { id: 21, nombre: "100", tipo: "max", value: 100, selected: false },
                        ]
                    }
                ]
            }
        ]
    },
    {
        catid: 4,
        catname: "Rating",
        subcategorias: [
            {
                subcatid: 1,
                subcatname: "Subcategoria",
                subcatindicador: [
                    {
                        indicadorid: 1,
                        indicadorname: "Rating Activo",
                        indicadorsrc: "rating_parcela",
                        indicadortipo: "min_max",
                        indicadorfiltros: [
                            { id: 0, nombre: "-", tipo: "min", value: 0, selected: false },
                            { id: 1, nombre: "D", tipo: "min", value: 0, selected: false },
                            { id: 2, nombre: "C", tipo: "min", value: 1, selected: false },
                            { id: 3, nombre: "CC", tipo: "min", value: 2, selected: false },
                            { id: 4, nombre: "CCC", tipo: "min", value: 3, selected: false },
                            { id: 5, nombre: "B-", tipo: "min", value: 4, selected: false },
                            { id: 6, nombre: "B", tipo: "min", value: 5, selected: false },
                            { id: 7, nombre: "B+", tipo: "min", value: 6, selected: false },
                            { id: 8, nombre: "BB-", tipo: "min", value: 7, selected: false },
                            { id: 9, nombre: "BB", tipo: "min", value: 8, selected: false },
                            { id: 10, nombre: "BB+", tipo: "min", value: 9, selected: false },
                            { id: 11, nombre: "BBB-", tipo: "min", value: 10, selected: false },
                            { id: 12, nombre: "BBB", tipo: "min", value: 11, selected: false },
                            { id: 13, nombre: "BBB+", tipo: "min", value: 12, selected: false },
                            { id: 14, nombre: "A-", tipo: "min", value: 13, selected: false },
                            { id: 15, nombre: "A", tipo: "min", value: 14, selected: false },
                            { id: 16, nombre: "A+", tipo: "min", value: 15, selected: false },
                            { id: 17, nombre: "AA-", tipo: "min", value: 16, selected: false },
                            { id: 18, nombre: "AA", tipo: "min", value: 17, selected: false },
                            { id: 19, nombre: "AA+", tipo: "min", value: 18, selected: false },
                            { id: 20, nombre: "AAA", tipo: "min", value: 19, selected: false },

                            { id: 21, nombre: "-", tipo: "max", value: 0, selected: false },
                            { id: 22, nombre: "AAA", tipo: "max", value: 19, selected: false },
                            { id: 23, nombre: "AA+", tipo: "max", value: 18, selected: false },
                            { id: 24, nombre: "AA", tipo: "max", value: 17, selected: false },
                            { id: 25, nombre: "AA-", tipo: "max", value: 16, selected: false },
                            { id: 26, nombre: "A+", tipo: "max", value: 15, selected: false },
                            { id: 27, nombre: "A", tipo: "max", value: 14, selected: false },
                            { id: 28, nombre: "A-", tipo: "max", value: 13, selected: false },
                            { id: 29, nombre: "BBB+", tipo: "max", value: 12, selected: false },
                            { id: 30, nombre: "BBB", tipo: "max", value: 11, selected: false },
                            { id: 31, nombre: "BBB-", tipo: "max", value: 10, selected: false },
                            { id: 32, nombre: "BB+", tipo: "max", value: 9, selected: false },
                            { id: 33, nombre: "BB", tipo: "max", value: 8, selected: false },
                            { id: 34, nombre: "BB-", tipo: "max", value: 7, selected: false },
                            { id: 36, nombre: "B+", tipo: "max", value: 6, selected: false },
                            { id: 37, nombre: "B", tipo: "max", value: 5, selected: false },
                            { id: 38, nombre: "B-", tipo: "max", value: 4, selected: false },
                            { id: 39, nombre: "CCC", tipo: "max", value: 3, selected: false },
                            { id: 40, nombre: "CC", tipo: "max", value: 2, selected: false },
                            { id: 41, nombre: "C", tipo: "max", value: 1, selected: false },
                            { id: 42, nombre: "D", tipo: "max", value: 0, selected: false },
                        ]
                    },
                    {
                        indicadorid: 2,
                        indicadorname: "Rating Barrio",
                        indicadorsrc: "rating__cusec",
                        indicadortipo: "selector",
                        indicadorfiltros: [
                            { id: 0, nombre: "-", tipo: "selector", value: 0, selected: false },
                            { id: 1, nombre: "AAA", tipo: "selector", value: 19, selected: false },
                            { id: 2, nombre: "AA+", tipo: "selector", value: 18, selected: false },
                            { id: 3, nombre: "AA", tipo: "selector", value: 17, selected: false },
                            { id: 4, nombre: "AA-", tipo: "selector", value: 16, selected: false },
                            { id: 5, nombre: "A+", tipo: "selector", value: 15, selected: false },
                            { id: 6, nombre: "A", tipo: "selector", value: 14, selected: false },
                            { id: 7, nombre: "A-", tipo: "selector", value: 13, selected: false },
                            { id: 8, nombre: "BBB+", tipo: "selector", value: 12, selected: false },
                            { id: 9, nombre: "BBB", tipo: "selector", value: 11, selected: false },
                            { id: 10, nombre: "BBB-", tipo: "selector", value: 10, selected: false },
                            { id: 11, nombre: "BB+", tipo: "selector", value: 9, selected: false },
                            { id: 12, nombre: "BB", tipo: "selector", value: 8, selected: false },
                            { id: 13, nombre: "BB-", tipo: "selector", value: 7, selected: false },
                            { id: 14, nombre: "B+", tipo: "selector", value: 6, selected: false },
                            { id: 15, nombre: "B", tipo: "selector", value: 5, selected: false },
                            { id: 16, nombre: "B-", tipo: "selector", value: 4, selected: false },
                            { id: 17, nombre: "CCC", tipo: "selector", value: 3, selected: false },
                            { id: 18, nombre: "CC", tipo: "selector", value: 2, selected: false },
                            { id: 19, nombre: "C", tipo: "selector", value: 1, selected: false },
                            { id: 20, nombre: "D", tipo: "selector", value: 0, selected: false },
                        ]
                    }
                ]
            }
        ]
    },
    /*{
        catid: 5,
        catname: "Otros",
        subcategorias: [
            {
                subcatid: 1,
                subcatname: "Buscar Municipio",
                subcatindicador: [
                    {
                        indicadorid: 1,
                        indicadorname: "Municipio",
                        indicadorsrc: "codigo_municipio",
                        indicadortipo: "buscador-selector",
                        indicadorfiltros: []
                    }
                ]
            },
        ]
    }*/
]

// array y constantes de los menus de navegacion de filtros
const menus = [
    {
        id: 0,
        name: 'Activo',
        icon: <FilterOutlined />,
        elementos: [
            {
                id: 0,
                name: "Superficie con comunes",
                description: "Descripción del producto 1",
                activated: true,
                grid: { field: 'sup_elememtos_constru_asociados', headerName: 'Superficie c.c.', minWidth: 100, width: 100, },
            },
            {
                id: 1,
                name: "Renta Media Hogar",
                description: "Descripción del producto 2",
                activated: true,
                grid: { field: 'rmh_2019', headerName: 'Renta Media Hogar', minWidth: 100, width: 100, },
            },
            {
                id: 2,
                name: "Rentabilidad Historica (%)",
                description: "Descripción del producto 2",
                activated: true,
                grid: { field: 'rentabilidad_historica', headerName: 'Rentabilidad Historica (%)', minWidth: 103, width: 103, cellStyle: cellStyleInteger, valueFormatter: numberFormatter, aggFunc: 'avg', },
            },
            {
                id: 3,
                name: "Avg. Rentabilidad (%)",
                description: "Descripción del producto 2",
                activated: true,
                grid: { field: 'rentabilidad_media', headerName: 'Avg. Rentabilidad (%)', minWidth: 108, width: 108, cellStyle: cellStyleInteger, valueFormatter: numberFormatter, aggFunc: 'avg', },
            },
            {
                id: 4,
                name: "Antigüedad Efectiva",
                description: "Descripción del producto 2",
                activated: false,
                grid: { field: 'tabla14_antiguedad_efectiva', headerName: 'Antigüedad Efectiva', minWidth: 100, width: 100, },
            },
            {
                id: 5,
                name: "Calidad Constructiva",
                description: "Descripción del producto 2",
                activated: true,
                grid: { field: 'tabla14_categoria', headerName: 'Calidad Constructiva', minWidth: 105, width: 105,  cellRenderer: rendererCalidad, },
            },
            {
                id: 6,
                name: "Municipio",
                description: "Descripción del producto 1",
                activated: true,
                grid: { field: 'municipio_name', headerName: 'Municipio', minWidth: 150, },
            },
            {
                id: 7,
                name: "Antigüedad",
                description: "Descripción del producto 2",
                activated: true,
                grid: { field: 'antiguedad', headerName: 'Antigüedad', minWidth: 100, width: 100, },
            },
            {
                id: 7,
                name: "CEE",
                description: "Descripción del producto 2",
                activated: true,
                grid: { field: 'cee_predicted', headerName: 'CEE', minWidth: 100, width: 100, cellClass: 'mi-clase', cellRenderer: rendererCEE },
            },            
            {
                id: 8,
                name: "Taxonomía",
                description: "Descripción del producto 2",
                activated: true,
                grid: { field: 'taxonomia', headerName: 'Taxonomía', minWidth: 105, width: 105, cellStyle: cellStyleTaxonomia, cellRenderer: rendererTaxonomia, },
            },            
            {
                id: 9,
                name: "VzH",
                description: "Descripción del producto 2",
                activated: true,
                grid: { field: 'valor_ref_zona_dgc', headerName: 'VzH', minWidth: 100, width: 100, },
            }, 
        ]
    },
    {
        id: 1,
        name: 'Económicos',
        icon: <FilterOutlined />,
        elementos: [

        ]
    },
    {
        id: 2,
        name: 'Climáticos',
        icon: <FilterOutlined />,
        elementos: [           
            {
                id: 10,
                name: "Risk ENP",
                description: "Descripción del producto 2",
                activated: false,
                grid: { field: 'risk_enp_enp', headerName: 'Risk ENP', minWidth: 100, width: 100, },
            },
            {
                id: 11,
                name: "Risk MAB",
                description: "Descripción del producto 2",
                activated: false,
                grid: { field: 'risk_enp_mab', headerName: 'Risk MAB', minWidth: 100, width: 200, },
            },
            {
                id: 12,
                name: "Risk Red Natura",
                description: "Descripción del producto 2",
                activated: false,
                grid: { field: 'risk_enp_rednatura', headerName: 'Risk Red Natura', minWidth: 100, width: 300, },
            },
            {
                id: 13,
                name: "Risk Desertificación",
                description: "Descripción del producto 2",
                activated: false,
                grid: { field: 'risk_desertificacion', headerName: 'Risk Desertificación', minWidth: 100, width: 300, },
            },
            {
                id: 14,
                name: "Risk Sismo",
                description: "Descripción del producto 2",
                activated: false,
                grid: { field: 'risk_sismo', headerName: 'Risk Sismo', minWidth: 100, width: 300, },
            },
        ]
    },
    {
        id: 3,
        name: 'Rating',
        icon: <FilterOutlined />,
        elementos: [

        ]
    },
    {
        id: 4,
        name: 'Otros',
        icon: <FilterOutlined />,
        elementos: [

        ]
    }
]

const PantallasVeltis = [
    { label: "Viviendas Mejor Rating Madrid", key: 0, },
    { label: "Nuevos Activos - Uso Hotelero", key: 1, },
    { label: "Mejores Rendimientos 2022", key: 2, },
];
const PantallasClientes = [
    { label: "Screen_1", key: 0, },
    { label: "Screen_2", key: 1, },
]




const Body = () => {

    const [openedFilters, setOpenedFilters] = useState(false);
    const [varestado, setVarestado] = useState(3);
    const [gridSize, setGridSize] = useState(false);
    const [selectedCategoria, setselectedCategoria] = useState(false);
    function openFilters(e) { setOpenedFilters(true); setselectedCategoria(e); }
    const [usestateListaFiltros, setusestateListaFiltros] = useState(listaFiltros);
    const [vistaSelected, setvistaSelected] = useState(null);
    const [vistaVeltisSelected, setvistaVeltisSelected] = useState(null);
    const [pantallaSelected, setPantallaSelected] = useState(null);
    const [pantallaVeltisSelected, setpantallaVeltisSelected] = useState(null);
    const [modal, setModal] = useState(false);
    function abrirModal() { setModal(true); }
    function cerrarModal() { setModal(false); }
    const [filtros, setFiltros] = useState(menus);
    const [selectedItems, setSelectedItem] = useState();
    const [overSelectedItem, setoverSelectedItem] = useState();
    const [activoSelected, setACtivoSeletected] = useState('');
    const [carteras, setCarteras] = useState([]);
    const [carteraSelected, setCarteraSelected] = useState(false); // check if any wallet is selected and is setted to true if so
    const [userName, setUserName] = useState('');
    const [userData, setUserData] = useState(false);
    const [activosInGrid, setactivosInGrid] = useState([]);

    useEffect(() => {
        async function loadUserData() {
            const response = await RequestGet(
                'https://terminal.veltisrating.com:8888/api/getAuthData',
            )
            if (response.data[0] !== undefined && response.data.length > 0) {
                setUserName(response.data[0].user_name);
                setUserData(response.data[0]);
            } else {
                return '';
            }
        }
        loadUserData();
    }, [])

    function borrarFiltross() {
        const filtrosSeleccionados = [...filtros];
        filtrosSeleccionados.map((item) => {
            return item.elementos.map((cosa) => {
                return cosa.activated = false;
            })
        })
        setFiltros(filtrosSeleccionados);
    }
    function verGrafica(id) { setVarestado(id) }
    function changeGrid(size) { setGridSize(size); if (size[0] > 0 && size[1] > 0) localStorage.setItem('splitPos', JSON.stringify(size)); }
    function borrarTodoFiltros() {
        const arrayOriginal = [...usestateListaFiltros]
        arrayOriginal.map((categoria) =>
            categoria.subcategorias.map((indicador) =>
                indicador.subcatindicador.map((filtro) =>
                    filtro.indicadorfiltros.map((item) =>
                        item.selected = false))))
        setusestateListaFiltros(arrayOriginal);
    }

    const [contadorFiltros, setContadorFiltros] = useState(0);
    useEffect(() => {
        let filtrosActivos = 0;
        usestateListaFiltros.map((categoria) => {
            categoria.subcategorias.map((indicador) =>
                indicador.subcatindicador.map((filtro) =>
                    filtro.indicadorfiltros.map((item) => {
                        if (item.nombre !== '-' && item.selected) {
                            filtrosActivos++;
                        }
                    })
                )
            );
        });
        setContadorFiltros(filtrosActivos);

    }, [usestateListaFiltros]);

    useEffect(() => {
        if (activoSelected !== null && activoSelected !== '' && activoSelected !== false && activoSelected !== undefined) {
            const old = JSON.parse(JSON.stringify(userData));
            old.user_creditos = old.user_creditos + 1;
            setUserData(old);
        }
    }, [activoSelected])


    function borrarFiltros(e) {
        const newArraya = [...usestateListaFiltros];
        const categoria = e.target.getAttribute("data-categoria");
        const indice1 = newArraya.findIndex(item => item.catid === Number(categoria));
        if (indice1 !== -1) {
            newArraya[indice1].subcategorias.forEach((indicador) =>
                indicador.subcatindicador.forEach((item) =>
                    item.indicadorfiltros.forEach((filtro) =>
                        filtro.selected = false
                    )
                )
            );
            setusestateListaFiltros(newArraya);
        }
    }

    useEffect(() => {
        if (vistaVeltisSelected !== null) {
            const newArrat = [...filtros];
            for (const categoriaFiltro of filtros) {
                for (const Filtroo of categoriaFiltro.elementos) {
                    Filtroo.activated = false;
                    if (vistaVeltisSelected.grid.includes(Filtroo.id)) {
                        Filtroo.activated = true;
                    }
                }
            }
            setFiltros(newArrat)
        }
    }, [vistaVeltisSelected])

    useEffect(() => {
        if (vistaSelected !== null) {
            const newArrat = [...filtros];
            for (const categoriaFiltro of filtros) {
                for (const Filtroo of categoriaFiltro.elementos) {
                    Filtroo.activated = false;
                    if (vistaSelected.grid.includes(Filtroo.id)) {
                        Filtroo.activated = true;
                    }
                }
            }
            setFiltros(newArrat)
        }
    }, [vistaSelected])

    const borrarPantalla = async (e) => {
        const id_pantalla = e;
        await requestPost(
            'https://terminal.veltisrating.com:8888/api/removeListaPantalla',
            { id_pantalla: id_pantalla }
        )
        message.info(`Se ha borrado la pantalla: ${pantallaSelected.label}`);
        setPantallaSelected(null);
    }

    const borrarVista = async (e) => {
        const id_vista = e;
        await requestPost(
            'https://terminal.veltisrating.com:8888/api/removeListaVista',
            { id_vista: id_vista }
        )
        message.info(`Se ha borrado la vista: ${vistaSelected.label}`);
        setvistaSelected(null);
    }

    // const [tags, setTags] = useState(['Unremovable asd asd qwe asd', 'Tag 2', 'Tag 3']);


    async function aplicarFiltros() {
        const newArrat = [];
        for (const categoriaFiltro of filtros) {
            for (const Filtroo of categoriaFiltro.elementos) {
                if (Filtroo.activated) {
                    newArrat.push(Filtroo.id);
                }
            }
        }
        if (vistaSelected !== null) {
            await requestPost(
                'https://terminal.veltisrating.com:8888/api/updateVista',
                { id_vista: vistaSelected.key, columns: JSON.stringify(newArrat) }
            )
            message.info(`Columnas Actualizados en Vista: ${vistaSelected.label}`);
        }
    }

    const listaCarteras = () => {
        const datosretorno = [];
        for (const iterator of carteras) {
            datosretorno.push(
                {
                    label: iterator.name,
                    name: iterator.name,
                    key: `${iterator.id}`,
                }
            )
        }
        return datosretorno;
    }

    async function handleCarteraClick(e) {
        let contador = 0;
        for (const elemento of activoSelected) {
            await requestPost(
                'https://terminal.veltisrating.com:8888/api/addUserActivoToCartera',
                { cartera_id: e.key, refcat: elemento.full_refcat }
            )
            ++contador;
        }
        message.info(`Se han añadido ${contador} activo/s a cartera (${e.item.props.name})`);
        fetchCarteras(carteras, setCarteras);
    }


    async function borrarActivoCartera() {
        const refcat = [];
        const index = carteras.findIndex((objeto) => objeto.checked === true);
        const carteraSelected = carteras[index].id;
        for (const elemento of activoSelected) {
            refcat.push(elemento.full_refcat);
        }
        await RequestDelete(
            'https://terminal.veltisrating.com:8888/api/removeActivoCartera',
            { id_cartera: carteraSelected, refcat: refcat }
        )
        message.info(`Eliminación correcta, cartera: ${carteras[index].name}`)
        fetchCarteras(carteras, setCarteras);
    }

    useEffect(() => {
        let check = false;
        carteras.forEach((item) => {
            if (item.checked === true) {
                check = true;
            }
        })
        setCarteraSelected(check);
    }, [carteras])


    return (
        <>
            <Layout setACtivoSeletected={setACtivoSeletected} carteras={carteras} setCarteras={setCarteras} userName={userName} userData={userData} />
            {/*<Text1 carteras={carteras} setcarteras={setcarteras} />*/}
            <section className="el-container" style={{ height: 'calc(100vh)', overflowAnchor: 'none' }}>
                <main id="components-root" className="el-main auto-height-on-print capture-areaz scroll" style={{ overflow: 'auto', overflowAnchor: 'none' }}>
                    <div className="capture-area enable-darkmode page-container">
                        {/*
                        <div data-v-34f0d138 className="flex flex-nowrap step1 capture-area">
                            <div data-v-34f0d138>
                                <div data-v-34f0d138 className="aio-tabs-title flex flex-center justify-between cursor-text ">
                                    <div data-v-34f0d138>
                                        <WindowsOutlined />
                                        <span data-v-34f0d138>
                                            Screens

                                        </span>
                                        <span style={{ marginLeft: 5 }}>
                                            <Tooltip title="texto explicativo">
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </span>

                                    </div>
                                </div>
                            </div>
                            <div data-v-2a8a9290 data-v-34f0d138 className="hscroll" style={{ height: '36px' }}>
                                <div data-v-2a8a9290 className="scroll-container">
                                    <div data-v-2a8a9290 className="scroll-wrapper" style={{ left: '0px', padding: '0px', width: 'auto' }}>
                                        <span data-v-2a8a9290>
                                            <div className="aio-tabs-button el-popover__reference" aria-describedby="el-popover-941" tabIndex={0}>
                                                <Drop title={pantallaVeltisSelected !== null ? <>Pantalla Veltis:<b>{pantallaVeltisSelected.label}</b></> : "Pantallas Veltis"} canAdd={false} data={PantallasVeltis} tipo="veltis_pantallas" seleteced={pantallaVeltisSelected} setseletected={setpantallaVeltisSelected} setnoveltisselected={setPantallaSelected} />
                                            </div>
                                        </span>
                                        <span data-v-ccb51ac6 data-v-2a8a9290>
                                            <div data-v-ccb51ac6 className="aio-tabs-button el-popover__reference" aria-describedby="el-popover-8008" tabIndex={0} style={{ color: '#409eff' }}>
                                                <Drop title={pantallaSelected !== null ? <>Mis Pantallas:<b>{pantallaSelected.label}</b></> : "Mis Pantallas"} canAdd={true} data={PantallasClientes} tipo="mis_pantallas" seleteced={pantallaSelected} setseletected={setPantallaSelected} setnoveltisselected={setpantallaVeltisSelected} />
                                            </div>
                                        </span>
                                        {
                                            pantallaSelected !== null
                                                ?
                                                <Popconfirm
                                                    title="Delete the task"
                                                    description="Are you sure to delete this task?"
                                                    onConfirm={() => { borrarPantalla(pantallaSelected.key) }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <span className="limpiarFiltros1 margenizq" data-id={pantallaSelected.key}  ><CloseCircleOutlined style={{ fontSize: '12px', color: 'red' }} /> Borrar Pantalla</span>
                                                </Popconfirm>

                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}

                        <div data-v-13991ee7 className="step3 capture-area">
                            <div data-v-34f0d138 data-v-13991ee7 className="flex flex-nowrap">
                                <div data-v-34f0d138 onClick={() => {
                                    setOpenedFilters(!openedFilters)
                                    if (selectedCategoria === false) setselectedCategoria(1)
                                }}>
                                    <div data-v-34f0d138 className="aio-tabs-title flex flex-center justify-between cursor-pointer " style={{ marginTop: '15%' }}>
                                        <div data-v-34f0d138>
                                            <i data-v-34f0d138 className="gf-icon-filter" />
                                            <span data-v-34f0d138>Filtros</span>
                                        </div>
                                        <i data-v-34f0d138 className={openedFilters ? "t-primary m-r-sm gf-icon-chevron-up" : "t-primary m-r-sm gf-icon-chevron-down"} />
                                    </div>
                                </div>
                                <div className='filtrosYlimpiar'>
                                    <Tabs
                                        defaultActiveKey={"1"}
                                        tabPosition={'top'}
                                        onTabClick={openFilters}
                                        destroyInactiveTabPane={true}
                                        style={{
                                            height: openedFilters ? 350 : 75,
                                        }}
                                        items={usestateListaFiltros.map((categoria, indicecat) => {
                                            return {
                                                label: `${categoria.catname}`,
                                                key: categoria.catid,
                                                //children: <div className={openedFilters ? 'gridFiltros' : 'gridFiltros oculta'}><Filters1 eventoChange={eventoChange} categoria={categoria.id} indicadores={categoria.indicadores} /></div>,
                                                children: <div className={openedFilters ? 'gridFiltros' : 'gridFiltros oculta'}><Filters indicecat={indicecat} subcategorias={categoria.subcategorias} usestateListaFiltros={usestateListaFiltros} setusestateListaFiltros={setusestateListaFiltros} /></div>,
                                            };
                                        })}
                                    />
                                    {/*<div>
                                        <Space size={[0, 8]} wrap>
                                            {tags.map((tag, index) => {

                                                const isLongTag = tag.length > 20;
                                                const tagElem = (
                                                    <Tag
                                                        key={tag}
                                                        closable={index !== 0}
                                                        style={{
                                                            userSelect: 'none',
                                                        }}
                                                    // onClose={() => handleClose(tag)}
                                                    >
                                                        <span>
                                                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                                        </span>
                                                    </Tag>
                                                );
                                                return isLongTag ? (
                                                    <Tooltip title={tag} key={tag}>
                                                        {tagElem}
                                                    </Tooltip>
                                                ) : (
                                                    tagElem
                                                );
                                            })}
                                        </Space>
                                        </div>*/}
                                    <div style={{ margin: '0.5%', marginTop: '-2.5%', zIndex: 1 }}>
                                        <span onClick={borrarFiltros} data-categoria={selectedCategoria} className={openedFilters ? 'limpiarFiltros1' : 'limpiarFiltros1 oculta'}><CloseCircleOutlined style={{ fontSize: '12px', color: 'red' }} /> Restablecer</span>
                                        <span onClick={borrarTodoFiltros} className={openedFilters ? 'limpiarFiltros2' : 'limpiarFiltros2 oculta'}><CloseCircleOutlined style={{ fontSize: '12px', color: 'red' }} /> Restablecer Todos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-3e179206 className="screener-view step4 capture-area">
                            <div data-v-e4263f44 data-v-3e179206 className="aio-tabs" style={{ position: 'relative', marginTop: '-0.5%' }}>

                                <div data-v-e4263f44 className="aio-tabs-title cursor-pointer view-customize">
                                    <i data-v-e4263f44 className="gf-icon-columns" />
                                    <span data-v-e4263f44>
                                        <div onClick={abrirModal} style={{ display: 'inline-block', width: '60px', verticalAlign: 'bottom' }}>
                                            <div style={{ fontSize: '11px', lineHeight: '11px' }}> Campos </div>
                                            <div style={{ fontSize: '10px', lineHeight: '11px' }}> (Personalizados) </div>
                                        </div>
                                    </span>
                                    <i data-v-e4263f44 className="el-icon-caret-bottom" style={{ float: 'right', margin: '3px' }} />
                                </div>
                                {/* Componente Vistas */}
                                <Modal name="Vistas" open={modal} onCancel={cerrarModal} footer={
                                    vistaSelected ? <Button type="primary" style={{ display: 'flex', justifyContent: 'center' }} onClick={aplicarFiltros}>Guardar Columnas en Vista Activa "{vistaSelected.label}"</Button> : ""

                                } width={1000}>
                                    <div>
                                        <h2>Seleción Campos</h2>
                                        <br />
                                        <div>Campos seleccionados:</div>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            <BarraSeleccion selectedFilters={filtros} setSelectedFilters={setFiltros} />
                                            <Button onClick={borrarFiltross}>Borrar Todos</Button>
                                        </div>
                                        <br />
                                        <div>Buscador campos:</div>
                                        <Buscador filtros={filtros} setFiltros={setFiltros} />
                                        <br /><br />
                                        <div className='pantallaSelecFiltros'>
                                            <div className='pantallaIzda'>
                                                <Navegacion filtros={filtros} setFiltros={setFiltros} setSelectedItem={setSelectedItem} setoverSelectedItem={setoverSelectedItem} />
                                                <br />
                                            </div>
                                            <div className='pantallaDrch'>
                                                <Descripcion overSelectedItem={overSelectedItem} />
                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                <div data-v-2a8a9290 data-v-e4263f44 style={{ height: '36px', width: 'calc(100% - 114px)', display: 'inline-block', verticalAlign: 'top' }}>
                                    <div data-v-2a8a9290 className="scroll-container">
                                        <div data-v-2a8a9290 className="scroll-wrapper" style={{ left: '0px', padding: '0px', width: 'auto' }}>
                                            <span data-v-3e179206 data-v-2a8a9290>
                                                <a href="#" aria-current="page" className="aio-tabs-item nuxt-link-exact-active nuxt-link-active aio-tabs-selected"> Filtros Activos ({contadorFiltros} filtros y {activosInGrid.length} activos) <i className="aio-tabs-hint"> Guardar </i>
                                                </a>
                                                <div data-v-7116be8f className="el-dialog__wrapper gf dialog-no-header" style={{ display: 'none' }}>
                                                    <div role="dialog" aria-modal="true" aria-label="dialog" className="el-dialog gf aio-view-editor" style={{ marginTop: '5vh', width: '450px' }}>
                                                        <div className="el-dialog__header">
                                                            <span className="el-dialog__title" />
                                                            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                                                <i className="el-dialog__close el-icon el-icon-close" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                            { /*<a data-v-3e179206 href="#" className="aio-tabs-item" onClick={() => { verGrafica(1) }} is-default="" data-v-2a8a9290>
                                                <TableOutlined /> Grid
                                            </a>
                                            <a data-v-3e179206 href="#" className="aio-tabs-item" onClick={() => { verGrafica(3) }} is-default="" data-v-2a8a9290>
                                                <i className="el-icon-s-grid" /> Mapa
                                            </a>
                                            <a data-v-3e179206 href="#" className="aio-tabs-item" onClick={() => { verGrafica(2) }} is-default="" data-v-2a8a9290>
                                                <i className="gf-icon-chart-area" /> Gráfica
                                            </a>*/
                                            }
                                            <Segmented
                                                style={{ margin: 5, fontSize: 12 }}
                                                onChange={verGrafica}
                                                size="small"
                                                defaultValue={3}
                                                options={[
                                                    {
                                                        label: 'Tabla',
                                                        value: 1,
                                                        icon: <TableOutlined />,
                                                    },
                                                    {
                                                        label: 'Mapa',
                                                        value: 3,
                                                        icon: <i className="el-icon-s-grid" />,
                                                    },
                                                    /*{
                                                        label: 'Gráfica',
                                                        value: 2,
                                                        icon: <i className="gf-icon-chart-area" />,
                                                    },*/
                                                ]}
                                            />
                                            <span data-v-3e179206 data-v-2a8a9290>
                                                <div data-v-7116be8f className="el-dialog__wrapper gf dialog-no-header" style={{ display: 'none' }}>
                                                    <div role="dialog" aria-modal="true" aria-label="dialog" className="el-dialog gf aio-view-editor" style={{ marginTop: '5vh', width: '450px' }}>
                                                        <div className="el-dialog__header">
                                                            <span className="el-dialog__title" />
                                                            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                                                <i className="el-dialog__close el-icon el-icon-close" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                            <span data-v-b4a743b4 data-v-3e179206 is-default="" data-v-2a8a9290>  <span data-v-b4a743b4>
                                                <div role="tooltip" id="el-popover-3157" aria-hidden="true" className="el-popover el-popper aio-popover no-padding" tabIndex={0} style={{ display: 'none' }}></div>
                                                <div data-v-b4a743b4 className="aio-tabs-button el-popover__reference" aria-describedby="el-popover-3157" tabIndex={0}> <Drop title={vistaVeltisSelected !== null ? <>Vistas Veltis:<b>{vistaVeltisSelected.label}</b></> : "Vistas Veltis"} canAdd={false} tipo="veltis_vistas" seleteced={vistaVeltisSelected} setseletected={setvistaVeltisSelected} setnoveltisselected={setvistaSelected} />
                                                </div>
                                            </span>
                                            </span>
                                            <span data-v-63a53a5e data-v-3e179206 is-default="" data-v-2a8a9290>
                                                <div role="tooltip" id="el-popover-7202" aria-hidden="true" className="el-popover el-popper aio-popover no-padding" tabIndex={0} style={{ display: 'none' }}></div>
                                                <div data-v-63a53a5e className="aio-tabs-button el-popover__reference" aria-describedby="el-popover-7202" tabIndex={0}> <Drop title={vistaSelected !== null ? <>Mis Vistas:<b>{vistaSelected.label}</b></> : "Mis Vistas"} canAdd={true} tipo="mis_vistas" seleteced={vistaSelected} setseletected={setvistaSelected} setnoveltisselected={setvistaVeltisSelected} />
                                                </div>
                                            </span>
                                            {
                                                vistaSelected !== null
                                                    ?
                                                    <Popconfirm
                                                        title="Delete the task"
                                                        description="Are you sure to delete this task?"
                                                        onConfirm={() => { borrarVista(vistaSelected.key) }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <span className="limpiarFiltros1 margenizqdere"><CloseCircleOutlined style={{ fontSize: '12px', color: 'red' }} /> Borrar Vista</span>
                                                    </Popconfirm>

                                                    : ""
                                            }
                                            {/*<a data-v-3e179206 href="#" className="aio-tabs-item fw-bolder backtesting-btn" is-default="" data-v-2a8a9290>
                                                <i className="gf-icon-vials" /> Backtesting <span data-v-505fe70d className="plus-icon">P&nbsp;</span>
                                        </a>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-v-3e179206 className="el-dialog__wrapper gf" style={{ display: 'none' }}>
                                <div role="dialog" aria-modal="true" aria-label="dialog" className="el-dialog" style={{ marginTop: '10%', width: '850px' }}>
                                    <div className="el-dialog__header">
                                        <span className="el-dialog__title" />
                                        <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                            <i className="el-dialog__close el-icon el-icon-close" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section data-v-4cbe18f4 className="el-container table-root capture-area" exchange="AMEX,ARCA,BATS,GREY,IEXG,NAS,NYSE,OTCBB,OTCPK">
                            <section data-v-4cbe18f4 className="el-container">
                                <main data-v-4cbe18f4 className="el-main no-padding table-section">
                                    <div data-v-4cbe18f4 className="main-section" style={{ overflow: 'hidden', overflowAnchor: 'none' }}>

                                        <SplitPane
                                            split="vertical"
                                            minSizes={[200, 550]}
                                            initialSizes={localStorage.getItem('splitPos') !== '' ? JSON.parse(localStorage.getItem('splitPos')) : []}
                                            resizerOptions={{
                                                css: {
                                                    width: '10x',
                                                    background: 'rgba(0, 0, 0, 0.1)',
                                                },
                                                hoverCss: {
                                                    width: '30px',
                                                    background: '1px solid rgba(102, 194, 255, 0.5)',
                                                },
                                                grabberSize: '1rem',
                                            }}
                                            /*collapse={{
                                                collapseDirection: "right",
                                                overlayCss: { backgroundColor: "black" },
                                                buttonTransition: "zoom",
                                                buttonPositionOffset: -20,
                                                collapsedSize: 10,
                                                collapseTransitionTimeout: 350,
                                            }}*/
                                            collapse={false}
                                            hooks={{
                                                onChange: changeGrid,
                                                onCollapse: changeGrid,
                                                onSaveSizes: changeGrid,
                                            }}
                                        >
                                            <BodyInf varestado={varestado} gridSize={gridSize} setACtivoSeletected={setACtivoSeletected} filtros={filtros} setFiltros={setFiltros} carteras={carteras} setCarteras={setCarteras} usestateListaFiltros={usestateListaFiltros} setoverSelectedItem={setoverSelectedItem} overSelectedItem={overSelectedItem} activosInGrid={activosInGrid} setactivosInGrid={setactivosInGrid} />
                                            <InfPanel activoSelected={activoSelected !== null ? activoSelected[0] : null} carteras={carteras} setCarteras={setCarteras} />
                                        </SplitPane>
                                    </div>
                                </main>
                                <div data-v-4cbe18f4 id="data-table-stocky-footer" className="sticky-foot">
                                    <div data-v-2a8a9290 data-v-4cbe18f4 style={{ height: '24px', margin: '3px 0px', width: 210, display: 'inline-block' }}>
                                        <div data-v-2a8a9290 className="scroll-container">
                                            <div data-v-2a8a9290 className="scroll-wrapper" style={{ left: '0px', padding: '0px', width: 'auto' }}>
                                                <span data-v-7e9f3ef1 data-v-4cbe18f4 data-v-2a8a9290>
                                                    <span data-v-7e9f3ef1>
                                                        <Dropdown
                                                            menu={{
                                                                items: listaCarteras(),
                                                                onClick: handleCarteraClick,
                                                            }}
                                                            trigger={['click']}
                                                        >
                                                            <button style={activoSelected !== null && activoSelected.length > 0 ? { fontSize: 'small', color: 'blue', position: 'relative', left: '6px' } : { fontSize: 'small', color: 'transparent', cursor: 'default' }} /*onClick={handleCarteraClick}*/> Añadir activos seleccionados ({activoSelected.length}) </button>
                                                        </Dropdown>
                                                    </span>
                                                    <div data-v-7e9f3ef1 className="el-dialog__wrapper" style={{ display: 'none' }}>
                                                        <div role="dialog" aria-modal="true" aria-label="Create Portfolio" className="el-dialog gf" style={{ marginTop: '15vh' }}>
                                                            <div className="el-dialog__header">
                                                                <span className="el-dialog__title">Añadir a Cartera</span>
                                                                <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                                                    <i className="el-dialog__close el-icon el-icon-close" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: 'calc(100% - 300px)' }}>
                                        {/* la funcion borrarActivoCartera no tiene funcionalidad todavia */}
                                        <button style={activoSelected !== null && activoSelected.length > 0 && carteraSelected === true ? { fontSize: 'small', color: 'red', position: 'relative', left: '6px' } : { fontSize: 'small', color: 'transparent', cursor: 'default' }} onClick={borrarActivoCartera}><CloseCircleOutlined style={activoSelected !== null && activoSelected.length > 0 && carteraSelected === true ? { fontSize: '12px', color: 'red' } : { fontSize: '12px', color: 'transparent', cursor: 'default' }} /> Borrar Activo de la Cartera</button>
                                    </div>
                                    <span data-v-5ee715d9 data-v-4cbe18f4 className="right-float">

                                        <span data-v-5ee715d9 className="pagination-item fc-regular"> Total 12628 </span>

                                    </span>
                                </div>
                            </section>
                        </section>
                        <div />
                    </div>
                </main>
            </section >
        </>
    )
}

export default Body