import React, {  } from 'react';
import './Cee.css';
import { Button, Divider } from 'antd';

const Cee = ({ dataa }) => {


    if (dataa === null || dataa === undefined) return;

    const datos = dataa.data[0];

    const datosInferida = () => {
        if (datos.cee_real === null) return "INFERIDA";
        return 'OBTENIDA';
    }

    const calificacion = () => {
        if (datos.cee_real !== null) {
            return datos.cee_real;
        } else if (datos.cee_predicted !== null && datos.cee_predicted !== 'NULL') {
            return datos.cee_predicted;
        } else {
            return 'sin datos';
        }
    };

    let ceeConsumo = calificacion(), ceeEmisiones = calificacion();

    function cargarConsumoYEmisiones(zonaclima, cert) {
        let consumo = 'N/A', emisiones = 'N/A';

        if (zonaclima === 'A2') {
            switch (cert) {
                case 'A':
                    consumo = 2.80;
                    break;
                case 'B':
                    consumo = 4.05;
                    break;
                case 'C':
                    consumo = 7.10;
                    break;
                case 'D':
                    consumo = 11.55;
                    break;
                case 'E':
                    consumo = 24.80;
                    break;
                case 'F':
                    consumo = 37.70;
                    break;
                case 'G':
                    consumo = 40.00;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'A3') {
            switch (cert) {
                case 'A':
                    consumo = 2.90;
                    break;
                case 'B':
                    consumo = 4.15;
                    break;
                case 'C':
                    consumo = 7.30;
                    break;
                case 'D':
                    consumo = 11.95;
                    break;
                case 'E':
                    consumo = 23.70;
                    break;
                case 'F':
                    consumo = 34.80;
                    break;
                case 'G':
                    consumo = 36.90;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'A4') {
            switch (cert) {
                case 'A':
                    consumo = 3.20;
                    break;
                case 'B':
                    consumo = 4.65;
                    break;
                case 'C':
                    consumo = 8.20;
                    break;
                case 'D':
                    consumo = 13.35;
                    break;
                case 'E':
                    consumo = 25.80;
                    break;
                case 'F':
                    consumo = 36.80;
                    break;
                case 'G':
                    consumo = 38.80;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'B2') {
            switch (cert) {
                case 'A':
                    consumo = 3.70;
                    break;
                case 'B':
                    consumo = 5.30;
                    break;
                case 'C':
                    consumo = 11.60;
                    break;
                case 'D':
                    consumo = 15.25;
                    break;
                case 'E':
                    consumo = 32.00;
                    break;
                case 'F':
                    consumo = 48.15;
                    break;
                case 'G':
                    consumo = 51.10;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'B3') {
            switch (cert) {
                case 'A':
                    consumo = 3.60;
                    break;
                case 'B':
                    consumo = 8.20;
                    break;
                case 'C':
                    consumo = 9.15;
                    break;
                case 'D':
                    consumo = 15.00;
                    break;
                case 'E':
                    consumo = 30.00;
                    break;
                case 'F':
                    consumo = 44.20;
                    break;
                case 'G':
                    consumo = 46.90;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'B4') {
            switch (cert) {
                case 'A':
                    consumo = 4.40;
                    break;
                case 'B':
                    consumo = 6.05;
                    break;
                case 'C':
                    consumo = 10.10;
                    break;
                case 'D':
                    consumo = 16.10;
                    break;
                case 'E':
                    consumo = 31.90;
                    break;
                case 'F':
                    consumo = 46.10;
                    break;
                case 'G':
                    consumo = 48.10;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'C1') {
            switch (cert) {
                case 'A':
                    consumo = 5.40;
                    break;
                case 'B':
                    consumo = 7.10;
                    break;
                case 'C':
                    consumo = 11.25;
                    break;
                case 'D':
                    consumo = 17.35;
                    break;
                case 'E':
                    consumo = 33.45;
                    break;
                case 'F':
                    consumo = 65.45;
                    break;
                case 'G':
                    consumo = 55.00;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'C2') {
            switch (cert) {
                case 'A':
                    consumo = 6.10;
                    break;
                case 'B':
                    consumo = 8.00;
                    break;
                case 'C':
                    consumo = 12.60;
                    break;
                case 'D':
                    consumo = 19.40;
                    break;
                case 'E':
                    consumo = 36.25;
                    break;
                case 'F':
                    consumo = 53.15;
                    break;
                case 'G':
                    consumo = 57.30;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'C3') {
            switch (cert) {
                case 'A':
                    consumo = 5.60;
                    break;
                case 'B':
                    consumo = 7.65;
                    break;
                case 'C':
                    consumo = 12.75;
                    break;
                case 'D':
                    consumo = 20.25;
                    break;
                case 'E':
                    consumo = 38.55;
                    break;
                case 'F':
                    consumo = 55.80;
                    break;
                case 'G':
                    consumo = 59.20;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'C4') {
            switch (cert) {
                case 'A':
                    consumo = 6.00;
                    break;
                case 'B':
                    consumo = 8.20;
                    break;
                case 'C':
                    consumo = 13.65;
                    break;
                case 'D':
                    consumo = 21.70;
                    break;
                case 'E':
                    consumo = 40.70;
                    break;
                case 'F':
                    consumo = 58.50;
                    break;
                case 'G':
                    consumo = 62.10;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'D1') {
            switch (cert) {
                case 'A':
                    consumo = 8.40;
                    break;
                case 'B':
                    consumo = 10.65;
                    break;
                case 'C':
                    consumo = 16.10;
                    break;
                case 'D':
                    consumo = 24.00;
                    break;
                case 'E':
                    consumo = 44.30;
                    break;
                case 'F':
                    consumo = 65.85;
                    break;
                case 'G':
                    consumo = 71.80;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'D2') {
            switch (cert) {
                case 'A':
                    consumo = 7.90;
                    break;
                case 'B':
                    consumo = 10.40;
                    break;
                case 'C':
                    consumo = 16.45;
                    break;
                case 'D':
                    consumo = 25.35;
                    break;
                case 'E':
                    consumo = 46.85;
                    break;
                case 'F':
                    consumo = 68.35;
                    break;
                case 'G':
                    consumo = 73.70;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'D3') {
            switch (cert) {
                case 'A':
                    consumo = 8.40;
                    break;
                case 'B':
                    consumo = 11.00;
                    break;
                case 'C':
                    consumo = 17.35;
                    break;
                case 'D':
                    consumo = 26.75;
                    break;
                case 'E':
                    consumo = 49.35;
                    break;
                case 'F':
                    consumo = 72.95;
                    break;
                case 'G':
                    consumo = 79.60;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'E1') {
            switch (cert) {
                case 'A':
                    consumo = 10.40;
                    break;
                case 'B':
                    consumo = 13.25;
                    break;
                case 'C':
                    consumo = 20.05;
                    break;
                case 'D':
                    consumo = 29.85;
                    break;
                case 'E':
                    consumo = 59.30;
                    break;
                case 'F':
                    consumo = 89.95;
                    break;
                case 'G':
                    consumo = 97.00;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'a1') {
            switch (cert) {
                case 'A':
                    consumo = 2.10;
                    break;
                case 'B':
                    consumo = 3.05;
                    break;
                case 'C':
                    consumo = 5.40;
                    break;
                case 'D':
                    consumo = 8.80;
                    break;
                case 'E':
                    consumo = 13.10;
                    break;
                case 'F':
                    consumo = 16.40;
                    break;
                case 'G':
                    consumo = 17.40;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }

        if (zonaclima === 'A2') {
            switch (cert) {
                case 'A':
                    emisiones = 10.60;
                    break;
                case 'B':
                    emisiones = 15.35;
                    break;
                case 'C':
                    emisiones = 27.05;
                    break;
                case 'D':
                    emisiones = 44.25;
                    break;
                case 'E':
                    emisiones = 91.80;
                    break;
                case 'F':
                    emisiones = 134.90;
                    break;
                case 'G':
                    emisiones = 140.70;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'A3') {
            switch (cert) {
                case 'A':
                    emisiones = 12.30;
                    break;
                case 'B':
                    emisiones = 17.80
                    break;
                case 'C':
                    emisiones = 31.35;
                    break;
                case 'D':
                    emisiones = 51.25;
                    break;
                case 'E':
                    emisiones = 98.65;
                    break;
                case 'F':
                    emisiones = 140.20;
                    break;
                case 'G':
                    emisiones = 140.70;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'A4') {
            switch (cert) {
                case 'A':
                    emisiones = 13.70;
                    break;
                case 'B':
                    emisiones = 19.80;
                    break;
                case 'C':
                    emisiones = 34.85;
                    break;
                case 'D':
                    emisiones = 57.00;
                    break;
                case 'E':
                    emisiones = 107.40;
                    break;
                case 'F':
                    emisiones = 151.10
                    break;
                case 'G':
                    emisiones = 157.60;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'B2') {
            switch (cert) {
                case 'A':
                    emisiones = 14.20;
                    break;
                case 'B':
                    emisiones = 20.55;
                    break;
                case 'C':
                    emisiones = 36.20;
                    break;
                case 'D':
                    emisiones = 59.20;
                    break;
                case 'E':
                    emisiones = 119.20;
                    break;
                case 'F':
                    emisiones = 172.95
                    break;
                case 'G':
                    emisiones = 180.40;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'B3') {
            switch (cert) {
                case 'A':
                    emisiones = 15.60;
                    break;
                case 'B':
                    emisiones = 22.60;
                    break;
                case 'C':
                    emisiones = 39.80;
                    break;
                case 'D':
                    emisiones = 65.05;
                    break;
                case 'E':
                    emisiones = 126.90;
                    break;
                case 'F':
                    emisiones = 181.55;
                    break;
                case 'G':
                    emisiones = 189.40;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'B4') {
            switch (cert) {
                case 'A':
                    emisiones = 19.20;
                    break;
                case 'B':
                    emisiones = 26.15;
                    break;
                case 'C':
                    emisiones = 43.55;
                    break;
                case 'D':
                    emisiones = 69.40;
                    break;
                case 'E':
                    emisiones = 134.55;
                    break;
                case 'F':
                    emisiones = 192.60;
                    break;
                case 'G':
                    emisiones = 200.90;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'C1') {
            switch (cert) {
                case 'A':
                    emisiones = 24.20;
                    break;
                case 'B':
                    emisiones = 31.70;
                    break;
                case 'C':
                    emisiones = 49.95;
                    break;
                case 'D':
                    emisiones = 77.05;
                    break;
                case 'E':
                    emisiones = 146.70
                    break;
                case 'F':
                    emisiones = 213.00
                    break;
                case 'G':
                    emisiones = 226.00;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'C2') {
            switch (cert) {
                case 'A':
                    emisiones = 26.80;
                    break;
                case 'B':
                    emisiones = 35.10;
                    break;
                case 'C':
                    emisiones = 55.35;
                    break;
                case 'D':
                    emisiones = 85.40;
                    break;
                case 'E':
                    emisiones = 158.20;
                    break;
                case 'F':
                    emisiones = 226.70;
                    break;
                case 'G':
                    emisiones = 240.50;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'C3') {
            switch (cert) {
                case 'A':
                    emisiones = 24.50;
                    break;
                case 'B':
                    emisiones = 33.40;
                    break;
                case 'C':
                    emisiones = 55.70;
                    break;
                case 'D':
                    emisiones = 88.80;
                    break;
                case 'E':
                    emisiones = 167.60;
                    break;
                case 'F':
                    emisiones = 236.90;
                    break;
                case 'G':
                    emisiones = 247.10;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'C4') {
            switch (cert) {
                case 'A':
                    emisiones = 26.20;
                    break;
                case 'B':
                    emisiones = 35.70;
                    break;
                case 'C':
                    emisiones = 59.45;
                    break;
                case 'D':
                    emisiones = 94.75;
                    break;
                case 'E':
                    emisiones = 176.40;
                    break;
                case 'F':
                    emisiones = 252.40;
                    break;
                case 'G':
                    emisiones = 267.80;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'D1') {
            switch (cert) {
                case 'A':
                    emisiones = 37.50;
                    break;
                case 'B':
                    emisiones = 47.60;
                    break;
                case 'C':
                    emisiones = 70.90;
                    break;
                case 'D':
                    emisiones = 107.15;
                    break;
                case 'E':
                    emisiones = 200.05;
                    break;
                case 'F':
                    emisiones = 295.00;
                    break;
                case 'G':
                    emisiones = 318.10;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'D2') {
            switch (cert) {
                case 'A':
                    emisiones = 35.30;
                    break;
                case 'B':
                    emisiones = 46.25;
                    break;
                case 'C':
                    emisiones = 72.95;
                    break;
                case 'D':
                    emisiones = 112.50;
                    break;
                case 'E':
                    emisiones = 210.50;
                    break;
                case 'F':
                    emisiones = 308.90;
                    break;
                case 'G':
                    emisiones = 333.10;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'D3') {
            switch (cert) {
                case 'A':
                    emisiones = 37.10;
                    break;
                case 'B':
                    emisiones = 48.60;
                    break;
                case 'C':
                    emisiones = 76.65;
                    break;
                case 'D':
                    emisiones = 118.25;
                    break;
                case 'E':
                    emisiones = 220.70;
                    break;
                case 'F':
                    emisiones = 317.45;
                    break;
                case 'G':
                    emisiones = 336.80;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'E1') {
            switch (cert) {
                case 'A':
                    emisiones = 46.90;
                    break;
                case 'B':
                    emisiones = 59.50;
                    break;
                case 'C':
                    emisiones = 89.80;
                    break;
                case 'D':
                    emisiones = 133.80;
                    break;
                case 'E':
                    emisiones = 259.45;
                    break;
                case 'F':
                    emisiones = 389.30;
                    break;
                case 'G':
                    emisiones = 419.80;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }
        else if (zonaclima === 'a1') {
            switch (cert) {
                case 'A':
                    emisiones = 7.40;
                    break;
                case 'B':
                    emisiones = 10.70;
                    break;
                case 'C':
                    emisiones = 18.80;
                    break;
                case 'D':
                    emisiones = 30.75;
                    break;
                case 'E':
                    emisiones = 46.05;
                    break;
                case 'F':
                    emisiones = 56.65;
                    break;
                case 'G':
                    emisiones = 59.10;
                    break;
                default:
                    consumo = '';
                    break;
            }
        }

        return [consumo, emisiones];
    }

    const abrirNuevaPestaña = () => {
        window.open('https://terminal.veltisrating.com:8888/api/view_pdf_cee/' + dataa.data[0].full_refcat, '_blank');
    };

    return (
        <div style={{ paddingBottom: 70 }} className='moster'>
            <div style={{ display: 'flex', justifyContent: 'end', marginRight: 10, marginBottom: 10 }}>
                <Button type="primary" onClick={abrirNuevaPestaña}>
                    Abrir informe (CEE)
                </Button>
            </div>
            <Divider>CERTIFICADO DE EFICIENCIA ENERGÉTICA DE EDIFICIOS EXISTENTES</Divider>
            <h2 style={{ paddingTop: '11pt', paddingLeft: '15pt', textIndent: '0pt', textAlign: 'left' }}>IDENTIFICACIÓN DEL EDIFICIO O DE LA PARTE QUE SE CERTIFICA:</h2><br />
            <div style={{ width: 'auto', paddingRight: 20 }}>
                <table style={{ borderCollapse: 'collapse', marginLeft: '5.59843pt', width: '100%' }} cellSpacing={0}>
                    <tbody>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Nombre del edificio</p>
                            </td>
                            <td style={{ width: '312pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>VIVIENDA</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Dirección</p>
                            </td>
                            <td style={{ width: '312pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.via_tipo}. {datos.via_nombre}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '23pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingTop: '4pt', paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Municipio</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', paddingRight: '8pt', textIndent: '0pt', textAlign: 'left' }}>{datos.municipio_name}</p>
                            </td>
                            <td style={{ width: '114pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingTop: '4pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Código Postal</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingTop: '4pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.codigo_postal}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Provincia</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.provincia_name}</p>
                            </td>
                            <td style={{ width: '114pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Comunidad Autónoma</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}></p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Zona climática</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.zona_clima_mun}</p>
                            </td>
                            <td style={{ width: '114pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Año construcción</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.tabla14_antiguedad_efectiva}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '23pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'left' }}>Normativa vigente (construcción / rehabilitación)</p>
                            </td>
                            <td style={{ width: '312pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                                <p className="s2" style={{ paddingTop: '4pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>C.T.E.</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Referencia/s catastral/es</p>
                            </td>
                            <td style={{ width: '312pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.full_refcat}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                <br />
            </p>
            <div style={{ width: 'auto', paddingRight: 20 }}>
                <table style={{ borderCollapse: 'collapse', marginLeft: '5.59843pt', width: '100%' }} cellSpacing={0}>
                    <tbody>
                        <tr style={{ height: '14pt' }}>
                            <td style={{ width: '530pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingTop: '1pt', paddingLeft: '154pt', paddingRight: '154pt', textIndent: '0pt', textAlign: 'center' }}>Tipo de edificio o parte del edificio que se certifica:</p>
                            </td>
                        </tr>
                        <tr style={{ height: '14pt' }}>
                            <td style={{ width: '265pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <ul id="l1">
                                    <li data-list-text="○">
                                        <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '13pt', textIndent: '-7pt', textAlign: 'left' }}>Edificio de nueva construcción</p>
                                    </li>
                                </ul>
                            </td>
                            <td style={{ width: '265pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <ul id="l2">
                                    <li data-list-text="●">
                                        <p className="s2" style={{ paddingTop: '2pt', paddingLeft: '13pt', textIndent: '-8pt', textAlign: 'left' }}>Edificio Existente</p>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr style={{ height: '14pt' }}>
                            <td style={{ width: '530pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2} bgcolor="#FDE8D8">
                                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                                    <br />
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: '57pt' }}>
                            <td style={{ width: '265pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <ul id="l3">
                                    <li data-list-text="●">
                                        <p className="s2" style={{ paddingLeft: '22pt', textIndent: '-8pt', textAlign: 'left' }}>Vivienda</p>
                                        <ul id="l4">
                                            <li data-list-text="●">
                                                <p className="s2" style={{ paddingLeft: '36pt', textIndent: '-8pt', textAlign: 'left' }}>Unifamiliar</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul id="l5">
                                    <li data-list-text="○">
                                        <p className="s2" style={{ paddingTop: '1pt', paddingLeft: '36pt', textIndent: '-8pt', textAlign: 'left' }}>Bloque</p>
                                        <ul id="l6">
                                            <li data-list-text="○">
                                                <p className="s2" style={{ paddingTop: '1pt', paddingLeft: '50pt', textIndent: '-8pt', textAlign: 'left' }}>Bloque completo</p>
                                            </li>
                                            <li data-list-text="○">
                                                <p className="s2" style={{ paddingTop: '1pt', paddingLeft: '50pt', textIndent: '-8pt', lineHeight: '9pt', textAlign: 'left' }}>Vivienda individual</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </td>
                            <td style={{ width: '265pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <ul id="l7">
                                    <li data-list-text="○">
                                        <p className="s2" style={{ paddingLeft: '22pt', textIndent: '-7pt', textAlign: 'left' }}>Terciario</p>
                                        <ul id="l8">
                                            <li data-list-text="○">
                                                <p className="s2" style={{ paddingLeft: '36pt', textIndent: '-8pt', textAlign: 'left' }}>Edificio completo</p>
                                            </li>
                                            <li data-list-text="○">
                                                <p className="s2" style={{ paddingTop: '1pt', paddingLeft: '36pt', textIndent: '-8pt', textAlign: 'left' }}>Local</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                <br />
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <h2 style={{ paddingLeft: '15pt', textIndent: '0pt', textAlign: 'left' }}>CALIFICACIÓN ENERGÉTICA {datosInferida()} ({calificacion()}):</h2><br />
            <div style={{ width: 'auto', paddingRight: 20, display: 'flex', justifyContent: 'center' }}>
                <table style={{ borderCollapse: 'collapse' }} cellSpacing={0}>
                    <tbody>
                        <tr style={{ height: '33pt' }}>
                            <td style={{ width: '201pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '41pt', paddingRight: '40pt', textIndent: '0pt', lineHeight: '106%', textAlign: 'center' }}>CONSUMO DE ENERGÍA PRIMARIA NO RENOVABLE</p>
                                <p className="s1" style={{ paddingLeft: '41pt', paddingRight: '40pt', textIndent: '0pt', textAlign: 'center' }}>[kWh/m² año]</p>
                            </td>
                            <td style={{ width: '202pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={7} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '39pt', paddingRight: '40pt', textIndent: '0pt', lineHeight: '106%', textAlign: 'center' }}>EMISIONES DE DIÓXIDO DE CARBONO</p>
                                <p className="s1" style={{ paddingLeft: '39pt', paddingRight: '40pt', textIndent: '0pt', textAlign: 'center' }}>[kgCO2/ m² año]</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="s3" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">A</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeConsumo === 'A' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'A')[0]}</p>
                            </td>

                            <td style={{ width: '129pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                                <p className="s3" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">A</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeEmisiones === 'A' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'A')[1]}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="sb" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">B</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeConsumo === 'B' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'B')[0]}</p>
                            </td>

                            <td style={{ width: '129pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                                <p className="sb" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">B</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeEmisiones === 'B' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'B')[1]}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="sc" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">C</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeConsumo === 'C' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'C')[0]}</p>
                            </td>

                            <td style={{ width: '129pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                                <p className="sc" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">C</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeEmisiones === 'C' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'C')[1]}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="sd" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">D</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeConsumo === 'D' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'D')[0]}</p>
                            </td>

                            <td style={{ width: '129pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                                <p className="sd" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">D</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeEmisiones === 'D' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'D')[1]}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="se" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">E</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeConsumo === 'E' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'E')[0]}</p>
                            </td>

                            <td style={{ width: '129pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                                <p className="se" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">E</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeEmisiones === 'E' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'E')[1]}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="sf" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">F</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeConsumo === 'F' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'F')[0]}</p>
                            </td>

                            <td style={{ width: '129pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                                <p className="sf" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">F</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeEmisiones === 'F' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'F')[1]}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="sg" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">G</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeConsumo === 'G' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'G')[0]}</p>
                            </td>
                            <td style={{ width: '129pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6}>
                                <p className="sg" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">G</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={ceeEmisiones === 'G' ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>{cargarConsumoYEmisiones(datos.zona_clima_mun, 'G')[1]}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='tabla1'>
                <p>
                    <br />
                    <b style={{ fontWeight: 'bold' }}>ADVERTENCIA:</b> Los compradores de viviendas y las comunidades de vecinos deben tener en cuenta que a partir del 1 de enero del 2030 tienen que tener una clasificación mínima de letra E. Y para el 2033 su clasificación mínima deberá ser D. Esto supondrá a los propietarios y comunidades de vecinos el tener que abordar obras de acondicionamiento para poder cumplir con estos estándares. Además su incumplimiento puede conllevar sanciones como que Europa limitará la venta de viviendas que no cumplan con la directiva de eficiencia energética para el 2030.
                    <br />
                    Por otro lado los propietarios de activos y fondos de inversión que cuenten con viviendas poco contaminantes Verdes Dark Green o Light Green o sostenibles, se podrán aprovechar de beneficios relevantes pomo posibles prestamos verdes.
                    <br /><br />
                </p>
            </div>
        </div>
    )
}
export default Cee