import React from 'react'
import "./FilterCat.css";
import FilterSubCat from './FilterSubCat';

const FilterCat = ({ indicecat, subcategorias, usestateListaFiltros, setusestateListaFiltros }) => {
    
    return (
        <section className='FlexContainer'>
            {
                subcategorias.map((subcategoria, indicesubcategoria) => {
                    return <FilterSubCat key={indicesubcategoria} indicesubcategoria={indicesubcategoria} subcatindicador={subcategoria.subcatindicador} indicecat={indicecat} usestateListaFiltros={usestateListaFiltros} setusestateListaFiltros={setusestateListaFiltros} subcategoria={subcategoria} />;
                })
            }
        </section>

    )
}

export default FilterCat