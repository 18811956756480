import { AutoComplete, Button, Input, Modal, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import './ModalBuscador.css';
import logo4 from './logos/locationColor.png';
import { RequestGet, requestPost } from '../../hooks/Request/Request';

function ModalBuscador({ setACtivoSeletected }) {
    const [open, setOpen] = useState(false);
    const [municipio, setMunicipio] = useState('');
    const [via, setVia] = useState('');
    const [codMunicipio, setCodMunicipio] = useState(0);
    const [disabledText, setDisabledText] = useState(true);
    const [options, setOptions] = useState([]);
    const [options2, setOptions2] = useState([]);

    // funciones del modal
    const showModal = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    // funciones que activa el segundo input una vez se haya rellenado el primero y asigna el valor seleccionado a una variable para mostrar los activos de dicha via
    function handleMunicipioSelect(event) {
        const value = event;
        const municipio = value.slice(9);
        setMunicipio(municipio);
        const codMunicipio = value.slice(0, 5);
        setCodMunicipio(codMunicipio);
        setDisabledText(value === '');
        setVia('');
    };
    function handleViaSelect(event) {
        const value = event;
        setVia(value);
    };

    // funciones del buscador de municipios
    const handleSearch = (value) => {
        const laca = async () => {
            const response = await RequestGet(
                'https://terminal.veltisrating.com:8888/api/searchMunByName/' + value.toLowerCase(),
            )
            const retorno = [];
            if (response.data.length > 0) {
                for (const iterator of response.data) {
                    retorno.push(
                        {
                            value: `${iterator.id_mun} - ${iterator.name_mun}`,
                            label: (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                   
                                    <span>Municipio <span style={{ color: 'var(--color-primary-color)' }}>{iterator.name_mun}</span></span>
                                </div>
                            ),
                            datos: iterator,
                        }
                    )
                }
            }
            setOptions(retorno);
        }
        if (value.length < 3) return;
        laca();
    };

    //funciones del buscador de calles
    async function handleSearch2(value) {
        setOptions2([]);
        if (value.length < 3) return;
        const retorno = [];
        const response = await RequestGet(
            `https://terminal.veltisrating.com:8888/api/searchCalleByName/${codMunicipio}/${value.toLowerCase()}`,
        )
        if (response.data.length > 0) {
            for (const iterator of response.data) {
                retorno.push(
                    {
                        value: iterator.via_nombre,
                        label: (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>{iterator.via_tipo} <span style={{ color: 'var(--color-primary-color)' }}>{iterator.via_nombre}</span></span>
                            </div>
                        ),
                        datos: iterator,
                    }
                )
            }
        }
        setOptions2(retorno);
    };

    // funcion que solicita los datos de todos los inmuebles de la calle seleccionada
    const [resultBusqueda, setResultBusqueda] = useState([]);
    const [valorNumero, setValorNumero] = useState(false);
    async function resultadosBusqueda() {
        const response = await RequestGet(
            `https://terminal.veltisrating.com:8888/api/searchRefcatByCalleByMun/${codMunicipio}/${via.toLowerCase()}/${valorNumero}`
        )
        setResultBusqueda(response.data);
    }

    useEffect(() => {
        resultadosBusqueda();
    }, [, valorNumero]);

    // funcion que selecciona el activo de entre los mostrados en la busqueda y lo muestra en el panel de informacion
    async function seleccionarActivo(e) {
        const result = await requestPost(
            'https://terminal.veltisrating.com:8888/api/activo',
            { refcat: e.full_refcat }
        )
        setACtivoSeletected(result.data);
    }

    function changenumero(campo) {
        setValorNumero(campo.target.value)
    }

    const listaActivos = resultBusqueda.map((item) => {
        return (
            <button key={item.id} className='div-resultado-busqueda' onClick={() => seleccionarActivo(item)}>
                <div className='div-info-busqueda'>
                    <p><u style={{ textDecorationStyle: 'dotted', textUnderlineOffset: '5px' }}>{item.full_refcat}</u></p>
                    <p>{item.via_tipo} {item.via_nombre} {item.primer_num_portal}, {item.planta}º{item.puerta}</p>
                </div>
                <div className='div-info-busqueda2'>
                    <p>{item.sup_elememtos_constru_asociados}m&#178;</p>
                    {/*<p>%</p>*/}
                    <p>{item.antiguedad}</p>
                </div>
            </button>
        )
    })


    return (
        <>
            <Tooltip title="Buscar por dirección postal" placement="bottom">
                <Button style={{ background: 'var(--color-input-bg)', }} icon={<input type="image" onClick={showModal} className="imagenMaps" src={logo4} alt="Búsqueda por dirección" />} />
            </Tooltip>
            <Modal
                open={open}
                title="Búsqueda por dirección"
                onCancel={handleCancel}
                width={800}
                footer={[
                    <Button onClick={resultadosBusqueda}>Buscar</Button>
                ]}
            >
                <div className='contenido-modal'>
                    <div className='input-municipio'>
                        <h3>Municipio</h3>
                        <AutoComplete
                            options={options}
                            style={{
                                width: 500,
                            }}
                            placeholder="Introduzca el municipio"
                            onSearch={handleSearch}
                            onSelect={handleMunicipioSelect}
                        />
                    </div>
                    <div className='input-via-y-numero'>
                        <h3>Vía</h3>
                        <AutoComplete
                            options={options2}
                            style={{
                                width: 500,
                            }}
                            placeholder="Introduzca el nombre de la vía"
                            onSearch={handleSearch2}
                            onSelect={handleViaSelect}
                            disabled={disabledText}
                        />
                        <h3>Número</h3>
                        <Input placeholder="" className='input-numero' onChange={changenumero} />
                    </div>
                    <div className='input-direccion'>
                        <h3>Dirección interna: </h3>
                        <div className='div-direccion-interna'>
                            <h4>Bloque</h4>
                            <Input placeholder="" className='input-direccion-interna' />
                            <h4>Escalera</h4>
                            <Input placeholder="" className='input-direccion-interna' />
                            <h4>Planta</h4>
                            <Input placeholder="" className='input-direccion-interna' />
                            <h4>Puerta</h4>
                            <Input placeholder="" className='input-direccion-interna' />
                        </div>
                    </div>
                    <div style={{ maxHeight: '300px', whiteSpace: 'none', overflow: 'auto', scrollbarGutter: 'stable' }}>
                        {listaActivos}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ModalBuscador