import { message, Radio, Row, Col, Button, Result, Skeleton, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import ContentTabla from './ContentTabla';
import './ValoracionActivos.css';
import { RequestGet, requestPost } from '../../hooks/Request/Request';
import erro_img from './no_thumbnail.png'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ejemploActivo = {
    id: 0,
    nombret: 'PISO CON TRASTERO ANEJO Y GARAJE ANEXO EN EDIFICIO RESIDENCIAL TERMINADO',
    tipoCalle: 'Cl',
    calle: 'OBISPO PERALTA',
    datosCalle: '26, PL, 1B, PT, ES 3',
    municipio: 'Zaragoza',
    provincia: 'Zaragoza',
    codigoPostal: 50012,
    refCat: '2519412XM7121H0058XD',
    valorActivo: 0
}

const series = [
    {
        name: "Evolución Precio unitario",
        unit: "aaa",
        color: "#A1FF33",
        data: [
            1525.9,
            1743.7,
            2050,
            2089.4,
            2016.4,
            1922,
            1931,
            1968.7,
            1810.9,
            1656,
            1573.8,
            1540.3,
            1509.8,
            1526.8,
            1623.7,
            1706.1,
            1727.4,
            1788.3,
            1850.3,
            1960.5
        ],
        yAxis: 0,
    },
    {
        "name": "Tensión Precio Suelo/Vivienda",
        "color": "#FF3357",
        "data": [
            14.6,
            14.9,
            13,
            13.4,
            12.7,
            12.5,
            10.8,
            10.1,
            10,
            9.5,
            9.3,
            9.9,
            10.5,
            10.7,
            9.8,
            9.4,
            8.5,
            8.4,
            8.6,
            7.8
        ],
        "yAxis": 1
    },
    {
        "name": "Precio Suelo",
        "color": "#33A157",
        "data": [
            223.3,
            259.4,
            267.1,
            279.2,
            256.1,
            240.3,
            208.6,
            198.1,
            181.7,
            156.7,
            145.9,
            153.2,
            159.2,
            163.8,
            159.3,
            160.9,
            147,
            149.6,
            158.8,
            151.7
        ],
        "yAxis": 2
    }
];

const yAxis = [
    {
        "labels": {
            "style": {
                "color": "#A1FF33"
            }
        },
        "title": {
            "text": "Evolución Precio unitario (Eur/m2)",
            "style": {
                "color": "#A1FF33"
            }
        }
    },
    {
        "labels": {
            "style": {
                "color": "#FF3357"
            }
        },
        "title": {
            "text": "Tensión Precio Suelo/Vivienda (%)",
            "style": {
                "color": "#FF3357"
            }
        }
    },
    {
        "labels": {
            "style": {
                "color": "#33A157"
            }
        },
        "title": {
            "text": "Precio Suelo (Eur/m2)",
            "style": {
                "color": "#33A157"
            }
        }
    }
];


const Valoracion = ({ dataa }) => {
    const [acceso, setAcceso] = useState(true);
    const [selectedOption, setSelectedOption] = useState('valoracion');
    const [selectedOption2, setSelectedOption2] = useState('valoracion');
    const [tasaciondata, setTasaciondata] = useState({ "id": "", "predict": { "avg": { "gross": null, "perSq": null }, }, "predictRent": { "avg": { "gross": null, "perSq": null }, } });
    const [isLoading, setIsLoading] = useState(false)
    const [botonOn, setBotonOn] = useState(true)
    const [historicoTasacion, setHistoricoTasacion] = useState([])
    const [historicoTasacionAVM, setHistoricoTasacionAVM] = useState([])


    let imgError = erro_img;
    const [imgResFachada, setImgResFachada] = useState('');
    let imgFachada = `https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral=${dataa.data[0].full_refcat.substring(0, 14)}`;
    const [imgResPlano, setImgResPlano] = useState('');
    let imgPlano = `https://www1.sedecatastro.gob.es/Cartografia/GeneraGraficoParcela.aspx?del=${dataa.data[0].codigo_provincia}&mun=${dataa.data[0].codigo_municipio}&refcat=${dataa.data[0].full_refcat.substring(0, 14)}&AnchoPixels=120&AltoPixels=120`;

    useEffect(() => {
        setImgResFachada(imgError);
        setImgResPlano(imgError);
        checkIfImageExists(imgFachada, (exists) => {
            if (exists) {
                setImgResFachada(imgFachada);
            } else {
                setImgResFachada(imgError);
            }
        });

        checkIfImageExists(imgPlano, (exists) => {
            if (exists) {
                setImgResPlano(imgPlano);
            } else {
                setImgResPlano(imgError);
            }
        });
    }, [imgFachada, imgPlano, imgError]);

    function checkIfImageExists(url, callback) {
        const img = new Image();
        img.src = url;

        if (img.complete) {
            callback(true);
        } else {
            img.onload = () => {
                callback(true);
            };

            img.onerror = () => {
                callback(false);
            };
        }
    }

    useEffect(() => {
        setIsLoading(true)
        async function tasacionDataAWM() {
            const arrayDatos = [];
            const data = await RequestGet('https://terminal.veltisrating.com:8888/api/getActivoHistTasacionesAWM/' + refCat)
            data.data.forEach(item => {
                // eliminar información sobrante y dar formato españa a la fecha para que aparezca dd/mm/yyyy
                let dateFormat = item.user_hist_awm_date.substring(0, 10).split('-');
                let date = new Date(dateFormat[0], dateFormat[1] - 1, dateFormat[2]);
                const formattedDate = date.toLocaleDateString('es-ES');

                const dato = {
                    key: item.refcat_tasacion,
                    fecha_tasacion: formattedDate,
                    valor_tasacion: item.user_hist_awm_price,
                }
                arrayDatos.push(dato);
            })
            setHistoricoTasacionAVM(arrayDatos);
        }

        if (!botonOn && dataa !== null && dataa !== undefined) {
            const handleClickTest = async () => {
                const datosPost = {
                    "fields": {
                        "address": `${dataa.data[0].via_tipo} ${dataa.data[0].via_nombre}, ${dataa.data[0].primer_num_portal}, ${dataa.data[0].codigo_postal}, ${dataa.data[0].municipio_name}, Spain`,
                        "latitude": dataa.data[0].latitude,
                        "longitude": dataa.data[0].longitude,
                        "assetType": "apartment",
                        "condition": "used",
                        "grossArea": parseInt(dataa.data[0].sup_elememtos_constru_asociados),
                        "numberOfRooms": parseInt(dataa.data[0].sup_elememtos_constru_asociados) > 120 ? 2 : 1,
                        "buildingYear": parseInt(dataa.data[0].antiguedad),
                    },
                    /*"characteristics": [

                    ],*/
                    "images": [
                        "https://via.placeholder.com/500",
                        "https://via.placeholder.com/300.png/09f/fff"
                    ],
                    "status": {
                        "date": "2023-06-16 00:00:00",
                        "status": "estimated"
                    }
                };

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer C43562i+eTOtG6zctbuifBKLRIXU/1jasZfLr2B0Om3c2IwsoNYX0Nrz4X/3xrEa' },
                    body: JSON.stringify(datosPost)
                };

                const response = await fetch('https://v2.sys.alfredo.pt/api/estimate', requestOptions).then(response => response.json());
                setTasaciondata(response);

                const responseRequestAddTasacionAWM = await requestPost(
                    'https://terminal.veltisrating.com:8888/api/addActivoHistTasacionesAWM',
                    {
                        refcat: dataa.data[0].full_refcat,
                        precio: response.predict.avg.gross
                    })

                tasacionDataAWM();
                setIsLoading(false);
            };

            handleClickTest();
        } else {
            setIsLoading(false);
        }
    }, [botonOn]);

    useEffect(() => {
        setBotonOn(true);
    }, [dataa]);

    const refCat = dataa.data[0].full_refcat; // cambiar por el dato obtenido de dataa.data[0].full_refCat

    // fetch para obtener el valor historico de las tasaciones del cliente
    useEffect(() => {
        setIsLoading(true);
        async function tasacionData() {
            const arrayDatos = [];
            const data = await RequestGet('https://terminal.veltisrating.com:8888/api/getActivoHistTasaciones/' + refCat)
            data.data.forEach(item => {
                // eliminar información sobrante y dar formato españa a la fecha para que aparezca dd/mm/yyyy
                let dateFormat = item.fecha_tasacion.substring(0, 10).split('-');
                let date = new Date(dateFormat[0], dateFormat[1] - 1, dateFormat[2]);
                const formattedDate = date.toLocaleDateString('es-ES');

                const dato = {
                    key: item.refcat_tasacion,
                    fecha_tasacion: formattedDate,
                    valor_tasacion: item.valor_tasacion,
                }
                arrayDatos.push(dato);
            })
            setIsLoading(false);
            setHistoricoTasacion(arrayDatos);
        }

        async function tasacionDataAWM() {
            const arrayDatos = [];
            const data = await RequestGet('https://terminal.veltisrating.com:8888/api/getActivoHistTasacionesAWM/' + refCat)
            data.data.forEach(item => {
                // eliminar información sobrante y dar formato españa a la fecha para que aparezca dd/mm/yyyy
                let dateFormat = item.user_hist_awm_date.substring(0, 10).split('-');
                let date = new Date(dateFormat[0], dateFormat[1] - 1, dateFormat[2]);
                const formattedDate = date.toLocaleDateString('es-ES');

                const dato = {
                    key: item.refcat_tasacion,
                    fecha_tasacion: formattedDate,
                    valor_tasacion: item.user_hist_awm_price,
                }
                arrayDatos.push(dato);
            })
            setHistoricoTasacionAVM(arrayDatos);
        }

        if (refCat !== null && refCat !== undefined) {
            tasacionData();
            tasacionDataAWM();
        }

    }, [dataa]);

    // con los datos obtenidos en el fetch anterior se rellenan los datos de la tabla dandoles formato a la fecha año/mes/dia y el valor
    const historico = historicoTasacion.map(item => {
        return (
            /* el key se pone con un numero random ya que los datos comparten refCat */
            <tr key={Math.floor(Math.random() * 99)}>
                <td>{item.key}</td>
                <td>{item.fecha_tasacion}</td>
                <td>{new Intl.NumberFormat('en-DE').format(Math.trunc(item.valor_tasacion))}€</td>
            </tr>
        )
    });

    const historicoAWM = historicoTasacionAVM.map(item => {
        return (
            /* el key se pone con un numero random ya que los datos comparten refCat */
            <tr key={Math.floor(Math.random() * 99)}>
                <td>{item.key}</td>
                <td>{item.fecha_tasacion}</td>
                <td>{new Intl.NumberFormat('en-DE').format(Math.trunc(item.valor_tasacion))}€</td>
            </tr>
        )
    })

    function historicoValorReferencia() {
        if (parseFloat(dataa.data[0].valor_ref_zona_dgc) > 0) {
            return (
                /* el key se pone con un numero random ya que los datos comparten refCat */
                <tr key={Math.floor(Math.random() * 99)}>
                    <td>{dataa.data[0].full_refcat}</td>
                    <td>{2023}</td>
                    <td>{new Intl.NumberFormat('en-DE').format(Math.trunc(dataa.data[0].valor_ref_zona_dgc * dataa.data[0].sup_elememtos_constru_asociados))}€</td>
                </tr>
            )
        }
        else {
            return (
                /* el key se pone con un numero random ya que los datos comparten refCat */
                <tr key={Math.floor(Math.random() * 99)}>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            )
        }

    }

    if (dataa === null || dataa === undefined) return ('');

    const confirm = (e) => {
        message.success('Confirmación de acceso aceptada.');
        setAcceso(true);
    };
    const cancel = (e) => {
        message.warning('Petición cancelada.');
    };
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const item = dataa.data[0]
    var valorMercado = item['V.MERCADO'];
    var superficie = item.sup_elememtos_constru_asociados;

    async function solicitarTasacionHandle() {
        const response = await RequestGet(
            'https://terminal.veltisrating.com:8888/api/sendRequestTasacion/' + dataa.data[0].full_refcat,
        )
        console.log(response)
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <div className='ValoracionTop'>
                <div className='foto'>
                    <img src={imgResFachada} alt="foto" style={{ width: 545, height: 'auto' }} className='imgcat' />
                </div>
                <div style={{ marginLeft: 10 }}>
                    <div className='datosActivo'>
                        <h2> {dataa.data[0].nombret} </h2>
                        <div className='datosCalle'>
                            <h3><b style={{ fontWeight: '700', fontSize: '105%' }}>Dirección:</b> {dataa.data[0].via_tipo} {dataa.data[0].via_nombre} {dataa.data[0].bloque !== '' && dataa.data[0].bloque !== null ? 'bloque ' + dataa.data[0].bloque : ''} {dataa.data[0].primer_num_portal} {dataa.data[0].segundo_numero_portal !== 0 ? dataa.data[0].segundo_numero_portal : ''}, {dataa.data[0].escalera !== 0 && dataa.data[0].escalera !== null ? 'escalera ' + dataa.data[0].escalera + ',' : ''} {dataa.data[0].planta}{dataa.data[0].puerta}</h3>
                            <h3>{dataa.data[0].codigo_postal} {dataa.data[0].municipio_name} &#40;{dataa.data[0].provincia_name}&#41;</h3>
                        </div>
                        <div className='datosCat'>
                            <h3><b style={{ fontWeight: '700', fontSize: '105%' }}>Ref Cat:</b> {dataa.data[0].full_refcat}</h3>
                        </div>
                    </div>
                    <Row style={{ paddingTop: '10px' }}>
                        <Col flex="1 1 170px" >
                            <div className='precio'>
                                <h3 style={{ fontWeight: 'bold' }}>Valor de Tasación</h3>
                                <div className='valoracionVenta'>
                                    <h4>{valorMercado ? new Intl.NumberFormat('en-DE').format(Math.trunc(valorMercado)) + "€" : "N/A"}</h4>
                                    <p>{valorMercado ? new Intl.NumberFormat('en-DE').format(Math.trunc(valorMercado / superficie)) + "€/m2" : "N/A"}</p>
                                </div>
                            </div>
                        </Col>
                        <Col flex="1 1 130px" >
                            <div className='precio'>
                                <div>
                                    <h3>Valor de mercado AVM</h3>
                                    {botonOn ?
                                        <Button type="primary" onClick={() => setBotonOn(!botonOn)}>Ver valores</Button>
                                        :
                                        <div className='valoracionVenta'>
                                            {isLoading ?
                                                <p> cargando...</p>
                                                :
                                                <>
                                                    <h4>{new Intl.NumberFormat('en-DE').format(Math.trunc(tasaciondata.predict.avg.gross))}€</h4>
                                                    <p>{new Intl.NumberFormat('en-DE').format(Math.trunc(tasaciondata.predict.avg.perSq))}€/m2</p>
                                                </>
                                            }
                                        </div>

                                    }
                                </div>
                            </div>
                        </Col>
                        <Col flex="1 1 130px" >
                            <div className='precio'>
                                <h3>Renta de mercado</h3>
                                {botonOn ?
                                    <Button type="primary" onClick={() => setBotonOn(!botonOn)}>Ver valores</Button>
                                    :
                                    <div className='valoracionVenta'>
                                        {isLoading ?
                                            <p> cargando...</p>
                                            :
                                            <>
                                                <h4>{new Intl.NumberFormat('en-DE').format(Math.trunc(tasaciondata.predictRent.avg.gross))}€</h4>
                                                <p>{new Intl.NumberFormat('en-DE').format(Math.trunc(tasaciondata.predictRent.avg.perSq))}€/m2</p>
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row >

                </div>
            </div>

            <>
                <div style={{ display: 'flex', justifyContent: 'end', marginRight: '10px' }}>
                    <Radio.Group onChange={handleOptionChange} value={selectedOption}>
                        <Radio.Button value="valoracion">Valoración</Radio.Button>
                        <Radio.Button value="caracteristicas">Características</Radio.Button>
                    </Radio.Group>
                </div>
                <Divider orientation="left" style={{ marginBottom: '2%' }}>Histórico: Tasaciones (AVM)</Divider>
                {isLoading ?
                    <Skeleton />
                    :
                    historicoTasacionAVM.length === 0 ?
                        <div>
                            <Result
                                icon={null}
                                subTitle="Tasaciones previas no disponibles"
                            />
                        </div>
                        :
                        <div>
                            <table className='tabla-historico-tasacion'>
                                <thead>
                                    <th>Referencia</th>
                                    <th>Fecha Tasación</th>
                                    <th>Valor Tasación</th>
                                </thead>
                                <tbody>
                                    {historicoAWM}
                                </tbody>
                            </table>
                        </div>
                }
                <Divider orientation="left" style={{ marginBottom: '2%' }}>Histórico: Tasaciones (ECO)</Divider>
                {isLoading ?
                    <Skeleton />
                    :
                    historicoTasacion.length === 0 ?
                        <div>
                            <Result
                                icon={null}
                                subTitle="Tasaciones previas no disponibles"
                            />
                        </div>
                        :
                        <div>
                            <table className='tabla-historico-tasacion'>
                                <thead>
                                    <th>Referencia</th>
                                    <th>Fecha Tasación</th>
                                    <th>Valor Tasación</th>
                                </thead>
                                <tbody>
                                    {historico}
                                </tbody>
                            </table>
                        </div>
                }
                <Divider orientation="left" style={{ marginBottom: '2%' }}>Histórico: Valor Medio Castastral</Divider>
                <div>
                    <table className='tabla-historico-tasacion'>
                        <thead>
                            <th>Referencia</th>
                            <th>Año Valoración</th>
                            <th>Valoración</th>
                        </thead>
                        <tbody>
                            {historicoValorReferencia()}
                        </tbody>
                    </table>
                </div>
                <Divider orientation="left">Información Tasación</Divider>
                {dataa.data[0].REFCATASTR !== null ?

                    <div style={{ paddingBottom: '30px' }}>
                        {isLoading ? (
                            <Skeleton />
                        ) : (
                            <ContentTabla selectedOption={selectedOption} dataa={dataa} />
                        )}
                    </div>
                    :
                    <div>
                        <Result
                            //status=""
                            icon={null}
                            subTitle="Información de tasación no disponible"
                            extra={
                                <Button type="primary" key="console" onClick={solicitarTasacionHandle}>
                                    Solicitar tasación
                                </Button>
                            }
                        />

                    </div>
                }
                <Divider orientation="left" style={{ marginBottom: '2%' }}>Gráficas Precio</Divider>
                <div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            series,
                            yAxis,
                            xAxis: {
                                categories: ['2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
                            },
                            title: { text: '' },
                            credits: { enabled: false },
                            tooltip: {
                                formatter: function () {
                                    return 'The value for <b>' + this.series.unit + '</b> is <b>' + this.y + '</b>, in series ' + this.series.name;
                                }
                            }
                        }} />
                </div>
            </>
        </div>
    );
};
export default Valoracion;