import React, { useState } from 'react'
import { Select } from 'antd';
const { Option } = Select;

function Selector({ options, setOptions }) {

    const [filteredOptions, setFilteredOptions] = useState(options);

    function handleOption(e) {
        const optionsTmp = [...options];
        const index = optionsTmp.findIndex((objeto) => objeto.id === e);
        optionsTmp.forEach(item => item.activated = false);
        optionsTmp[index].activated = true;
        setOptions(optionsTmp);
    }

    function onSearch(value) {
        const filtered = options.filter((option) =>
            option.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    const opciones = filteredOptions.map((item) => {
        return (
            <Option key={item.id} value={item.id}>{item.name}</Option>
        )
    })

    return (
        <Select
            showSearch
            placeholder="Seleccione una leyenda"
            optionFilterProp="children"
            onChange={handleOption}
            onSearch={onSearch}
            style={{width:'250px'}}
        >
        {opciones}
        </Select>
    );
}

export default Selector