import React from 'react'
import logo3 from './logos/medium.png'
import logo4 from './logos/gas_radon_01-e1628241875871.jpg'

const Affecciones = () => {
    return (
        <div style={{ paddingBottom: 70 }}>
            <center>
                <img src={logo3} style={{ width: 300, height: 'auto' }} alt='logo3'/> <br /><br />
                <img src={logo4} style={{ width: '100%', height: 'auto' }} alt='logo4'/>
            </center>
        </div>
    )
}

export default Affecciones