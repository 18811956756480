import { RequestGet } from "../Request/Request";

async function fetchCarteras (carteras, setCarteras) {
    // get the data from the api
    const data = await RequestGet('https://terminal.veltisrating.com:8888/api/getUserCarteras');
    // convert data to json
    //const json = await data.json();
    const arrCarteras = [];
    let i = 0;
    for (const iterator of data.data) {
        arrCarteras.push({
            id: iterator.cartera_id,
            cartera_id: iterator.cartera_id,
            name: iterator.cartera_title,
            cartera_cnt_activos: iterator.cartera_cnt_activos,
            cartera_valor_activos: iterator.cartera_valor_activos,
            cartera_avg_rating_activos: iterator.cartera_avg_rating_activos,
            cartera_avg_var_activos: iterator.cartera_avg_var_activos,
            checked: false
        });
        ++i;
    }
    // check if there is any wallet already checked and mantain the checked = true
    const index = carteras.findIndex((objeto) => objeto.checked === true);
    if ( index !== -1 ) {
        const carteraId = carteras[index].id;
        const newIndex = arrCarteras.findIndex((objeto) => objeto.id === carteraId);
        arrCarteras[newIndex].checked = true;
    }
    setCarteras(arrCarteras);
}

export {fetchCarteras}