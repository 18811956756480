import React, { useState } from 'react';
import './Cee.css';
import { Button, Collapse, Divider, Skeleton } from 'antd';

const Taxonomia = ({ dataa }) => {

    const [cargado, setCargado] = useState(false);

    if (dataa === null || dataa === undefined) return;

    const datos = dataa.data[0];

    const datosInferida = () => {
        if (datos.cee_real === null) return "INFERIDA";
        return 'OBTENIDA';
    }

    const calificacion = () => {
        if (datos.cee_real !== null) {
            return datos.cee_real;
        } else if (datos.cee_predicted !== null && datos.cee_predicted !== 'NULL') {
            return datos.cee_predicted;
        } else {
            return 'sin datos';
        }
    };

    let ceeConsumo = calificacion(), ceeEmisiones = calificacion();
    let val_taxonomia = datos.taxonomia;

    function onChange(key) {
        setCargado(false);
        // añadir la carga de datos desde la api cuando se pulse el desplegable y que el skeleton aparezca o desaparezca
        setCargado(true);
    }

    const abrirNuevaPestaña = () => {
        window.open('https://terminal.veltisrating.com:8888/api/view_pdf_taxonomia/' + dataa.data[0].full_refcat, '_blank');
    };

    return (
        <div style={{ paddingBottom: 70 }} className='moster'>
            <div style={{ display: 'flex', justifyContent: 'end', marginRight: 10, marginBottom: 10 }}>
                <Button type="primary" onClick={abrirNuevaPestaña}>
                    Abrir informe (Taxonomía)
                </Button>
            </div>
            <Divider>TAXONOMÍA DE VIVIENDAS EXISTENTES</Divider>
            <h2 style={{ paddingTop: '11pt', paddingLeft: '15pt', textIndent: '0pt', textAlign: 'left' }}>IDENTIFICACIÓN DEL EDIFICIO O DE LA PARTE QUE SE CERTIFICA:</h2><br />
            <div style={{ width: 'auto', paddingRight: 20 }}>
                <table style={{ borderCollapse: 'collapse', marginLeft: '5.59843pt', width: '100%' }} cellSpacing={0}>
                    <tbody>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Nombre del edificio</p>
                            </td>
                            <td style={{ width: '312pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>VIVIENDA</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Dirección</p>
                            </td>
                            <td style={{ width: '312pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.via_tipo}. {datos.via_nombre}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '23pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingTop: '4pt', paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Municipio</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', paddingRight: '8pt', textIndent: '0pt', textAlign: 'left' }}>{datos.municipio_name}</p>
                            </td>
                            <td style={{ width: '114pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingTop: '4pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Código Postal</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingTop: '4pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.codigo_postal}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Provincia</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.provincia_name}</p>
                            </td>
                            <td style={{ width: '114pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Comunidad Autónoma</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}></p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Zona climática</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{dataa.data[0].zona_clima_mun}</p>
                            </td>
                            <td style={{ width: '114pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Año construcción</p>
                            </td>
                            <td style={{ width: '99pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.tabla14_antiguedad_efectiva}</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '218pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} bgcolor="#FDE8D8">
                                <p className="s1" style={{ paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Referencia/s catastral/es</p>
                            </td>
                            <td style={{ width: '312pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={3}>
                                <p className="s2" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>{datos.full_refcat}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                <br />
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <h2 style={{ paddingLeft: '15pt', textIndent: '0pt', textAlign: 'left' }}>RESULTADO TAXONOMÍA:</h2><br />
            <div style={{ width: 'auto', paddingRight: 20, display: 'flex', justifyContent: 'center' }}>
                <table style={{ borderCollapse: 'collapse' }} cellSpacing={0}>
                    <tbody>
                        <tr style={{ height: '33pt' }}>
                            <td style={{ width: '201pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={6} bgcolor="#FDE8D8">
                                <p className="s1" style={{ textIndent: '0pt', paddingTop: '10pt', textAlign: 'center' }}>Taxonomía</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="s3" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">Verde</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={val_taxonomia === 2 ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>&nbsp;</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="sd" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">Sostenible</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={val_taxonomia === 1 ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>&nbsp;</p>
                            </td>
                        </tr>
                        <tr style={{ height: '13pt' }}>
                            <td style={{ width: '128pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={5}>
                                <p className="sg" style={{ paddingTop: '3pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'left' }}><span className="s4">No Sostenible</span>
                                </p>
                            </td>
                            <td style={{ width: '73pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                                <p className={val_taxonomia === 0 ? "s5" : "s5 none"} style={{ paddingTop: '3pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>&nbsp;</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p style={{ fontSize: 10 }}>
                <p><span style={{ fontWeight: 'bold' }}>ADVERTENCIA:</span></p>
                <p>Los compradores de viviendas y las comunidades de vecinos deben tener en cuenta que a partir del 1 de enero del 2030 tienen que tener una clasificación mínima de letra E. Y para el 2033 su clasificación mínima deberá ser D. Esto supondrá a los propietarios y comunidades de vecinos el tener que abordar obras de acondicionamiento para poder cumplir con estos estándares. Además su incumplimiento puede conllevar sanciones como que Europa limitará la venta de viviendas que no cumplan con la directiva de eficiencia energética para el 2030.</p>
                <p>Por otro lado los propietarios de activos y fondos de inversión que cuenten con viviendas poco contaminantes Verdes Dark Green o Light Green o sostenibles, se podrán aprovechar de beneficios relevantes pomo posibles prestamos verdes.</p>

            </p>
            {
                /*<Collapse items={anexos} onChange={onChange} style={{marginTop: '5%'}}/>*/
            }

        </div>
    )
}
export default Taxonomia