import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import './TablaDesplegable.css';
import { SearchOutlined } from '@ant-design/icons';
import { RatingData } from '../../hooks/Rating/Rating';

const { Text } = Typography;


const ExpandableTable = ({ dataa, carteras, setCarteras }) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [carteraSelected, setcarteraSelected] = useState(null)

    function handleClick(key){
        const carterastmp = carteras.map(objeto => ({ ...objeto }));
        const index = carterastmp.findIndex((objeto) => objeto.id === key);
        setcarteraSelected(carterastmp[index].id);
        const checkedd = carterastmp[index].checked;
        carterastmp.forEach(objeto => objeto.checked = false);
        if (checkedd) {
            carterastmp[index].checked = false;
        }
        else {
            carterastmp[index].checked = true;
        }
        setCarteras(carterastmp);
    }

    useEffect(() => {
        const carterastmp = carteras.map(objeto => ({ ...objeto }));
        const arrayFiltrado = carterastmp.filter(cartera => cartera.checked === true);
        if (arrayFiltrado !== null && arrayFiltrado.length > 0) {
            setcarteraSelected(arrayFiltrado[0].id);
        }

    }, [carteras])

    

    const data = carteras.map((cartera) => {
        return {
            key: cartera.id,
            name: cartera.name,
            description: '',
            rating: cartera.cartera_avg_rating_activos ?  RatingData(cartera.cartera_avg_rating_activos).valorRating : "N/A",
            cartera_cnt_activos: cartera.cartera_cnt_activos,
            cartera_valor_activos: new Intl.NumberFormat('en-DE').format(Math.trunc(cartera.cartera_valor_activos))+"€",
            cartera_avg_var_activos: cartera.cartera_avg_var_activos,
            ver: <SearchOutlined onClick={() => handleClick(cartera.id)} />
        };
    });


    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            align: 'center'
        },
        {
            title: 'Total Activos',
            dataIndex: 'cartera_cnt_activos',
            key: 'cartera_cnt_activos',
            align: 'center'
        },
        /*{
            title: 'Valor Actual Veltis',
            dataIndex: 'cartera_valor_activos',
            key: 'cartera_valor_activos',
            align: 'center'
        },*/
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            align: 'center'
        },
        {
            title: '',
            dataIndex: 'ver',
            key: 'ver',
            align: 'center'
        },

    ];

    const expandedRowRender = (record) => (
        <div style={{ margin: 0, padding: 10 }}>
            <Text>{record.description}</Text>
        </div>
    );

    const handleRowExpand = (_, record) => {
        const keys = [...expandedRowKeys];
        const index = keys.indexOf(record.key);

        if (index > -1) {
            keys.splice(index, 1);
        } else {
            keys.push(record.key);
        }
        setExpandedRowKeys(keys);
    };

    const rowClassName = (_, record) => {
        if (_.key === carteraSelected) {
            return expandedRowKeys.includes(record.key) ? 'expanded-row' : 'pointer-row moco';
        } else {
            return expandedRowKeys.includes(record.key) ? 'expanded-row' : 'pointer-row';
        }
    }

    const expandableConfig = {
        expandedRowKeys,
        onExpand: handleRowExpand,
        //rowExpandable: (record) => true,
        //expandRowByClick: true,
    };

    return (
        <>
            <Table
                size='small'
                dataSource={data}
                columns={columns}
                expandedRowRender={expandedRowRender}
                rowClassName={rowClassName}
                {...expandableConfig}
                pagination={false}
                style={{ margin: '0px' }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: ()=>handleClick(record.key), // click row
                    };
                }}
            />
        </>
    );
};

export default ExpandableTable;

