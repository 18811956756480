import { Tooltip } from "antd";

function veltisTooltip(texto, descripcion) {
    return (
        <Tooltip title={descripcion} overlayInnerStyle={{ fontSize: 11 }}>
            <span style={{ textDecoration: 'underline dotted blue', cursor: 'pointer', textDecorationThickness: 1.3 }}>{texto}</span>
        </Tooltip>
    )
}

export { veltisTooltip };