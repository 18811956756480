import React from 'react';
import { NavLink } from "react-router-dom";
import Logo from '../LogoVeltis.png'
import './Layer1.css'
import { Dropdown, Space } from 'antd';
import Cookies from 'js-cookie';
import Alerts from '../../../components/Alerts/Alerts';

const Layer1 = ({ userName, userData }) => {

    const logout = async (e) => {
        e.preventDefault();
        try {
            Cookies.remove('token');
        } catch (error) {
            console.error('Error al cerrar sesión', error);
        }
    };

    const items = [
        {
            key: '1',
            label: (
                <a
                    onClick={logout}
                    className="ant-dropdown-link"
                >
                    Cerrar sesión
                </a>
            ),
        },
    ]

    return (
        <div id="navbar" className="navbar-desktop align-child-center bg-theme" style={{ backgroundColor: 'white' }} data-v-b50e56de>
            <div className="navbar" style={{ backgroundColor: 'white' }} data-v-b50e56de>
                <div className="navbar-brand" data-v-b50e56de>
                    <NavLink to="/" >
                        <img width={150} height={'auto'} src={Logo} alt='logoVeltis' />
                    </NavLink>
                </div>
                <div className="navbar-item navbar-right-item" style={{ position: 'absolute', right: 22 }} data-v-b50e56de>
                    <div className="p-v-md p-h-sm" data-v-b50e56de style={{ display: 'flex', gap: '20px' }}>
                        <div style={{ color: 'black', marginTop: 'auto', marginBottom: 'auto' }}>
                            Consultas realizadas: {userData.user_creditos}
                        </div>
                        <Alerts />
                        <Dropdown
                            menu={{
                                items,
                                style: {
                                    padding: '8px 8px 8px',
                                    borderRadius: 10,
                                    marginRight: -15,
                                },
                            }}
                            trigger={['click']}
                            placement='bottomRight'
                            overlayClassName='logout'
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space >
                                    <img alt="User's Avatar" height="32px" width="32px" src={`https://ui-avatars.com/api/?length=2&background=random&name=${userName}`} style={{ verticalAlign: 'middle', borderRadius: '50%', objectFit: 'scale-down' }} data-v-b50e56de />
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layer1