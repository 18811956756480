import { BellOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Dropdown, FloatButton } from 'antd'
import React, { useEffect, useState } from 'react'
import './Alerts.css'

function Alerts() {
    
    const itemArray = [
        /*{
            id: 0, label: 'Alerta 1', checked: false,
        },
        {
            id: 1, label: 'Alerta 2', checked: false,
        },
        {
            id: 2, label: 'Alerta 3', checked: false,
        }*/
    ]

    const [items, setItems] = useState(itemArray);
    const [unread, setUnread] = useState();

    function openAlert() {
        const itemsTmp = [...items];
        itemsTmp.forEach((item) => item.checked = true);
        setItems(itemsTmp);
        console.log(items);
    }
    
    function checkAlert(id) {
        console.log(id);
    }
    
    
    useEffect(() => {
        const itemList = items
            .map((item) => (
                {
                    key: item.id,
                    label: (
                        <div onClick={() => checkAlert(item.id)} className='dropdown-content'>{item.label}</div>
                    ),
                    checked: item.checked,
                }
            ));
        setItems(itemList)
    }, [])

    useEffect(() => {
        
        let contador = 0; 
        items.forEach((item) => {
            if(item.checked === false){
                contador++;
            }
        })
        console.log(contador);
        setUnread(contador);
    }, [items])
    

    return (
        <div>
            {/*
            <Dropdown
                menu={{items}}
                trigger={['click']}
                className='dropdown-alertas'
            >
                <FloatButton
                    shape="circle"
                    icon={<BellOutlined />}
                    badge={{
                        count: unread,
                    }}
                    style={{
                        position: 'inherit',
                        width: '35px',
                        height: '35px',
                    }}
                    onClick={openAlert}
                />
                </Dropdown>*/}
        </div>
    )
}

export default Alerts