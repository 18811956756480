import React, { useState } from 'react'
import { Skeleton, Result, Button, Flex } from 'antd';
import { useEffect } from 'react';

const RAfeccion = ({ dataa }) => {
    const [loading, setloading] = useState(true);
    const [errorRequest, seterrorRequest] = useState(false);

    useEffect(() => {
        setloading(true);
    }, [dataa])

    function contentLoaded() {
        setloading(false);
    };

    if (errorRequest) return (
        <Result
            icon={null}
            subTitle="No hay datos de liquidez disponibles"
        />
    );

    const abrirNuevaPestaña = () => {
        window.open('https://terminal.veltisrating.com:8888/api/view_pdf_afecciones/' + dataa.data[0].full_refcat, '_blank');
    };

    return (
        <>
            <div style={{ display: loading ? 'block' : 'none' }}>
                <div style={{ display: 'flex', gap: '5px', marginTop: '5%', marginLeft: '2%' }}>
                    <Skeleton
                        active
                        loading={loading}
                        paragraph={{
                            rows: 3,
                        }}
                    />
                </div>
                <div style={{ display: 'flex', gap: '5px', marginLeft: '2%' }}>
                    <Skeleton
                        active
                        loading={loading}
                        paragraph={{
                            rows: 3,
                        }}
                    />
                </div>
                <div style={{ display: 'flex', gap: '5px', marginLeft: '2%' }}>
                    <Skeleton
                        active
                        loading={loading}
                        paragraph={{
                            rows: 3,
                        }}
                    />
                </div>
                <div style={{ display: 'flex', gap: '5px', marginLeft: '2%' }}>
                    <Skeleton
                        active
                        loading={loading}
                        paragraph={{
                            rows: 3,
                        }}
                    />
                </div>
            </div>
            <div style={{ paddingBottom: 40, width: '100%', height: '91%', display: loading ? 'none' : 'block' }}>
                <div style={{ display: 'flex', justifyContent: 'end', marginRight: 10, marginBottom: 10 }}>
                    <Button type="primary" onClick={abrirNuevaPestaña}>
                        Abrir informe (Riesgos por Afeccion)
                    </Button>
                </div>
                <iframe src={"https://terminal.veltisrating.com:8888/api/view_pdf_afecciones/" + dataa.data[0].full_refcat}
                    type="application/pdf" onLoad={contentLoaded} width="100%" height="100%">
                </iframe>
            </div >
        </>
    )
}

export default RAfeccion