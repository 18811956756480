import { React } from 'react'

const TablaRiesgos = () => {

    return (
        <div style={{ whiteSpace: 'none', marginRight: 0, paddingBottom: 50 }}>
            <div>
                <table className="tableee table_coyun" style={{ width: '97%' }}>
                    <thead>
                        <tr><th className="first_th" />
                            <th>2017</th>
                            <th>2018</th>
                            <th>2019</th>
                            <th>2020</th>
                            <th>2021</th>
                            <th>2022</th>
                            <th>2023</th>
                            <th>Riesgo</th>
                        </tr></thead>
                    <tbody>
                        <tr className="midd">
                            <td colSpan={7}><b>PAÍS</b>&nbsp; <span className="dot white" /> Riesgo medio </td>
                        </tr><tr>
                            <td>Medíatico Socio-Político</td>
                            <td>109,66</td>
                            <td>115,63</td>
                            <td>136,96</td>
                            <td>196,72</td>
                            <td>148,44</td>
                            <td>144</td>
                            <td>139,4</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Ley y Gobierno</td>
                            <td>18,08</td>
                            <td>18,42</td>
                            <td>19,00</td>
                            <td>22,67</td>
                            <td>41,50</td>
                            <td>36,9</td>
                            <td>40,2</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Entorno Internacional</td>
                            <td>2,75%</td>
                            <td>1,84%</td>
                            <td>1,30%</td>
                            <td>-6,76%</td>
                            <td>1,16%</td>
                            <td>5,5</td>
                            <td>0,6</td>
                            <td><span className="dot orange" /></td>
                        </tr><tr>
                            <td>PIB</td>
                            <td>2,78%</td>
                            <td>2,33%</td>
                            <td>2,11%</td>
                            <td>-10,38%</td>
                            <td>0,69%</td>
                            <td>6,1</td>
                            <td>2</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Índice Confianza del Consumidor</td>
                            <td>102,83%</td>
                            <td>98,30%</td>
                            <td>89,34%</td>
                            <td>59,96%</td>
                            <td>68,10%</td>
                            <td>83,7%</td>
                            <td>76,5%</td>
                            <td><span className="dot orange" /></td>
                        </tr><tr>
                            <td>Inflación IPC</td>
                            <td>1,95%</td>
                            <td>1,57%</td>
                            <td>0,49%</td>
                            <td>-0,20%</td>
                            <td>0,71%</td>
                            <td>3,1</td>
                            <td>3,3</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Tipo de Cambio</td>
                            <td>1,13</td>
                            <td>1,18</td>
                            <td>1,12</td>
                            <td>1,14</td>
                            <td>1,20</td>
                            <td>1,1</td>
                            <td>1,1</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Euribor 3M</td>
                            <td>-0,15</td>
                            <td>-0,17</td>
                            <td>-0,22</td>
                            <td>-0,30</td>
                            <td>-0,49</td>
                            <td>1,1</td>
                            <td>3,8</td>
                            <td><span className="dot green" /></td>
                        </tr><tr>
                            <td>Prima de Riesgo</td>
                            <td>118,27</td>
                            <td>95,50</td>
                            <td>87,22</td>
                            <td>85,36</td>
                            <td>72,58</td>
                            <td>103,8</td>
                            <td>103,4</td>
                            <td><span className="dot green_2" /></td>
                        </tr><tr>
                            <td>Tasa de Paro Registrado</td>
                            <td>22,45%</td>
                            <td>21,11%</td>
                            <td>20,02%</td>
                            <td>23,61%</td>
                            <td>17,63%</td>
                            <td></td>
                            <td></td>
                            <td><span className="dot green_2" /></td>
                        </tr><tr>
                            <td>Empleo Afiliados</td>
                            <td>2.933.618</td>
                            <td>3.027.061</td>
                            <td>3.116.485</td>
                            <td>3.035.386</td>
                            <td>18.863.417</td>
                            <td>20.005.273</td>
                            <td>20.497.010</td>
                            <td><span className="dot green" /></td>
                        </tr><tr>
                            <td>Paro Registrado</td>
                            <td>849.460</td>
                            <td>809.842</td>
                            <td>779.987</td>
                            <td>938.236</td>
                            <td>3.958.352</td>
                            <td>2.962.791</td>
                            <td>2.854.214</td>
                            <td><span className="dot red" /></td>
                        </tr><tr>
                            <td>Mediáticos Internacionales</td>
                            <td>180,07</td>
                            <td>193,83</td>
                            <td>268,10</td>
                            <td>327,86</td>
                            <td>235,76</td>
                            <td>288,8</td>
                            <td>258,3</td>
                            <td><span className="dot orange" /></td>
                        </tr><tr>
                            <td>Reloj electoral</td>
                            <td>23,26</td>
                            <td>72,92</td>
                            <td>80,90</td>
                            <td>31,25</td>
                            <td>68,75</td>
                            <td>14,6</td>
                            <td>52,1</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Transparencia Int'L España</td>
                            <td>57,00</td>
                            <td>58,00</td>
                            <td>62,00</td>
                            <td>62,00</td>
                            <td>62,00</td>
                            <td>60,0</td>
                            <td>59,0</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Desconfianza Empresarial</td>
                            <td>8,17</td>
                            <td>9,67</td>
                            <td>8,17</td>
                            <td>10,92</td>
                            <td>35,75</td>
                            <td>32,3</td>
                            <td>30,6</td>
                            <td><span className="dot orange" /></td>
                        </tr><tr>
                            <td>Crédito</td>
                            <td>1.614.617</td>
                            <td>1.598.976</td>
                            <td>1.602.762</td>
                            <td>1.629.612</td>
                            <td>1.642.479</td>
                            <td>1.653.458</td>
                            <td>1.605.384</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Mora</td>
                            <td>8,89%</td>
                            <td>6,57%</td>
                            <td>5,42%</td>
                            <td>4,73%</td>
                            <td>4,45%</td>
                            <td>4,0%</td>
                            <td>3,6%</td>
                            <td><span className="dot green_2" /></td>
                        </tr><tr>
                            <td>Consumo</td>
                            <td>104,33</td>
                            <td>105,45</td>
                            <td>107,02</td>
                            <td>101,74</td>
                            <td>108,80</td>
                            <td>107,8</td>
                            <td>110,2</td>
                            <td><span className="dot green_2" /></td>
                        </tr><tr>
                            <td>Comercio Minorista (ICM)</td>
                            <td>103,60</td>
                            <td>104,65</td>
                            <td>108,51</td>
                            <td>99,53</td>
                            <td>97,95</td>
                            <td>104,7</td>
                            <td>106,8</td>
                            <td><span className="dot orange" /></td>
                        </tr><tr>
                            <td>Licitaciones</td>
                            <td>94.256</td>
                            <td>131.769</td>
                            <td>60.331</td>
                            <td>138.509</td>
                            <td>319.923</td>
                            <td>2.084.137</td>
                            <td>2.118.356</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Exportaciones</td>
                            <td>2.822.011</td>
                            <td>3.048.854</td>
                            <td>3.081.572</td>
                            <td>2.909.672</td>
                            <td>15.401.797</td>
                            <td>15275868</td>
                            <td>15054726</td>
                            <td><span className="dot green" /></td>
                        </tr><tr>
                            <td>Importaciones</td>
                            <td>4.537.810</td>
                            <td>4.460.995</td>
                            <td>4.138.283</td>
                            <td>3.489.254</td>
                            <td>19.681.253</td>
                            <td>21.693.134</td>
                            <td>20.854.040</td>
                            <td><span className="dot green" /></td>
                        </tr><tr>
                            <td>Pernoctaciones</td>
                            <td>4.380.099</td>
                            <td>4.440.008</td>
                            <td>4.570.653</td>
                            <td>1.398.448</td>
                            <td>2.776.352</td>
                            <td>2.673.2833</td>
                            <td>2.970.8635</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Condiciones Políticas Monetarias</td>
                            <td>5,06</td>
                            <td>4,22</td>
                            <td>2,96</td>
                            <td>-10,16</td>
                            <td>1,95</td>
                            <td>13,6</td>
                            <td>3,1</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Evolución Precios</td>
                            <td>1.478</td>
                            <td>1.501</td>
                            <td>1.581</td>
                            <td>1.632</td>
                            <td>1.783</td>
                            <td>1,850</td>
                            <td>1,960</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Volatilidad</td>
                            <td>6,65%</td>
                            <td>1,50%</td>
                            <td>5,32%</td>
                            <td>3,37%</td>
                            <td>1,62%</td>
                            <td>3,5%</td>
                            <td>6,9%</td>
                            <td><span className="dot white" /></td>
                        </tr><tr>
                            <td>Burbuja Inmobiliaria</td>
                            <td>28,84</td>
                            <td>29,62</td>
                            <td>30,71</td>
                            <td>30,70</td>
                            <td>30,85</td>
                            <td>32,</td>
                            <td>37,9</td>
                            <td><span className="dot green_2" /></td>
                        </tr><tr>
                            <td>Ciclo</td>
                            <td>7.467</td>
                            <td>8.451</td>
                            <td>8.342</td>
                            <td>6.956</td>
                            <td>44.174</td>
                            <td>54.092</td>
                            <td>52.630</td>
                            <td><span className="dot green" /></td>
                        </tr><tr>
                            <td>Stock Proxy</td>
                            <td>-753</td>
                            <td>-707</td>
                            <td>-605</td>
                            <td>-6,08</td>
                            <td>-3.508</td>
                            <td>-1.330</td>
                            <td>-1.698</td>
                            <td><span className="dot green" /></td>
                        </tr><tr>
                            <td>Precio Suelo</td>
                            <td>157%</td>
                            <td>159%</td>
                            <td>169%</td>
                            <td>149%</td>
                            <td>137%</td>
                            <td>158%</td>
                            <td>151%</td>
                            <td><span className="dot green" /></td>
                        </tr><tr>
                            <td>Ratio Tensión P.Suelo/P.Viv</td>
                            <td>10,64</td>
                            <td>10,63</td>
                            <td>10,75</td>
                            <td>9,19</td>
                            <td>7,70</td>
                            <td>8,6</td>
                            <td>7,8</td>
                            <td><span className="dot green" /></td>
                        </tr><tr>
                            <td>Alquiler</td>
                            <td>547,54</td>
                            <td>588,63</td>
                            <td>655,21</td>
                            <td>697,71</td>
                            <td>949,88</td>
                            <td>910</td>
                            <td>982</td>
                            <td><span className="dot red" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}



export default TablaRiesgos