import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import logo4 from './logos/mapa-patrimonio-arquitectonico-bic-centro-historico-malaga.jpg'
import './Protecciones.css'

HighchartsMore(Highcharts);

const Protecciones = ({ value }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const options = {
        chart: {
          type: 'gauge',
          plotBackgroundColor: null,
          plotBackgroundImage: null,
          plotBorderWidth: 0,
          plotShadow: false,
        },
        accessibility: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        title: {
          text: '',
        },
        pane: {
          startAngle: -90,
          endAngle: 90,
          background: [
            {
              backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, '#55BF3B'], // verde
                  [0.25, '#66BB6A'], // verde claro
                  [0.5, '#FFEB3B'], // amarillo
                  [0.75, '#FF9800'], // naranja
                  [1, '#FF5722'], // rojo
                ],
              },
              borderWidth: 0,
              outerRadius: '0%',
              innerRadius: '0%',
            },
          ],
        },
        yAxis: {
          min: 0,
          max: 200,
          minorTickInterval: 'auto',
          minorTickWidth: 1,
          minorTickLength: 10,
          minorTickPosition: 'inside',
          minorTickColor: '#666',
          tickWidth: 2,
          tickPosition: 'inside',
          tickLength: 10,
          tickColor: '#666',
          labels: {
            step: 2,

            formatter: function () {
              if (this.value === 0) return 'BAJO';
              if (this.value === 100) return 'MEDIO';
              if (this.value === 200) return 'ALTO';
              return '';
            },
            y: -6, //posición de los indicadores medio, bajo, alto.
            style: {
              fontSize: '14px',
              fontWeight: 800,
              color: 'white'
            },
          },
          plotBands: [
            {
              from: 0,
              to: 29,
              color: '#55BF3B', // verde
              thickness: '30%',
            },
            {
              from: 30,
              to: 59,
              color: '#66BB6A', // verde claro
              thickness: '30%',
            },
            {
              from: 60,
              to: 99,
              color: '#e1be23', // amarillo
              thickness: '30%',
            },
            {
              from: 100,
              to: 139,
              color: '#FF9800', // naranja
              thickness: '30%',
            },
            {
              from: 140,
              to: 200,
              color: '#FF5722', // rojo
              thickness: '30%',
            },
          ],
        },
        series: [
          {
            name: '',
            data: [40], //Este es el valor numérico que hay que modificar para cambiar la dirección de la flecha
            dataLabels: {
              enabled: false,
            },
          },
        ],
      };

      const chart = Highcharts.chart(chartRef.current, options);

      const updateValue = (value) => {
        const point = chart.series[0].points[0];
        point.update(value);
      };

      let currentSpeed = 0;
      let animationInterval;

      const startAnimation = () => {
        animationInterval = setInterval(() => {
          if (currentSpeed < value) {
            currentSpeed += 1;
            updateValue(currentSpeed);
          } else {
            clearInterval(animationInterval);
          }
        }, 20);
      };

      startAnimation();
    }
  }, [value]);

  return (
    <div style={{ paddingBottom: 70 }}>
      <div ref={chartRef} className='velocimetro' />
      <div className='contentt'>
        <center>
          <img src={logo4} style={{ width: '100%', height: 'auto' }} alt='logo4'/>
        </center>
      </div>
    </div>
  )
};

export default Protecciones;



