import React from 'react'

function Descripcion({ overSelectedItem }) {
    if (overSelectedItem !== undefined && overSelectedItem !== null) {
        return <>
                <h2 style={{ marginBottom: '10px' }}>Informacion del Campo</h2>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '35%', gap: '20px' }}>
                        <div>
                            <h4 style={{ fontWeight: 'bold' }}>Nombre:</h4>
                            <p>{overSelectedItem.name}</p>
                        </div>
                        <div>
                            <h4 style={{ fontWeight: 'bold' }}>Descripcion:</h4>
                            <p>{overSelectedItem.description}</p>
                        </div>
                    </div>
                    <div>
                        <h4 style={{ fontWeight: 'bold' }}>Imagen de ejemplo:</h4>
                        <img src="" alt={overSelectedItem.name} />
                    </div>
                </div>
            </>
    } else {
        // descripcion sin objeto seleccionado
        return <>
           {
        //    <h2 style={{ marginBottom: '10px' }}>Informacion del Campo</h2>
        //     <div style={{ display: 'flex' }}>
        //         <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '35%', gap: '20px' }}>
        //             <div>
        //                 <h4 style={{ fontWeight: 'bold' }}>Nombre:</h4>
        //                 <p>Nombre del campo</p>
        //             </div>
        //             <div>
        //                 <h4 style={{ fontWeight: 'bold' }}>Descripcion:</h4>
        //                 <p>Descripcion del campo seleccionado</p>
        //             </div>
        //         </div>
        //         <div>
        //             <h4 style={{ fontWeight: 'bold' }}>Imagen de ejemplo:</h4>
        //             <p>Imagen de muestra del campo seleccionado</p>
        //         </div>
        //     </div>

           } 
        </>
    }
};
export default Descripcion